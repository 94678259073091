import { useMutation } from "@tanstack/react-query";
import { endpoints, getAuthToken, queryClient, setAuthToken } from "config/api";
import { AuthService } from "services/auth/AuthServices";
import { storageKeys } from "utils/storage";

const AuthServiceInstance = new AuthService();

export const useLogin = () => {

    const { mutate, isLoading } = useMutation(AuthServiceInstance.loginUser,{
        onSuccess: (res) => {
            setAuthToken(res.access_token, false);
        }
    });

    return { onLoginUser: mutate, isLoading };
};

export const useLoginSocials = () => {
    const { mutate, isLoading } = useMutation(AuthServiceInstance.loginSocial,{
        onSuccess: (res) => {
            setAuthToken(res.access_token, true);
        }
    });

    return { onLoginSocials: mutate, isLoading };
};

export const useRegister = () => {
    const { mutate, isLoading } = useMutation(AuthServiceInstance.registerUser,{
        onSuccess: (res) => {
            setAuthToken(res.access_token, false);
        }
    });

    return {onRegisterUser: mutate, isLoading };
};

export const useResetPassword = () => {
    const { mutate, isLoading } = useMutation(AuthServiceInstance.resetPassword);

    return {onResetPassword: mutate, isLoading };
};

export const useForgotPassword = () => {
    const { mutate, isLoading } = useMutation(AuthServiceInstance.forgotPassword);

    return {onForgotPassword: mutate, isLoading };
};

export const useLogout = () => {
    const { mutate, isLoading } = useMutation(AuthServiceInstance.logout);

    return {onLogout: mutate, isLoading };
};

export const useGetGuest = () => {
    const { mutate, isLoading } = useMutation(AuthServiceInstance.getGuest);

    return {onGetGuest: mutate, isLoading };
};


export const useResendVerification = () => {
    const { mutate, isLoading } = useMutation(AuthServiceInstance.resendVerification);

    return {onResendVerificationEmail: mutate, isLoading };
};

export const useLoginAsGuest = () => {
    const { mutate, isLoading } = useMutation(AuthServiceInstance.loginAsGuest,{
        onSuccess: (res) => {
            setAuthToken(res.access_token, false);
            sessionStorage.setItem(storageKeys.guestMode, "true");
        }
    });

    return { onLoginGuest: mutate, isLoading };
};

export const useConvertGuest = () => {
    const { mutate, isLoading } = useMutation(AuthServiceInstance.registerFromGuest,{
        onSuccess: () => {
            setAuthToken(getAuthToken() as string, true);
            setTimeout(()=> {
                queryClient.invalidateQueries({
                    queryKey: [endpoints.profile.me]
                });
            }, 500);
        }
    });

    return {onConvertGuest: mutate, isLoading };
};


