import React, { useEffect, useRef } from 'react';

interface DropdownProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  containerClass?: string
}

export const Dropdown = (props: DropdownProps): React.JSX.Element => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        const handleOutsideClick = (event: Event) => {
            if (dropdownRef.current !== null && !dropdownRef.current.contains(event.target as HTMLElement)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };


    }, [dropdownRef]);

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div ref={dropdownRef} className={`dropdown dropdown-open dropdown-end  ${props?.containerClass}`}>
            <label tabIndex={0} className='' onClick={handleDropdownToggle}>{props.icon}</label>
            {/* <summary className='m-1 btn btn-sm btn-ghost' onClick={handleDropdownToggle}>

            </summary> */}
            {isOpen && (
                <ul tabIndex={0} className='p-2 shadow menu dropdown-content z-[1] bg-white rounded-box'>
                    {props.children}
                </ul>
            )}
        </div>
    );
};

export default Dropdown;
