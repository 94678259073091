import { ASSETS } from 'assets';
import Button from 'components/Button/Button';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CreateAccount } from 'services/auth/AuthTypes';
import TextInput from 'components/TextInput/TextInput';
import { useConvertGuest, useLoginSocials, useRegister } from 'hooks/auth/auth';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import ToastMessage from 'components/ToastMessage/ToastMessage';
import { Link, useNavigate } from 'react-router-dom';
import { publicRouteNames } from 'config/routes/PublicRoutes';
import { ProtectedRouteNames } from 'config/routes/ProtectedRoute';
import { MessageFormError } from 'services/serviceHelper';
import { useGoogleLogin } from '@react-oauth/google';
import '../Authentication.scss';
import { useGetProfileMutation } from 'hooks/user/user';

// Define your validation schema using Yup
const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required").email("Invalid email format"),
    password: yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must have at least one lowercase char")
        .matches(/[A-Z]/, "Password must have at least one uppercase char")
        .matches(/\W|_/, "Password must have at least one special character")
});

export const Register = (): JSX.Element => {
    const { control, handleSubmit, setError, formState:{errors} } = useForm({
        resolver: yupResolver(schema),
    });
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const isForGuest = token;
    const {onConvertGuest} = useConvertGuest();


    const {onRegisterUser, isLoading: registerLoading} = useRegister();
    const {onGetProfileMutation, isLoading: getProfileLoading} = useGetProfileMutation();
    const navigate = useNavigate();
    const {onLoginSocials, isLoading: socialLoading} = useLoginSocials();

    const isLoading = registerLoading || socialLoading || getProfileLoading;

    const onSubmit = (data: CreateAccount) => {
        if(isForGuest){
            onConvertGuest(data, {
                onSuccess: () => navigateToDashboard()
            });

            return;
        }

        onRegisterUser(data, {
            onSuccess:(tokenRes)=> {
                onGetProfileMutation(undefined, {
                    onSuccess: ()=> {
                        toast.info(<ToastMessage title='Hello' message={'Register Success!, Please validate email'} />, {
                            theme: 'colored',
                            autoClose: 1000,
                            hideProgressBar: true,
                            icon: false
                        });
                        navigateToVerifyScreen(tokenRes.access_token);
                    }
                });
                // navigateToDashboard();
            },
            onError: (err) => {
                const errorAxios = err as AxiosError<MessageFormError<CreateAccount>>;

                if(errorAxios.response && errorAxios.response?.status === 401){
                    toast.error(<ToastMessage title='Error' message={'Credentials Invalid'} />, {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                        className: 'text-white',
                        icon: false
                    });
                }else if (errorAxios.response?.status === 422 && errorAxios.response.data.errors) {
                    const errorsFromServer = errorAxios.response.data.errors;

                    for (const key in errorsFromServer) {
                        if (errorsFromServer[key as keyof typeof errorsFromServer].length > 0) {
                            setError(key as keyof CreateAccount, {
                                type: "manual",
                                message: errorsFromServer[key as keyof typeof errorsFromServer][0]
                            });
                        }
                    }
                }
            }
        });
    };

    const requestGoogleLogin = useGoogleLogin({
        onError: () => {
            toast.error(<ToastMessage title='Error' message={'Authentication Failed'} />, {
                theme: 'colored',
                autoClose: 1000,
                hideProgressBar: true,
                className: 'text-white',
                icon: false
            });
        },
        onSuccess: (data) => {
            const access_token = data?.access_token;

            onLoginSocials({
                token:access_token,
                provider: 'google'
            }, {
                onSuccess: () => {
                    toast.info(<ToastMessage title='Hello' message={'Welcome to LauncHER !'} />, {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                        icon: false
                    });

                    navigateToDashboard();
                }
            }
            );


        }
    });

    const navigateToDashboard = () => {
        navigate(ProtectedRouteNames.DASHBOARD, {replace: true});
    };

    const navigateToVerifyScreen = (token:string) => {
        navigate(`${publicRouteNames.REGISTER}?token=${token}`, {replace: true});
    };

    return (
        <div className='flex flex-wrap overflow-y-hidden relative gradient-overlay'>
            <img src={ASSETS.waves.WaveTop1} alt='Wave Top'
                className='absolute top-0  left-0 w-1/2 max-md:w-full max-md:top-[-85px] max-sm:top-[-25px]' />
            <img src={ASSETS.waves.WaveTop2} alt='Wave Top 2'
                className='absolute top-0  left-0 w-1/2 max-md:w-full max-md:top-[-30px] max-sm:top-[0px]' />

            {/* Form Section */}
            <div className='
                transition-transform duration-500 ease-in-out
                w-1/2 max-md:w-full flex items-center justify-center p-4 max-md:h-1/2 scale-125 max-2xl:scale-110 max-xl:scale-100 z-20' >
                <form className='w-full max-w-md px-[0px] max-lg:px-[20px] max-md:mt-[90px] max-md:mb-[40px]' onSubmit={handleSubmit(onSubmit)}>
                    <h1 className='text-[32px] font-semibold mb-[10px] leading-[40px] text-white'>
                        Join LauncHER Today
                    </h1>
                    <div className='mb-[40px] text-white leading-[28px] max-md:mb-[32px]'>
                        Register now and be part of our empowering community.
                    </div>
                    <div className='mb-[20px] flex flex-col'>
                        <Controller
                            name='name'
                            control={control}
                            defaultValue=''
                            render={({ field }) =>
                                <TextInput {...field}
                                    labelClass='text-white'
                                    label='Name'
                                    error={errors.name?.message}
                                    className='shadow appearance-none border rounded w-full py-2 px-3 input' />}/>
                    </div>

                    <div className='mb-[20px] flex flex-col'>
                        <Controller
                            name='email'
                            control={control}
                            defaultValue=''
                            render={({ field }) =>
                                <TextInput {...field}
                                    labelClass='text-white'
                                    label='Email'
                                    error={errors.email?.message}
                                    className='shadow appearance-none border rounded w-full py-2 px-3 input'
                                    type='email'/>}/>
                    </div>
                    <div className='mb-[25px] flex flex-col gap-y-[10px]'>
                        <Controller
                            name='password'
                            control={control}
                            defaultValue=''

                            render={({ field }) => <TextInput {...field} className='input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline'
                                label='Password'
                                labelClass='text-white'
                                type='password'
                                error={errors.password?.message}
                                autocomplete='false'  />}/>
                    </div>
                    <div className='flex flex-col items-center justify-between'>

                        <Button
                            type='submit'
                            isLoading={isLoading}
                            containerClass='w-full'
                            title='Register'/>
                        {
                            !isForGuest && <div className='divider before:bg-base-300 after:bg-base-300 before:h-[1px] after:h-[1px] text-white'>OR</div>
                        }


                        {
                            !isForGuest && (
                                <div className='btn bg-white text-black w-full  border-0 hover:bg-white hover:shadow-2xl' onClick={()=> requestGoogleLogin()}>
                                    <img src={ASSETS.icon.GoogleLogo}/>
                                    Register With Google
                                </div>

                            )
                        }

                        {
                            !isForGuest && (
                                <div className='mt-[20px] text-white'>
                                    {"Already Registered ? "}
                                    <Link to={publicRouteNames.LOGIN} className='text-white font-medium cursor-pointer'>
                                        Login
                                    </Link>
                                </div>

                            )
                        }

                    </div>
                </form>
            </div>

            {/* Image Section */}
            <div className='w-1/2 h-screen z-10
                max-md:w-full max-md:h-1/2
                flex items-center justify-center'>
                <img src={ASSETS.images.LoginCover} alt='Login Visual' className='w-full h-full object-cover' />
            </div>

            <img src={ASSETS.authentication.SuperGraphicsAuth} alt='Auth Super' className='absolute bottom-[0px] left-[0px] w-1/2' />

            <img src={ASSETS.waves.WaveBot1} alt='Wave Bot' className='absolute bottom-0 left-[0px] w-1/2 z-10' />
            <img src={ASSETS.waves.WaveBot2} alt='Wave Bot 2' className='absolute bottom-0 left-[0px] w-1/2 z-10' />

        </div>
    );
};

export default Register;
