import { useMutation, useQuery } from "@tanstack/react-query";
import { endpoints } from "config/api";
import { resourceEndpoints } from "config/apiCountry";
import { ResourceServices } from "services/resources/resurcesServices";

const ResourceInstance = new ResourceServices();

export const useGetSectors = () => {
    const {data, isLoading} = useQuery({
        queryFn: ResourceInstance.getSectorLists,
        queryKey: [endpoints.resources.sectors]
    });

    return {data, isLoading};
};

export const useGetExpertises = () => {
    const {data, isLoading} = useQuery({
        queryFn: ResourceInstance.getExpertisesLists,
        queryKey: [endpoints.resources.expertises]
    });

    return {data, isLoading};
};

export const useGetCountry = () => {
    const {data, isLoading} = useQuery({
        queryFn: ResourceInstance.getCountryList,
        queryKey: [resourceEndpoints.getCountry]
    });

    return {data, isLoading};
};

export const useGetStateByCountry = () => {
    const {mutate, isLoading} = useMutation(ResourceInstance.getStateByCountry);

    return {onGetState:mutate, isLoading};
};

export const useGetSdgs = () => {
    const {data, isLoading} = useQuery({
        queryFn: ResourceInstance.getSdgsLists,
        queryKey: [endpoints.resources.sdgs]
    });

    return {data, isLoading};
};


