import { containerClasses } from '@mui/material';
import { ASSETS } from 'assets';
import React, { InputHTMLAttributes, ForwardRefRenderFunction, useState } from 'react';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    type?: string;
    placeholder?: string;
    className?: string;
    autocomplete?: string;
    label?: string;
    error?: string
    labelClass?: string;
    containerClass?: string
}

const TextInput: ForwardRefRenderFunction<HTMLInputElement, TextInputProps> = (props, ref) => {
    const [isShow, setIsShow] = useState(false);

    const onToggle = () => {
        setIsShow((prevState) => !prevState);
    };

    return (
        <div className={`relative ${containerClasses}`}>
            {
                props?.label ?
                    <label
                        className={`${props?.labelClass}`}
                        htmlFor={props?.name}>
                        {props?.label}
                    </label>
                    :
                    <></>
            }

            <input
                ref={ref}
                type={props?.type === 'password' ? (isShow ? 'text' : 'password') : props?.type}
                placeholder={props?.placeholder}
                className={`${props?.label ? "mt-[10px]" : ""} bg-white shadow appearance-none border rounded w-full py-2 px-3 ${props?.className} !bg-white border-0`}
                id={props?.id}
                name={props?.name}
                defaultValue={props?.defaultValue}
                disabled={props.disabled}
                onChange={props?.onChange}
                onBlur={props?.onBlur}
                autoComplete={props?.autocomplete ? props?.autocomplete : 'false'}
                value={props?.value}/>

            {/* Display open/close text conditionally if the type is 'password' */}
            {props?.type === 'password' ?

                isShow ?

                    <img className={`absolute w-[30px]  right-0 pr-3 flex items-center cursor-pointer ${props?.label ? 'inset-y-[40px]': 'inset-y-[16px]'}`} onClick={onToggle} src={ASSETS.authentication.EyeOpen}/>

                    :

                    <img className={`absolute w-[30px]  right-0 pr-3 flex items-center cursor-pointer ${props?.label ? 'inset-y-[40px]': 'inset-y-[16px]'}`} onClick={onToggle} src={ASSETS.authentication.EyeClose}/>
                :<></>
            }

            {props?.error && <div className='rounded-md py-[2px] px-[10px] bg-[#FC2C51] mt-[5px]'>
                <span className='text-white text-[12px] italic'>{props?.error}</span>
            </div>}
        </div>
    );
};

export default React.forwardRef(TextInput);
