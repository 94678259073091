import {useMutation, useQuery } from "@tanstack/react-query";
import { endpoints, queryClient } from "config/api";
import { UserServices } from "services/user/UserServices";

const userServiceInstance = new UserServices();

export const useUpdateProfile = () => {
    const {mutate, isLoading} = useMutation(userServiceInstance.updateMyProfile,{
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [endpoints.profile.me]});
            queryClient.invalidateQueries({queryKey: [endpoints.cohort.cohorts]});
        }
    });

    return {onUpdate:mutate, isLoading};
};

export const useGetProfile = () => {
    const {data, isLoading, refetch} = useQuery({
        queryFn: userServiceInstance.getMyProfile,
        queryKey: [endpoints.profile.me]
    });

    return {data, isLoading, refetch};
};

export const useGetProfileMutation = () => {
    const {mutate, isLoading} = useMutation(userServiceInstance.getMyProfile);

    return {onGetProfileMutation:mutate, isLoading};
};

export const useGetUserData = (id:string) => {
    const {data, isLoading} = useQuery({
        queryFn: ()=>userServiceInstance.getUserById(id),
        queryKey: [endpoints.profile.get_user(id)]
    });

    return {data, isLoading};
};
