import { axiosInstance, endpoints } from "config/api";
import { isResponseSuccess } from "services/serviceHelper";
import { UserObj } from "./UserTypes";

export class UserServices {
    async getMyProfile(): Promise<UserObj> {
        const response = await axiosInstance.get(endpoints.profile.me);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

    async updateMyProfile(data: Partial<UserObj>): Promise<Partial<UserObj>> {
        const response = await axiosInstance.put(endpoints.profile.me, data);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

    async getUserById(id:string): Promise<UserObj> {
        const response = await axiosInstance.get(endpoints.profile.get_user(id));

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

}
