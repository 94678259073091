import { axiosInstance, endpoints } from "config/api";
import { isResponseSuccess } from "services/serviceHelper";
import { fileUploadResponse } from "./FileType";

export class FileServices {
    async uploadFile(file: File): Promise<fileUploadResponse> {
        const formData = new FormData();

        formData.append('file', file);
        const response = await axiosInstance.post(endpoints.file.upload, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

}
