import { AxiosResponse } from "axios";

export const isResponseSuccess = (response: AxiosResponse<unknown>) : boolean => {
    if(response.status < 200 || response.status >= 300){
        return false;
    }

    return true;
};

export type MessageOnlyResponse = AxiosResponse<{message: string}>


export type MessageFormError<T> = {
    message: string;
    errors: {
        [K in keyof T]: string[];
    };
}

export type PaginationConf = {
    page?:number;
    per_page?:number;
    total?: number;
}
