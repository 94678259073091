import React from 'react';

interface ButtonProps {
    containerClass?: string;
    title: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    type?: "button" | "submit" | "reset";
    isLoading?: boolean;
    disabled?: boolean;
    leftIcon?: React.JSX.Element
    isNotPrimary?: boolean;
}

export const Button = (props:ButtonProps): JSX.Element => {
    return <button
        onClick={(e)=>{
            if(props.isLoading) return;
            props.onClick && props.onClick(e);
        }}
        className={`btn
        ${props?.disabled ? 'btn-disabled !bg-gray-300 !text-white' : props?.isNotPrimary ? '':'btn-primary  text-white'}  ${props?.containerClass}`}
        type={`${props.type ? props.type : 'button'}`}>
        {props.leftIcon && props.leftIcon}
        {
            props?.isLoading ?
                <span className='loading loading-dots loading-md text-secondary' />
                :
                props.title
        }
    </button>;
};

export default Button;
