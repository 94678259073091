import { ASSETS } from "assets";
import  * as Lottie1 from 'assets/lottie/lottie_1.json';
import  * as Lottie2 from 'assets/lottie/lottie_2.json';
import  * as Lottie3 from 'assets/lottie/lottie_3.json';
import  * as Lottie4 from 'assets/lottie/lottie_4.json';


export const APP_ENV = process.env.REACT_APP_ENV;
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT;
export const API_COUNTRY = process.env.REACT_APP_COUNTRY_API;
export const SENTRY_AUTH_TOKEN = process.env.SENTRY_AUTH_TOKEN;

export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

export const SDG_OPTIONs = [
    {
        text:  "No Poverty",
        images: ASSETS.sdg.SDG01
    },
    {
        text:  "Zero Hunger",
        images: ASSETS.sdg.SDG02
    },
    {
        text:  "Good Health and Well-being",
        images: ASSETS.sdg.SDG03
    },
    {
        text:  "Quality Education",
        images: ASSETS.sdg.SDG04
    },
    {
        text:  "Gender Equality",
        images: ASSETS.sdg.SDG05
    },
    {
        text:  "Clean Water and Sanitation",
        images: ASSETS.sdg.SDG06
    },
    {
        text:  "Affordable and Clean Energy",
        images: ASSETS.sdg.SDG07
    },
    {
        text:  "Decent Work and Economic Growth",
        images: ASSETS.sdg.SDG08
    },
    {
        text:  "Industry, Innovation and Infrastructure",
        images: ASSETS.sdg.SDG09
    },
    {
        text:  "Reduced Inequalities",
        images: ASSETS.sdg.SDG10
    },
    {
        text:  "Sustainable Cities and Communities",
        images: ASSETS.sdg.SDG11
    },
    {
        text:  "Responsible Consumption and Production",
        images: ASSETS.sdg.SDG12
    },
    {
        text:  "Climate Action",
        images: ASSETS.sdg.SDG13
    },
    {
        text:  "Life Below Water",
        images: ASSETS.sdg.SDG14
    },
    {
        text:  "Life on Land",
        images: ASSETS.sdg.SDG15
    },
    {
        text:  "Peace, Justice and Strong Institutions",
        images: ASSETS.sdg.SDG16
    },
    {
        text:  "Partnerships for the Goals",
        images: ASSETS.sdg.SDG17
    },
];


export const REINFORCEMENT_TEXT = [
    "Keep up the good work!",
    "You're on the right track!",
    "You're doing great, keep it up!",
    "Well done, you're nailing it!",
];

export const REINFORCEMENT_LOTTIE = [
    Lottie1,
    Lottie2,
    Lottie3,
    Lottie4,
];


export const REACTION_TYPE_TEXT = {
    LOVE : 'love',
    LIKE : 'like',
    SHOCKED : 'shocked',
    INSIGHTFUL : 'insightful'
};

export const REACTION_TYPE = {
    LOVE : ASSETS.lottie.LottieHearth,
    LIKE : ASSETS.lottie.LottieClap,
    SHOCKED : ASSETS.lottie.LottieShock,
    INSIGHTFUL : ASSETS.lottie.LottieInsight
};

export const REACTION_TYPE_RECORD = {
    'love' : REACTION_TYPE.LOVE,
    'like' : REACTION_TYPE.LIKE,
    'insightful' : REACTION_TYPE.INSIGHTFUL,
    'shocked' : REACTION_TYPE.SHOCKED,
};

export const MAX_TEXT_LENGTH = 1500;
