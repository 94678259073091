import React, { useState } from 'react';

interface MultipleChoiceProps {
    id: number;
    options: string[];
    value?: string[];
    onChange?: (value: string) => void;
    answer:string[]
    myAnswer:string[]
}

export const MultipleChoice = (props: MultipleChoiceProps): JSX.Element => {
    // Initialize state with the first option or an empty string
    const [answer ,setAnswer] = useState<string[]>(props.value ?? []);


    // Correctly type the event and use e.target.value
    const onChange = (e: React.ChangeEvent<HTMLInputElement>, index:number) => {
        if(e.target.checked){
            const newState:string[] = [...answer, index.toString()];

            props?.onChange && props.onChange(JSON.stringify(newState));
            setAnswer(newState);
        }else{
            const newState:string[] = answer.filter(i => i != index.toString());

            setAnswer(newState);
            props?.onChange && props.onChange(JSON.stringify(newState));
        }
    };

    // const isTrue:number = 2;
    const trueClass = ' bg-[#E3F1DF] rounded-[8px]';
    const falseClass = 'bg-[#FFE3E6]/[0.6] rounded-[8px]';

    const defaultCheckbox = 'border-0 checked:w-[12px] checked:h-[12px] checked:rounded-[2px] checked:bg-[#FC2C52]';
    const defaultOuter = 'border-[2px] rounded-[6px] border-[#cccccc] w-[100px]';

    const falseCheckbox = 'border-0 checked:w-[12px] checked:h-[12px] checked:rounded-[2px] checked:bg-[#FC2C52]';
    const falseOuter = 'border-[2px] rounded-[6px] border-primary w-[100px]';

    const correctCheckbox = 'border-0 checked:w-[12px] checked:h-[12px] checked:rounded-[2px] checked:bg-success';
    const correctOuter = 'border-[2px] rounded-[6px] border-success w-[100px]';

    return (
        <div className='grid gap-[12px]'>
            {
                props.options.map((item, index) =>
                {
                    let isTrue = 3;

                    if(props.myAnswer.length >0 && props?.answer?.length > 0){
                        const IsMyAnswer = props.myAnswer.includes(index.toString());
                        const IsTheAsnwer = props.answer.includes(index.toString());
                        const IsCorrect = IsMyAnswer && IsTheAsnwer;

                        if(IsCorrect) isTrue = 1;
                        if(IsMyAnswer && !IsCorrect) isTrue = 2;
                        if(!IsMyAnswer && IsTheAsnwer) isTrue = 1;


                    }

                    return <div className={`flex items-center  ${isTrue === 1 ? trueClass: isTrue ===2 ? falseClass : ''} w-full`} key={`${item}-${index}`}>
                        <div className={`form-control`}>
                            <label className='label cursor-pointer flex items-center'>
                                <div className={`flex justify-center items-center w-[20px] h-[20px] mr-2
                                    ${isTrue === 3 ?  defaultOuter : isTrue === 2 ? falseOuter:correctOuter}
                                `}>
                                    <input
                                        onChange={(e) => onChange(e, index)}
                                        checked={answer.includes(index.toString())}
                                        value={item}
                                        type='checkbox'
                                        name={`option-${props.id}`}
                                        className={` !appearance-none ${isTrue === 3 ?  defaultCheckbox : isTrue === 2 ? falseCheckbox:correctCheckbox}
                                         w-5 h-5  border-1 border-[#cccccc] rounded-[6px] `}/>
                                </div>
                                <span className='mr-[8px] font-semibold text-[#475467] text-[14px]'>{item}</span>
                                { isTrue === 1 && <div className='text-[14px] font-semibold text-white bg-success px-[12px] py-[2px] rounded-[1000px]'>Correct answer</div>}
                                { isTrue === 2 && <div className='text-[14px] font-semibold text-white bg-[#FC2C52] px-[12px] py-[2px] rounded-[1000px]'>Incorrect answer</div>}
                            </label>
                        </div>
                    </div>;}
                )
            }
        </div>
    );
};

export default MultipleChoice;
