import React, { memo } from 'react';
import Navigator from '../../navigator/Navigator';
// import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const App = (): JSX.Element => {

    return (
        <>
            <Navigator />
            <ToastContainer />
        </>
    );
};

App.propTypes = {

};

export default memo(App);
