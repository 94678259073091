import { ASSETS } from 'assets';
import Button from 'components/Button/Button';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextInput from 'components/TextInput/TextInput';
import { useNavigate } from 'react-router-dom';
import { publicRouteNames } from 'config/routes/PublicRoutes';
import { useForgotPassword } from 'hooks/auth/auth';
import { ForgotAccount } from 'services/auth/AuthTypes';
import { AxiosError } from 'axios';
import { MessageFormError } from 'services/serviceHelper';
import ToastMessage from 'components/ToastMessage/ToastMessage';
import { toast } from 'react-toastify';
import '../Authentication.scss';

// Define your validation schema using Yup
const schema = yup.object().shape({
    email: yup.string().required("Email is required").email("Invalid email format"),
});

export const ForgotPassword = (): JSX.Element => {
    const { control, handleSubmit, setError, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const navigate = useNavigate();
    const {onForgotPassword, isLoading} = useForgotPassword();



    const onSubmit = (data: ForgotAccount) => {
        onForgotPassword(data, {
            onSuccess: () => {
                toast.info(<ToastMessage title='Email Sent' message={'Please check email to reset password'} />, {
                    theme: 'colored',
                    autoClose: 1000,
                    hideProgressBar: true,
                    className: 'text-white',
                    icon: false
                });
            },
            onError: (err) => {
                const errorAxios = err as AxiosError<MessageFormError<ForgotAccount>>;

                if (errorAxios.response?.status === 422 && errorAxios.response.data.errors) {
                    const errorsFromServer = errorAxios.response.data.errors;

                    for (const key in errorsFromServer) {
                        if (errorsFromServer[key as keyof typeof errorsFromServer].length > 0) {
                            setError(key as keyof ForgotAccount, {
                                type: "manual",
                                message: errorsFromServer[key as keyof typeof errorsFromServer][0]
                            });
                        }
                    }
                }else{
                    toast.error(<ToastMessage title='Error' message={'Server Error'} />, {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                        className: 'text-white',
                        icon: false
                    });
                }


            }
        });
    };

    const navigateToLogin = () => {
        navigate(publicRouteNames.LOGIN, {replace: true});
    };

    return (
        <div className='flex flex-wrap overflow-y-hidden relative gradient-overlay'>
            <img src={ASSETS.waves.WaveTop1} alt='Wave Top'
                className='absolute top-0  left-0 w-1/2 max-md:w-full max-md:top-[-85px] max-sm:top-[-25px]' />
            <img src={ASSETS.waves.WaveTop2} alt='Wave Top 2'
                className='absolute top-0  left-0 w-1/2 max-md:w-full max-md:top-[-30px] max-sm:top-[0px]' />

            {/* Form Section */}
            <div className=' z-40
                transition-transform duration-500 ease-in-out
                w-1/2 max-md:w-full flex items-center justify-center p-4 max-md:h-1/2 scale-125 max-2xl:scale-110 max-xl:scale-100' >
                <form className='w-full max-w-md px-[0px] max-lg:px-[20px] max-md:mt-[90px] max-md:mb-[40px]' onSubmit={handleSubmit(onSubmit)}>
                    <h1 className='text-[32px] font-semibold mb-[10px] leading-[40px] text-white'>
                        Forgot Password
                    </h1>
                    <div className='mb-[40px] text-base-200 leading-[28px] max-md:mb-[32px]' />
                    <div className='mb-[20px] flex flex-col'>
                        <Controller
                            name='email'
                            control={control}
                            defaultValue=''
                            render={({ field }) =>
                                <TextInput {...field}
                                    labelClass='text-white'
                                    label='Email'
                                    error={errors.email?.message}
                                    className='shadow appearance-none border rounded w-full py-2 px-3 input'
                                    type='email'/>}/>
                    </div>
                    <div className='flex-col  items-center justify-between'>
                        <Button
                            type='submit'
                            containerClass='w-full'
                            isLoading={isLoading}
                            title='Forgot Password'/>
                        <div className='mt-[20px]'>
                            <div onClick={navigateToLogin} className='cursor-pointer text-white'>Back to login Page</div>
                        </div>
                    </div>
                </form>
            </div>

            {/* Image Section */}
            <div className='w-1/2 h-screen z-10
                max-md:w-full max-md:h-1/2
                flex items-center justify-center'>
                <img src={ASSETS.images.LoginCover} alt='Login Visual' className='w-full h-full object-cover' />
            </div>
            <img src={ASSETS.authentication.SuperGraphicsAuth} alt='Auth Super' className='absolute bottom-[0px] left-[0px] w-1/2' />

            <img src={ASSETS.waves.WaveBot1} alt='Wave Bot' className='absolute bottom-0 left-[0px] w-1/2' />
            <img src={ASSETS.waves.WaveBot2} alt='Wave Bot 2' className='absolute bottom-0 left-[0px] w-1/2' />

        </div>
    );
};

export default ForgotPassword;
