import { ASSETS } from 'assets';
import Button from 'components/Button/Button';
import { useGetPeerReviewsv2, useSubmitLesson } from 'hooks/lesson/lesson';
// import Button from 'components/Button/Button';
import React, { useEffect, useRef, useState } from 'react';
import { CreateAnswerQuestions, LessonQuestion, LessonType } from 'services/lesson/LessonTypes';
import Reviews from './element/Reviews';
import SingleChoice from './element/SingleChoice';
import FileUpload from './element/FileUpload';
import Dropdown from 'components/Dropdown/Dropdown';
import MultipleChoice from './element/MultipleChoice';
import ReviewCard from './element/ReviewCard';
import { ReviewData } from 'services/lesson/ReviewTypes';
import { useGetProfile } from 'hooks/user/user';
import { MAX_TEXT_LENGTH } from 'config/constant';

interface QuestionProps {
    onUpdateAnswers: (questionId: number, answer: string) => void
    data: LessonQuestion,
    index: number,
}

export const Question = (props: QuestionProps): JSX.Element => {
    const contentEditableRef = useRef<HTMLDivElement | null>(null);
    const {onSubmitAnswer, isLoading: isLoadingSubmit}= useSubmitLesson();
    const {data:profileData} = useGetProfile();
    const [isAnswered, setIsAnswered] = useState(false);
    const [answer, setAnswer] = useState<string | string []>(props.data.pivot?.answer ?? "");

    const isPreviouslyAnswered = props.data.pivot?.answer?.toString() && (props.data.type === 'longText' || props.data.type === 'shortText');

    const isPeerAnswerExist =  props.data.is_reviewable && profileData?.defaultProgram.pivot.type === 'sync';

    const isSyncUser = () => {
        if(!profileData?.defaultProgram) return false;

        if(!profileData?.defaultProgram?.pivot) return false;

        if (profileData?.defaultProgram?.pivot?.type !== 'sync') return false;


        return true;
    };

    // To Determine condition of green tick
    const isGreenTick = () => {
        if(profileData?.type === 'guest'){

            return profileData?.type === 'guest' && answer;
        }

        if(isPeerAnswerExist){
            return (props.data.pivot?.answer && props.data.pivot?.is_completed);
        }


        if(answer){
            return true;
        }

        if(answer?.toString() === "0"){
            return true;
        }

        return false;

    };

    const answerStatus = props.data?.pivot?.answer_status;

    const singleChoiceAnsStatusIsWrong = props.data.type === 'singleChoice'  &&  props.data.pivot?.answer?.toString() &&  (props.data.data.answer?.includes(props.data.pivot?.answer?.toString() as string) ? false:true);


    // 1 mean only my groups
    // 0 mean all cohorts
    // 2 mean only my answers
    const [filterOptions, setFilterOptions]  = useState({
        my_group: '0',
        sortBy: 'recent',
    });

    const {data:peerReviews,
        hasNextPage, isFetchingNextPage,
        refetch: refetchPeerReviews, fetchNextPage} = useGetPeerReviewsv2({
        page_item_id: props.data.id.toString(),
        my_group: filterOptions.my_group == '2' ?  '1' : filterOptions.my_group,
        sort:filterOptions.sortBy,
        start_fetch: isAnswered && (isPeerAnswerExist ? true: false),
        page: 1,
    });

    const peerReviewsFlat = peerReviews?.pages?.flatMap(page => page?.data) ?? [];

    // const isLoading = (isLoadingSubmit || fetchStatus === 'fetching');

    // to input the innert text if there is an answer
    useEffect(() => {


        if (contentEditableRef.current && !isPeerAnswerExist ) {
            contentEditableRef.current.innerText = props.data?.pivot?.answer || "";
            setAnswer( props.data?.pivot?.answer || "");
        }

        if(props.data.pivot?.answer && isPeerAnswerExist){
            handleGetPeerReviews();
            setIsAnswered(true);
        }

    }, [props.data?.pivot?.answer]);

    // to initially trigger the answer on the parent element
    useEffect(()=>{
        if(props.data.type != 'textBlock'){
            if(props.data.pivot?.answer?.toString() === "0"){

                props.onUpdateAnswers(props.data.id, "0");

                return;
            }

            props.onUpdateAnswers(props.data.id, props.data.pivot?.answer || "");
        }
    },[]);

    // useEffect(()=>{
    //     if(isAnswered && (isPeerAnswerExist ? true: false)){
    //         refetchPeerReviews();
    //     }
    // },[filterOptions]);

    // handling question answer on blur to the parent element
    function handleChange(text: string) {
    // Trim the text to remove leading and trailing whitespaces
        const trimmedText = text.trim();

        if (trimmedText.length <= MAX_TEXT_LENGTH) {
        // Update the condition to use the trimmed text
            if (trimmedText) {
                props.onUpdateAnswers(props.data.id, trimmedText);
                setAnswer(trimmedText);
            } else {
                props.onUpdateAnswers(props.data.id, ""); // Ensure you're sending an empty string
                setAnswer("");
            }
        }
        else {
            if(contentEditableRef?.current && (props.data.type === 'longText'  || props.data.type === 'shortText')){
                contentEditableRef.current.innerText = answer as string; // Assuming answer is the previous value
            }
        }
    }



    const onChangeAnswer = (item: string) => {

        props.onUpdateAnswers(props.data.id, props.data.type === 'longText' ? item.toString(): item);
        setAnswer(item);
    };

    const submiAndPeerReviews = (ans?:string, callback?: ()=>void) => {
        if(!answer) return;
        const createAns :CreateAnswerQuestions = {
            page_id: props.data.page_id.toString(),
            answers: [
                {
                    page_item_id: props.data.id,
                    answer: ans ? ans : answer
                }
            ]
        };

        onSubmitAnswer(createAns, {
            onSuccess: () => {
                handleGetPeerReviews();
            },
            onSettled() {
                callback && callback();
            },
        });
    };

    const submitAnswer = () => {
        if(!answer) return;
        const createAns :CreateAnswerQuestions = {
            page_id: props.data.page_id.toString(),
            answers: [
                {
                    page_item_id: props.data.id,
                    answer: answer
                }
            ]
        };

        onSubmitAnswer(createAns);
    };

    const handleGetPeerReviews = () => {
        refetchPeerReviews();
    };

    const QuizRedirection = (type: LessonType): React.JSX.Element => {
        switch (type) {
            case "fileUpload":
                return  (
                    <FileUpload
                        currentFile={props.data.pivot?.answer}
                        id={props.data.id.toString()}
                        onChange={onChangeAnswer}/>
                );

            case "singleChoice":
                return (
                    <div className='mt-[15px]'>
                        <SingleChoice
                            answer={props?.data?.data?.answer as string | undefined}
                            myAnswer={props.data?.pivot?.answer}
                            value={props.data.pivot?.answer?.toString()}
                            onChange={onChangeAnswer}
                            id={props.data.id} options={props.data.data.options}/>
                    </div>
                );

            case "multipleChoice":
                // eslint-disable-next-line no-case-declarations
                const multipleAnswer = props.data?.pivot?.answer as unknown;

                return (
                    <div className='mt-[15px]'>
                        <MultipleChoice
                            value={answer ? answer as string[]: []}
                            onChange={onChangeAnswer}
                            myAnswer={(props.data?.pivot?.answer ) ? multipleAnswer as string[] : []}
                            answer={(props?.data?.data?.answer ) ? props?.data?.data?.answer : []}
                            id={props.data.id} options={props.data.data.options}/>
                    </div>
                );

            case 'textBlock':


                return(
                    <div className='mt-[15px]'>
                        <div
                            className='max-lg:mx-[25px]'
                            dangerouslySetInnerHTML={{ __html: props.data.data.body as string }}/>
                    </div>
                );

            case 'textWithFile':
                return  (
                    <FileUpload
                        downloadFile={props.data.data?.url}
                        currentFile={props.data.pivot?.answer}
                        id={props.data.id.toString()}
                        onChange={onChangeAnswer}/>
                );

            default:
                return (
                    <div className='relative'>
                        {  !contentEditableRef?.current?.innerText && isSyncUser() && isPeerAnswerExist  && (
                            <div
                                className='absolute text-gray-500 top-[20px] left-[18px]'
                                style={{ pointerEvents: 'none', userSelect: 'none' }}>
                                    Enter your Answer
                            </div>
                        )}
                        <div

                            ref={contentEditableRef}
                            contentEditable={true}
                            onInput={(e:React.FormEvent<HTMLDivElement>)=>handleChange(e.currentTarget.innerText)}
                            className='outline-[#b2b2b2] focus:border-gray-200 leading-[24px] mt-[20px] border-[1px] p-[16px] rounded-[12px] text-[14px] font-normal text-base-200' />

                        <div
                            className='absolute text-gray-500 bottom-[-20px] right-2 pointer-events-none select-none text-[12px]'>
                            {` ${isSyncUser() && isPeerAnswerExist ?
                                contentEditableRef?.current?.innerText?.length ? contentEditableRef.current.innerText?.length  : '0' :
                                answer.length ? answer.length : "0"}/1500`
                            }
                        </div>
                    </div>
                );
        }
    };

    const handleOnChangeFilter = (item:string) => {
        setFilterOptions((prevState)=>{

            switch (item) {
                case 'All Cohort':
                    return {
                        ...prevState,
                        my_group: '0'
                    };
                case 'My Answers':
                    return {
                        ...prevState,
                        my_group: '2'
                    };
                case 'My Group':
                    return {
                        ...prevState,
                        my_group: '1'
                    };
                default: // This will cover 'recent' and any other cases
                    return {
                        ...prevState
                    };
            }



        });
    };

    const handleChangeSortBy = (sort: string) => {
        setFilterOptions((prevState)=>{

            return {
                ...prevState,
                sortBy: sort
            };
        });
    };

    const capitalizeFirstLetter = (string:string) => {
        if (!string) return string;

        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getListOfSort = () => {
        switch (filterOptions.sortBy) {
            case 'reactions':
                return ['recent', 'comments'];
            case 'comments':
                return ['recent', 'reactions'];
            case 'recent':
                return ['comments', 'reactions'];
            default: // This will cover 'recent' and any other cases
                return [];
        }
    };

    const getListOfFilter = () => {
        switch (filterOptions.my_group) {
            case '1':
                return ['All Cohort', 'My Answers'];
            case '0':
                return ['My Group', 'My Answers'];
            case '2':
                return ['My Group', 'All Cohort'];
            default: // This will cover 'recent' and any other cases
                return [];
        }
    };

    if(props.data.type === "textBlock"){
        return <div className='my-[50px]'>
            {QuizRedirection(props.data.type)}
        </div>;
    }

    const setupMyReviewCard = () => {
        if (!profileData || !peerReviews || !isAnswered) return;

        const firstPage = peerReviews.pages?.[0];
        const currentComments = firstPage?.meta?.currentComments;
        const userPageItemId = currentComments?.data?.[0]?.user_page_item_id;
        const id = parseInt(userPageItemId);
        const safeId = isNaN(id) ? 0 : id;

        const MyAnswerCard: ReviewData = {
            id: safeId,
            user_id: profileData.id,
            page_item_id: props.data?.id ?? 0,
            comments: currentComments ?? null,
            user: profileData,
            answer: props.data?.pivot?.answer ?? '',
            reactions: {
                data: []
            },
            created_at: props.data?.pivot?.created_at ?? '',
            updated_at: props.data?.pivot?.updated_at ?? '',
        };

        return MyAnswerCard;
    };

    return (
        <>
            <div className={`mt-[10px] bg-white rounded-[30px] max-sm:rounded-none px-[32px] py-[36px] shadow-lg relative
            ${props.data.pivot?.peerAnswersCount && props.data.pivot.answer ? 'border-t-[6px] border-warning' : '' }`}>
                <div className='flex justify-between items-start'>
                    <div>
                        <span className='bg-accent px-[12px] py-[6px] rounded-[999px] text-white font-semibold mr-[20px]'>
                            Question {props.index}
                        </span>


                    </div>
                    {
                        isGreenTick()  ?
                            <img src={ASSETS.assignment.TickGreen}/> :<img src={ASSETS.assignment.TickCircle}/>
                    }


                </div>
                <div className='mt-[12px]'>
                    {<div dangerouslySetInnerHTML={{__html: props.data.data.question}}/>}

                </div>
                {QuizRedirection(props.data.type)}
                {
                    (isPeerAnswerExist)  ? (
                        <div className='mt-[30px] flex flex-row items-center justify-center max-md:flex-col max-md:items-start'>
                            <div className='flex-grow'>
                                <Button
                                    isLoading={isLoadingSubmit}
                                    onClick={()=>submiAndPeerReviews()}
                                    leftIcon={<img src={ASSETS.icon.Send}/>}
                                    containerClass='btn-sm leading-[12px]'
                                    title={isPreviouslyAnswered ? "Update your answer"  :"Submit to view peers’ answers"}/>
                            </div>
                            <div className='flex max-md:my-[20px]'>
                                <Dropdown
                                    icon={<div
                                        className='text-black border flex items-center justify-center px-[12px]
                                text-[14px] font-semibold leading-[20px] gap-x-[8px]
                                py-[7px] rounded-[8px] border-neutral mr-[12px]'>
                                        <img src={ASSETS.assignment.Filter}/>
                                        {filterOptions.my_group == '1' ? 'My Group' : filterOptions.my_group == '2' ?  'My Answer' : 'All Cohort'}
                                        <img src={ASSETS.icon.chevron}/>
                                    </div>}>
                                    <li className=''>
                                        {
                                            getListOfFilter().map((item)=> <a key={item} onClick={()=> handleOnChangeFilter(item)}>{item}</a>)
                                        }
                                    </li>
                                </Dropdown>
                                <Dropdown
                                    icon={<div
                                        className='text-black border flex items-center justify-center px-[12px]
                                text-[14px] font-semibold leading-[20px] gap-x-[8px]
                                py-[7px] rounded-[8px] border-neutral'>
                                        <img src={ASSETS.icon.Sort}/>
                                        {capitalizeFirstLetter(filterOptions.sortBy)}
                                        <img src={ASSETS.icon.chevron}/>
                                    </div>}>
                                    <li className=''>
                                        {
                                            getListOfSort().map((item)=><a key={item} onClick={()=>handleChangeSortBy(item)}>{capitalizeFirstLetter(item)}</a> )
                                        }
                                    </li>
                                </Dropdown>

                            </div>
                        </div>
                    ) : <></>
                }
                {
                    (props.data.type === 'singleChoice'  || props.data.type === 'multipleChoice' ) && (
                        <div className='flex-grow mt-[20px]'>
                            <Button
                                isLoading={isLoadingSubmit}
                                onClick={submitAnswer}
                                leftIcon={<img src={ASSETS.icon.Send}/>}
                                containerClass='btn-sm'
                                title='Submit answers'/>
                        </div>
                    )
                }

                <div className='overflow-x-auto max-h-[600px]'>


                    {
                        setupMyReviewCard() && props.data.is_reviewable ?
                            <ReviewCard
                                submitPeerReview={submiAndPeerReviews}
                                type={props.data.type}
                                optionsForMultiple={props?.data?.data?.options}
                                isMultipleChoice={props?.data?.type === "multipleChoice" || props?.data?.type === 'singleChoice'}
                                data={setupMyReviewCard() as ReviewData}
                                user_page_item_id={peerReviews?.pages[0]?.meta?.user_page_item_id}
                                handlePeerReviews={handleGetPeerReviews}/>
                            : <></>
                    }
                    {
                        peerReviews && filterOptions.my_group != '2' && props.data.is_reviewable && <Reviews
                            optionsForMultiple={props?.data?.data?.options}
                            isMultipleChoice={props?.data?.type === "multipleChoice" || props?.data?.type === 'singleChoice'}
                            hasNextPage={hasNextPage}
                            isLoading={isFetchingNextPage}
                            handleLoadMoreReviews={fetchNextPage}
                            questionType={props.data.type}
                            data={peerReviewsFlat} handlePeerReviews={handleGetPeerReviews}/>
                    }
                </div>

                {
                    props.data.type === 'singleChoice' && singleChoiceAnsStatusIsWrong && props.data.pivot?.answer?.toString()   && (
                        <div className='mt-[40px]'>
                            <span className='bg-accent px-[12px] py-[6px] rounded-[999px] text-white font-semibold mr-[20px]'>
                                {singleChoiceAnsStatusIsWrong  && "Incorrect" }
                            </span>

                            <div className='mt-[40px]'>
                                <div dangerouslySetInnerHTML={{ __html: props.data.data?.wrongFeedback as string }} />
                            </div>
                        </div>
                    )
                }

                {
                    ((answerStatus === 'incorrect' && props.data.data?.wrongFeedback) || (answerStatus === 'partially-correct' && props.data.data?.partiallyCorrectFeedback) ) ?
                        <div className='mt-[40px]'>
                            <span className={`bg-accent px-[12px] py-[2px] rounded-[999px] text-white font-semibold mr-[20px text-[14px] h-[20px]
                            ${answerStatus === 'partially-correct' ? 'bg-[#F26725]': ''}`}>
                                {answerStatus === 'incorrect' ? "Incorrect" : 'Partially Correct'}
                            </span>

                            <div className='mt-[40px]'>
                                {answerStatus === 'incorrect' ?
                                    <div dangerouslySetInnerHTML={{ __html: props.data.data?.wrongFeedback as string }} />

                                    :
                                    <div dangerouslySetInnerHTML={{ __html: props.data.data?.partiallyCorrectFeedback as string }} />
                                }
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>

        </>
    );
};

export default Question;
