export function formatDateToGMT7AndFormat(dateString:string) {
    // Parse the date from the input string
    const date = new Date(dateString);

    // Convert to GMT+7 timezone
    date.setUTCHours(date.getUTCHours() + 7);

    // Format the date to the required format
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short', // Short month representation
        day: 'numeric'  // Numeric day of the month
    });

    return formattedDate;
}

export const getHoursText = (minutes: number):string =>{
    if(minutes <= 60 ) return 'Hour';

    return 'Hours';
};
