import React from 'react';

interface LoadingPlaceholderProps {
    containerStyle ?: string;
}

export const LoadingPlaceholder = (props:LoadingPlaceholderProps): JSX.Element => {
    return (
        <div className={`flex items-center justify-center ${props?.containerStyle}`}>
            <progress className='progress progress-primary w-56' />
        </div>
    );
};

export default LoadingPlaceholder;
