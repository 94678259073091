import { ASSETS } from 'assets';
import React  from 'react';
import './Navbar.scss';
import { useNavigate } from 'react-router-dom';
import { ProtectedRouteNames } from 'config/routes/ProtectedRoute';
import { Link } from 'react-router-dom';
import { publicRouteNames } from 'config/routes/PublicRoutes';
import { clearAuthAndStorage, queryClient } from 'config/api';
import { useGetProfile } from 'hooks/user/user';
import Dropdown from 'components/Dropdown/Dropdown';
import {  useGoogleOneTapLogin } from '@react-oauth/google';
import { useLoginSocials } from 'hooks/auth/auth';
import ToastMessage from 'components/ToastMessage/ToastMessage';



// import { useGetProfile } from 'hooks/user/user';


// interface NavbarProps {
//     onPressNavbar: () => void;
// }

export const Navbar = (): JSX.Element => {
    const {data} = useGetProfile();
    const navigate = useNavigate();
    // const {data} = useGetProfile();
    const isGuestMode = data?.type === 'guest' ? true : false;

    const {onLoginSocials} = useLoginSocials();

    const navigateToDashboard = () => {
        navigate(ProtectedRouteNames.DASHBOARD);
    };

    const logoutUser = () => {
        clearAuthAndStorage();
        navigate(publicRouteNames.LOGIN);
    };

    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {

            if(credentialResponse.credential ){
                onLoginSocials({
                    provider: 'google',
                    jwt_token: credentialResponse.credential,
                }, {
                    onSuccess: () => {
                        queryClient.clear();
                        location.href = '/';
                        sessionStorage.clear();
                    }
                });
            }
        },
        onError: () => {
            ToastMessage({
                message: "Login Failed",
                title: "Server Error",
            });
        },
        disabled: !isGuestMode
    });



    return (
        <div className='drawer drawer-end'>
            <input id={`mobile-drawer`} type='checkbox' className='drawer-toggle' />
            {
                data?.type !== 'guest' && (
                    <div className='absolute top-[55px] right-[40px] max-md:hidden max-2xl:top-[10px] z-[1000]'>
                        <Dropdown
                            containerClass='z-[1000]'
                            icon={<div className='bg-white rounded-[1000px] border-0 cursor-pointer hover:bg-white hover:bg-white/80 max-md:hidden m-1 btn  btn-circle'>

                                <img src={ASSETS.layout.hamburger} alt='logo' className='cursor-pointer'/>

                            </div>}>
                            <div className=''>
                                {
                                    <ul className=''>
                                        {data?.type != 'guest' && <li className='text-[20px] mb-[15px]'><Link to={ProtectedRouteNames.PROFILE}>Profile</Link></li>}
                                        {data?.defaultProgram.pivot.type === 'sync' ? <li className='text-[20px] mb-[15px]'><Link to={ProtectedRouteNames.COHORT}>Cohort</Link></li> : <></>}
                                        {data?.type != 'guest' && <li className='text-[20px] mb-[15px]'><Link to={ProtectedRouteNames.PROFILE_SETTINGS}>Settings</Link></li> }
                                        <li className='text-[20px]'><div onClick={logoutUser}>{`Logout`}</div></li>
                                    </ul>
                                }
                            </div>
                        </Dropdown>
                    </div>
                )
            }
            <div className='drawer-content'>
                <div className='p-0 navbar'>
                    {/* Page content here */}
                    <div className='background-div w-full overflow-hidden mb-[60px] 2xl:min-h-[240px]'>
                        <img src={ASSETS.layout.headerWave} alt='logo-2' className='absolute top-[0] right-0 max-sm:opacity-0'/>
                        <img src={ASSETS.layout.ovalWhite} alt='logo' className='absolute left-0 top-0 sm:opacity-0' />
                        <div className='flex logo-div
                            ml-[11%] mr-[40px] mt-[20px] max-lg:ml-[17%] max-sm:ml-[8%] max-sm:mt-[10px]
                            mb-[100px] w-full !bg-transparent justify-between items-center'>
                            <img src={ASSETS.logo.withText} alt='logo' className='cursor-pointer z-10 w-[182px]'
                                onClick={navigateToDashboard}/>
                            {
                                data?.type !== 'guest' && (
                                    <>
                                        <label htmlFor={`mobile-drawer`} className='p-[16px] bg-white rounded-[1000px] cursor-pointer drawer-button md:hidden'>
                                            <img src={ASSETS.layout.hamburger} alt='logo' className='cursor-pointer'/>
                                        </label>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='drawer-side z-50'>
                <label htmlFor={`mobile-drawer`} aria-label='close sidebar' className='' />
                <ul className='menu p-4 w-full min-h-full bg-white text-dark'>
                    <div className='flex justify-between mx-[25px] mt-[25px] mb-[60px] items-center '>
                        <img src={ASSETS.logo.withText} alt='logoWhite' className='cursor-pointer z-10 w-[190px]'/>
                        <label htmlFor={`mobile-drawer`} className='p-[16px] bg-white rounded-[1000px] cursor-pointer drawer-button'>
                            <img src={ASSETS.layout.Cross} alt='logo' className='cursor-pointer z-10 w-[40px]'/>
                        </label>
                    </div>
                    <li className='text-li'><Link to={ProtectedRouteNames.PROFILE}>Profile</Link></li>
                    {data?.defaultProgram.pivot.type == 'sync' && <li className='text-li'><Link to={ProtectedRouteNames.COHORT}>Cohort</Link></li>}
                    <li className='text-li'><Link to={ProtectedRouteNames.PROFILE_SETTINGS}>Settings</Link></li>
                    <li className='text-li'><div onClick={logoutUser}>Logout</div></li>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
