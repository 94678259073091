import { axiosInstance, endpoints } from "config/api";
import { isResponseSuccess } from "services/serviceHelper";
import { CreateReaction } from "./ReactionType";

export class ReactionServices {
    async createReaction(data: CreateReaction): Promise<unknown> {
        const response = await axiosInstance.post(endpoints.reaction.createReaction, data);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

    async deleteReaction(id_reaction: string): Promise<unknown> {
        const response = await axiosInstance.delete(endpoints.reaction.deleteReaction(id_reaction));

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }
}
