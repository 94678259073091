import React from 'react';

interface ToastMessageProps {
    title:string;
    message: string
}

export const ToastMessage = (props:ToastMessageProps): JSX.Element => {
    return (
        <div className='text-left text-white'>
            <h5 className='font-bold text-white'>{props.title}</h5>
            <label className='text-white mt-[20px]'>{props.message}</label>
        </div>
    );
};

export default ToastMessage;
