import { ASSETS } from 'assets';
import Button from 'components/Button/Button';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextInput from 'components/TextInput/TextInput';
import { Link, useLocation } from 'react-router-dom';
import { publicRouteNames } from 'config/routes/PublicRoutes';
import '../Authentication.scss';
import { useLoginAuth } from '../hooks/useLogin';

// Define your validation schema using Yup
const schema = yup.object().shape({
    email: yup.string().required("Email is required").email("Invalid email format"),
    password: yup.string().required("Password is required"),
});

export const Login = (): JSX.Element => {
    const { control, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const location = useLocation();

    const {onSubmit, requestGoogleLogin, pageLoading, handleGuestLogin} = useLoginAuth();

    const isGuest = sessionStorage.getItem('guest');

    useEffect(()=> {
        if(isGuest === 'true'){
            handleGuestLogin();
        }

        if(location.search === '?guest=true'){
            handleGuestLogin();
        }

    },[isGuest, location.pathname]);


    return (
        <div className='flex flex-wrap overflow-y-hidden relative gradient-overlay'>
            <img src={ASSETS.waves.WaveTop1} alt='Wave Top'
                className='absolute top-0  left-0 w-1/2 max-md:w-full max-md:top-[-85px] max-sm:top-[-25px]' />
            {/* <img src={ASSETS.waves.WaveTop2} alt='Wave Top 2'
                className='absolute top-0  left-0 w-1/2 max-md:w-full max-md:top-[-30px] max-sm:top-[0px] z' /> */}

            {/* Form Section */}
            <div className='
                transition-transform duration-500 ease-in-out
                w-1/2 max-md:w-full flex items-center justify-center p-4 max-md:h-1/2 scale-125 max-2xl:scale-110 max-xl:scale-100 z-20' >
                <form className='w-full max-w-md px-[0px] max-lg:px-[20px] max-md:mt-[90px] max-md:mb-[40px]' onSubmit={handleSubmit(onSubmit)}>
                    <h1 className='text-[32px] font-semibold mb-[10px] leading-[40px] text-white'>
                        Welcome to LauncHER
                    </h1>
                    <div className='mb-[40px] text-white leading-[28px] max-md:mb-[32px]'>
                        To get started, please sign in!
                    </div>
                    <div className='mb-[20px] flex flex-col'>
                        <Controller
                            name='email'
                            control={control}
                            defaultValue=''
                            render={({ field }) =>
                                <TextInput {...field}
                                    labelClass='text-white'
                                    label='Email'
                                    error={errors.email?.message}
                                    className='shadow appearance-none border rounded w-full py-2 px-3 input'
                                    type='email'/>}/>
                    </div>
                    <div className='mb-[25px] flex flex-col gap-y-[10px]'>
                        <div className='flex justify-between mb-2'>
                            <label
                                htmlFor='password'
                                className='text-white'>
                                Password
                            </label>
                            <Link to={publicRouteNames.FORGOT_PASSWORD} className='text-white font-medium cursor-pointer'>
                                Forgot Password?
                            </Link>
                        </div>
                        <Controller
                            name='password'
                            control={control}
                            defaultValue=''
                            render={({ field }) => <TextInput {...field}
                                className='input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                                error={errors.password?.message}
                                type='password'
                                autocomplete='false' />}/>
                    </div>
                    <div className='flex flex-col items-center justify-between'>

                        <Button
                            type='submit'
                            containerClass='w-full'
                            isLoading={pageLoading}
                            title='Login'/>
                        <div
                            className='text-white divider before:bg-base-300 after:bg-base-300 before:h-[1px] after:h-[1px]'>
                                OR
                        </div>


                        <div
                            className='btn bg-white text-black border-0 w-full hover:bg-white hover:shadow-2xl'
                            onClick={()=> requestGoogleLogin()}>
                            <img src={ASSETS.icon.GoogleLogo}/>
                                Login With Google
                        </div>

                        <div className='mt-[20px] text-white'>
                            {"Not registered yet ? "}
                            <Link
                                to={publicRouteNames.SIGNUP}
                                className='text-white font-medium cursor-pointer'>
                                Register
                            </Link>
                        </div>
                    </div>
                </form>
            </div>

            {/* Image Section */}
            <div className='w-1/2 h-screen z-10
                max-md:w-full max-md:h-1/2
                flex items-center justify-center'>
                <img src={ASSETS.images.LoginCover} alt='Login Visual' className='w-full h-full object-cover' />
            </div>

            <img src={ASSETS.waves.WaveBot1} alt='Wave Bot' className='absolute bottom-0 left-[0px] w-1/2' />
            <img src={ASSETS.authentication.SuperGraphicsAuth} alt='Auth Super' className='absolute bottom-[0px] left-[0px] w-1/2' />
            {/* <img src={ASSETS.waves.WaveBot2} alt='Wave Bot 2' className='absolute bottom-0 left-[0px] w-1/2' /> */}

        </div>
    );
};

export default Login;
