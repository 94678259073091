import { axiosInstance, endpoints } from "config/api";
import { isResponseSuccess } from "services/serviceHelper";
import { CohortResponse } from "./CohortType";

export class CohortServices {
    async getDefaultCohort(params: { expertises: string[], sectors: string[], isMyGroup:boolean, page: number }): Promise<CohortResponse> {
        const queryParts = [];

        if (params.expertises.length > 0) {
            queryParts.push(params.expertises.map(expertise => `filter[expertises][]=${encodeURIComponent(expertise)}`).join('&'));
        }

        if (params.sectors.length > 0) {
            queryParts.push(params.sectors.map(sector => `filter[sectors][]=${encodeURIComponent(sector)}`).join('&'));
        }

        if (params.isMyGroup) {
            queryParts.push(`filter[my_group]=${params.isMyGroup ? '1': '0'}`);
        }

        const queryString = queryParts.join('&');
        // Construct the complete URL
        const url = `${endpoints.cohort.cohorts}?${queryString}&page=${params.page}`;

        // Use the complete URL in the GET request
        const response = await axiosInstance.get(url);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }
}

