import React from 'react';
import {  Navigate, useLocation } from 'react-router-dom';
// import {  } from './PublicRoutes';
import { getAuthToken, getAxiosAuth, setAuthToken } from 'config/api';
import { publicRouteNames } from './PublicRoutes';
import { ProtectedRouteNames } from './ProtectedRoute';
// import { ProtectedRouteNames } from './ProtectedRoute';
// import { ProtectedRouteNames } from './ProtectedRoute';

interface RouteGuardProps {
  children: JSX.Element
  isProtected: boolean
}

const RouteGuard = (props: RouteGuardProps): React.JSX.Element => {
    const token = getAuthToken();
    const location = useLocation();
    const localToken = getAxiosAuth();

    if (!token && props?.isProtected && !localToken) {
        return <Navigate to={publicRouteNames.LOGIN}/>;
    }

    if(token){
        if(props?.isProtected){
            setAuthToken(token, true);

            // Check if current path is a public route based on keys from publicRouteNames
        }

        if (Object.values(publicRouteNames).includes(location.pathname)) {

            if(location.pathname === publicRouteNames.REGISTER){
                const queryParams = new URLSearchParams(location.search);
                const token = queryParams.get('token');

                if(!token){
                    return <Navigate to={ProtectedRouteNames.DASHBOARD}/>;
                }
            }else if(location.pathname === publicRouteNames.SIGNUP){
                const queryParams = new URLSearchParams(location.search);
                const token = queryParams.get('token');

                if(!token){
                    return <Navigate to={ProtectedRouteNames.DASHBOARD}/>;
                }
            }

            else{
                return <Navigate to={ProtectedRouteNames.DASHBOARD}/>;
            }

        }

    }

    return props.children;
};

export default RouteGuard;
