import axios from "axios";
import { API_BASE_URL } from "./constant";
import { clearAllLocalStorage, getLocalStorage, removeLocalStorage, setLocalStorage, storageKeys} from "utils/storage";
import { QueryClient } from "@tanstack/react-query";
// import { QueryClient as QueryClientCore } from '@tanstack/query-core';

export const axiosInstance = axios.create({
    baseURL: API_BASE_URL
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    function(err) {
        if (err && err.status === 401) {

            queryClient.clear();
            clearAllLocalStorage();
        }

        return Promise.reject(err);
    }
);

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            cacheTime: 1000 * 60 * 60 // 1 hour
        }
    }
});


export const endpoints = {
    auth: {
        login: 'auth/login',
        loginSocial: 'auth/social-login',
        register: 'auth/register',
        reset_password: 'auth/reset-password',
        forgot_password: 'auth/forgot-password',
        verify_email: (id:string, hash:string) => `auth/verify-email/${id}/${hash}`,
        resend_verification: 'auth/resend-verification-email',
        logout: 'auth/logout',
        guest_token: 'auth/get-guest-token',
        guest_register: 'auth/convert-guest',
    },
    profile: {
        me: '/me',
        get_user: (id:string) => `users/${id}`
    },
    program: {
        modules: '/programs/default',
    },
    assignment: {
        lessons: (id:string) => `/pages/${id}`,
        submitAnswer:  (page_id:string) => `/pages/${page_id}/submit`,
        getPeerReviews: (page_item_id: string) => `/page-items/${page_item_id}/reviews`,
        getComments: (page_item_id:string, user_id: string) => `/page-items/${page_item_id}/reviews/${user_id}/comments`,
        createComments: '/comments',
        updateComments: (comment_id: string) => `/comments/${comment_id}`,
    },
    file: {
        upload: '/files/upload'
    },
    resources: {
        sectors: '/lists/sectors',
        expertises: '/lists/expertises',
        sdgs: '/lists/sdgs'
    },
    cohort: {
        cohorts:`/groups/default`
    },
    reaction: {
        createReaction: '/reactions',
        deleteReaction: (id_reaciton:string) => `/reactions/${id_reaciton}`
    }
};

export function setAuthToken(token: string, isSave:boolean) {
    if(isSave){
        setLocalStorage(storageKeys.authToken, token);
    }

    axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + token;
}

export function unsetAuthToken() {
    delete axiosInstance.defaults.headers['Authorization'];

    removeLocalStorage(storageKeys.authToken);
}

export function getAuthToken(){
    return getLocalStorage(storageKeys.authToken);
}

export const getAxiosAuth = ()=>  axiosInstance.defaults.headers['Authorization'];

export async function clearAuthAndStorage() {
    unsetAuthToken();
    removeLocalStorage(storageKeys.authToken);
    queryClient.clear();
}
