import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { App } from './containers/App';
import 'styles/styles.scss';
import { queryClient } from 'config/api';
import { QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { APP_ENV, GOOGLE_CLIENT_ID } from 'config/constant';
import { Provider, ErrorBoundary } from '@rollbar/react'; // <-- Provider imports 'rollbar' for us


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const rollbarConfig = {
    accessToken: '7ba75174ed7d4a16a240a758e9d79ef5',
    environment: APP_ENV,
};


root.render(
    <React.StrictMode>
        <Provider config={rollbarConfig}>
            <ErrorBoundary>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID as string}>
                    <QueryClientProvider client={queryClient}>
                        <App />
                    </QueryClientProvider>
                </GoogleOAuthProvider>
            </ErrorBoundary>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
