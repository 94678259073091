import Button from 'components/Button/Button';
import Layout from 'components/Layout/Layout';
import LoadingPlaceholder from 'components/LoadingPlaceholder/LoadingPlaceholder';
import TextInput from 'components/TextInput/TextInput';
import React from 'react';
import { useProfileForm } from './hooks/useProfileForm';
import { MAX_TEXT_LENGTH, SDG_OPTIONs } from 'config/constant';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Icon } from '@mui/material';
import { COUNTRY_DATA } from 'data/country';

export const SettingProfile = (): React.JSX.Element => {
    const {
        isLoading,
        updateProfileLoading,
        handleImageChange,
        triggerFileInput,
        fileInputRef,
        form,
        onFormUpdate,
        SECTOR_OPTIONS,
        EXPERTISE_OPTIONS,
        handleExpertiseChange,
        handleSectorChange,
        handleRemoveExpertises,
        handleRemoveSectors,
        expertiseSelectRef,
        sectorSelectRef,
        sectorForm,
        expertiseForm,
        handleSubmitForm,
        handleSdgChange,
        sdgForm,
        sdgSelectRef,
        handleRemoveSdg,
        handleSubmitPass
    } = useProfileForm();

    return (
        <Layout>
            {
                isLoading ?
                    <LoadingPlaceholder  containerStyle='h-[60vh]'/>
                    :
                    <div className='container mb-[200px]'>
                        <input
                            type='file'
                            accept='image/*'
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                            ref={fileInputRef}/>
                        <div className='card bg-white py-[40px] px-[80px] max-sm:px-[20px] max-sm mx-[20px]'>
                            <div className='flex items-center justify-between mb-[50px]'>
                                <h1 className=' text-[30px]'>Update My Profile</h1>
                            </div>
                            <div className='flex items-center justify-center'>
                                <div className=' relative group hover:opacity-100 cursor-pointer  w-40'>
                                    <div className='avatar w-40'>
                                        <div className='w-40 h-40 rounded-full overflow-hidden shadow-md'>
                                            <img src={form?.avatar || 'https://placehold.co/96x96'} alt='Profile' />
                                        </div>
                                    </div>
                                    {
                                        updateProfileLoading ?
                                            <div
                                                className='w-40 h-40 absolute rounded-[9999px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-100 group-hover:opacity-100 transition-opacity duration-300'>
                                                <span className='loading loading-spinner text-secondary' />
                                            </div>
                                            :
                                            <div
                                                onClick={triggerFileInput}
                                                className='w-40 h-40 absolute rounded-[9999px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                                                <span className='text-white'>
                                                Change
                                                </span>
                                            </div>
                                    }
                                </div>

                            </div>
                            <div className='mt-[30px] grid grid-cols-2 max-sm:grid-cols-1 gap-x-[15px] gap-y-[15px]'>
                                <TextInput className='input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                                // error={errors.password?.message}
                                    label='Name'
                                    value={form?.name}
                                    onChange={(e)=> onFormUpdate({name: e.target.value})}
                                    autocomplete='false' />
                                <TextInput className='input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                                // error={errors.password?.message}
                                    label='Title'
                                    value={form?.title ?? ''}
                                    onChange={(e)=> onFormUpdate({title: e.target.value})}
                                    autocomplete='false' />
                                <div className='form-control w-full'>
                                    <label
                                        htmlFor={'sectors'}>
                                        Sectors
                                    </label>
                                    <select
                                        className='select select-bordered shadow mt-[10px] bg-white'
                                        name='sectors'
                                        onChange={handleSectorChange}
                                        ref={sectorSelectRef}>
                                        <option value=''>
                                            Select Sectors
                                        </option>
                                        {
                                            SECTOR_OPTIONS?.map((item)=>  <option key={item} value={item} className={`${sectorForm.includes(item) ? 'hidden': '' } max-sm:line-clamp-1`}>{item}</option>)
                                        }

                                    </select>

                                    <div className='mt-[15px] flex gap-[5px] flex-wrap'>
                                        {
                                            sectorForm.map((item, index) => (
                                                <div className='group flex items-center gap-[2px] cursor-pointer' key={item} onClick={() => handleRemoveSectors(index)}>
                                                    <span  className='badge text-accent bg-base-content border-0 cursor-pointer'>
                                                        {item}
                                                    </span>
                                                    <div className='invisible group-hover:visible transition-opacity duration-200'>
                                                        <Icon className='text-accent'>
                                                            <HighlightOffIcon />
                                                        </Icon>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='form-control w-full'>
                                    <label htmlFor='expertise'>
                                        Expertise
                                    </label>
                                    <select
                                        className='select select-bordered shadow mt-[10px] bg-white'
                                        name='expertise'
                                        ref={expertiseSelectRef}
                                        onChange={handleExpertiseChange}>
                                        <option value=''>
                                            Select Expertise
                                        </option>
                                        {EXPERTISE_OPTIONS?.map((item) => (
                                            <option key={item} value={item} className={`${expertiseForm.includes(item) ? 'hidden': '' } cursor-pointer`}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>

                                    <div className='mt-[15px] flex gap-[5px] flex-wrap'>
                                        {
                                            expertiseForm.map((item, index) => (
                                                <div className='group flex items-center gap-[2px] cursor-pointer' key={item} onClick={() => handleRemoveExpertises(index)}>
                                                    <span  className='badge text-accent bg-base-content border-0 cursor-pointer'>
                                                        {item}
                                                    </span>
                                                    <div className='invisible group-hover:visible transition-opacity duration-200'>
                                                        <Icon className='text-accent'>
                                                            <HighlightOffIcon />
                                                        </Icon>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className='form-control w-full'>
                                    <label htmlFor='expertise'>
                                        {" SDGs"}
                                    </label>
                                    <select
                                        className='select select-bordered shadow mt-[10px] bg-white'
                                        name='expertise'
                                        ref={sdgSelectRef}
                                        onChange={handleSdgChange}>
                                        <option value=''>
                                            Select SDG
                                        </option>
                                        {SDG_OPTIONs?.map((item) => (
                                            <option key={item.text} value={item.text} className={`${sdgForm.includes(item.text) ? 'hidden': '' }`}>
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>

                                    <div className='mt-[15px] flex gap-[5px] flex-wrap'>
                                        {
                                            sdgForm.map((item, index)=> <div className='group flex items-center gap-[2px] cursor-pointer' key={item} onClick={()=>handleRemoveSdg(index)}>
                                                <img className='w-[30px]' src={SDG_OPTIONs.find((child)=> child.text == item )?.images}/>

                                                <div className='invisible group-hover:visible transition-opacity duration-200 '>
                                                    <Icon className='text-accent'>
                                                        <HighlightOffIcon />
                                                    </Icon>
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                </div>

                                <div className='form-control w-full'>
                                    <label
                                        htmlFor={'country'}>
                                        Country
                                    </label>
                                    <select
                                        className='select select-bordered shadow mt-[10px] bg-white'
                                        name='country'
                                        value={form.address?.country}
                                        onChange={(e) => {
                                            const selectedIndex = e.currentTarget.selectedIndex; // Get the index of the selected option
                                            const selectedOption = e.currentTarget.options[selectedIndex]; // Get the selected option element
                                            const flag = selectedOption.getAttribute('data-flag'); // Get the data-flag attribute of the selected option

                                            // Now you can use the flag value to update the form state or perform other actions
                                            onFormUpdate({
                                                address: {
                                                    country: e.currentTarget.value,
                                                    city: "",
                                                    flag: flag as string, // Use the flag value here
                                                }
                                            });
                                        }}>
                                        <option value={''}> Select Country</option>
                                        {
                                            COUNTRY_DATA?.ids.map((item)=>

                                            {
                                                const data = COUNTRY_DATA.byId[item as keyof typeof COUNTRY_DATA.byId];



                                                return <option
                                                    data-flag={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data.code}.svg`}
                                                    value={item}
                                                    key={item}>{item}
                                                </option>;
                                            }
                                            )
                                        }
                                    </select>
                                </div>

                                <TextInput className='input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                                    // error={errors.password?.message}
                                    label='City'
                                    value={form?.address?.city ?? ''}
                                    onChange={(e)=> onFormUpdate({
                                        address: {
                                            city: e.currentTarget.value,
                                        }
                                    })}
                                    autocomplete='false' />


                                <div className='flex flex-col gap-y-[10px] form-control  col-span-2 max-sm:col-span-1 relative'>
                                    <label
                                        htmlFor={'description'}>
                                        Description
                                    </label>
                                    <textarea
                                        maxLength={MAX_TEXT_LENGTH}
                                        name='description'
                                        value={form?.description ?? ''}
                                        onChange={(e)=> onFormUpdate({description: e.target.value})}
                                        className='textarea textarea-bordered h-24 shadow !bg-white'
                                        placeholder='' />

                                    <div
                                        className='absolute text-gray-500 bottom-[-14px] right-2 pointer-events-none select-none text-[12px]'>
                                        {`${form?.description?.length  ? form?.description?.length  : '0'}/1500`}
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-[10px] form-control  col-span-2 max-sm:col-span-1 relative'>
                                    <div className='flex gap-x-[5px] items-center justify-between'>
                                        <label
                                            htmlFor={'achievements'}>
                                            Achievements
                                        </label>
                                    </div>
                                    <textarea
                                        maxLength={MAX_TEXT_LENGTH}
                                        onChange={(e)=> onFormUpdate({achievements: [e.target.value]})}
                                        name='achievements'
                                        value={(form?.achievements && form?.achievements?.length > 0) ? form?.achievements[0]: ''}

                                        className='textarea textarea-bordered h-24 shadow !bg-white'
                                        placeholder='' />

                                    <div
                                        className='absolute text-gray-500 bottom-[-14px] right-2 pointer-events-none select-none text-[12px]'>
                                        {`${(form?.achievements && form?.achievements?.length > 0) ? form?.achievements[0].length  : '0'}/1500`}
                                    </div>
                                </div>

                                <div className='col-span-2 max-sm:col-span-1 mt-[40px]'>
                                    <div className='flex justify-end	'>
                                        <Button title='Submit Changes' isLoading={updateProfileLoading} onClick={handleSubmitForm}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card bg-white py-[40px] px-[80px] max-sm:px-[20px] max-sm mx-[20px] mt-[20px]'>
                            <div className='flex items-center justify-between mb-[50px]'>
                                <h1 className=' text-[30px]'>Security</h1>
                            </div>
                            <div className='mt-[30px] flex flex-col max-sm:grid-cols-1 gap-[15px]'>
                                <div className='col-span-2 '>
                                    <TextInput className='input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                                        // error={errors.password?.message}
                                        label='Email'
                                        value={form?.email}
                                        onChange={(e)=> onFormUpdate({email: e.target.value})}
                                        disabled={true}
                                        autocomplete='false' />
                                </div>
                                <div className='flex max-sm:flex-col gap-[15px]' >
                                    <div className='relative col-span-1'>
                                        <TextInput
                                            label='New Password'
                                            className='shadow appearance-none border rounded w-full py-2 px-3 input'
                                            type='password'
                                            value={form.password}
                                            onChange={(e)=> onFormUpdate({password: e.target.value})}
                                            autocomplete='false'  />
                                    </div>
                                    <div className='relative col-span-1'>
                                        <TextInput  className='input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                            type='password'
                                            label='Confirm New Password'
                                            value={form.password_confirmation}
                                            onChange={(e)=> onFormUpdate({password_confirmation: e.target.value})}
                                            autocomplete='false'  />
                                    </div>
                                </div>

                                <div className='col-span-2  max-sm:col-span-1 mt-[40px]'>
                                    <div className='flex justify-end '>
                                        <Button title='Save' isLoading={updateProfileLoading} onClick={handleSubmitPass}/>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
            }
        </Layout>
    );
};

export default SettingProfile;
