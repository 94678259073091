
import React from 'react';
import Lottie from 'react-lottie';
import  * as NoData from 'assets/lottie/lottie_no_data.json';

export const EmptyPlaceholder = (): React.JSX.Element => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: NoData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }

    };

    return (
        <div className='flex justify-center items-center h-[400px]'>
            <Lottie options={defaultOptions}
                height={400}
                width={400}
                isStopped={false}
                isPaused={false}/>
        </div>
    );
};

export default EmptyPlaceholder;
