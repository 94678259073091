import { useMutation, useQuery } from "@tanstack/react-query";
import { endpoints } from "config/api";
import { ModuleServices } from "services/module/ModuleServices";

const ModuleServiceInstance = new ModuleServices();

export const useGetModules = () => {
    const {data, isLoading} = useQuery({
        queryKey: [endpoints.program.modules],
        queryFn: ModuleServiceInstance.getModules
    });

    return {data, isLoading};
};

export const useGetModulesMutation = () => {
    return  useMutation({
        mutationFn: ModuleServiceInstance.getModules
    });
};
