import React from 'react';

interface ChipProps {
    children: JSX.Element
}

export const Chip = (props:ChipProps): JSX.Element => {
    return (
        <div className='flex bg-base-content items-center gap-x-[4px] rounded-[9999px] px-[6px] py-[3px]'>
            {props.children}
        </div>
    );
};

export default Chip;
