import axios from "axios";
import { API_COUNTRY } from "./constant";

export const axiosInstanceCountry = axios.create({
    baseURL: API_COUNTRY
});


export const resourceEndpoints = {
    getCountry: '/flag/images',
    getStateByCountry: 'states'
};
