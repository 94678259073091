import Layout from 'components/Layout/Layout';
import LoadingPlaceholder from 'components/LoadingPlaceholder/LoadingPlaceholder';
import { SDG_OPTIONs } from 'config/constant';
import { useGetExpertises, useGetSectors } from 'hooks/resources/resources';
import { useGetUserData } from 'hooks/user/user';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import SettingsIcon from '@mui/icons-material/Settings';

export const UserProfile = (): JSX.Element => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {data,isLoading: isGetProfileLoading} = useGetUserData(id as string);
    const {isLoading: isGetExpertiseLoading} = useGetExpertises();
    const {isLoading: isGetSectorsLoading} = useGetSectors();

    const isLoading = isGetProfileLoading || isGetExpertiseLoading || isGetSectorsLoading;


    const goBack = () => {
        navigate(-1);
    };

    return (
        <Layout>
            {
                isLoading ?
                    <LoadingPlaceholder  containerStyle='h-[60vh]'/>
                    :
                    <div className='container mb-[200px]'>
                        <div className='card bg-white py-[40px] px-[80px] max-sm:px-[20px] mx-[20px]'>
                            <div onClick={goBack} className='cursor-pointer'>{'< Go Back'}</div>
                            <div className='relative group hover:opacity-100'>
                                <div className='avatar flex justify-center '>
                                    <div className='w-[192px] h-[212px] overflow-hidden relative py-[10px]'>
                                        <img src={data?.avatar || 'https://placehold.co/192x192'} className='shadow-md rounded-full ' alt='Profile' />
                                        <div className='avatar  absolute top-[150px] right-[0px]'>
                                            <div className='w-[48px] rounded-full'>
                                                <img src={data?.address.flag}
                                                    className='w-[48px] h-[48px] rounded-full '/>
                                            </div>
                                        </div>
                                        <div className=' w-[48px] rounded-full  shadow-md' />
                                    </div>

                                </div>
                            </div>
                            <div className='text-[16px] font-semibold text-center mt-[16px]'>
                                {data?.name}
                            </div>
                            <div className='text-[14px] font-normal text-center mt-[8px]'>
                                {data?.title || "-- no title --"}
                            </div>
                            <div className='text-[14px] font-normal text-center mt-[4px]'>
                                {`${data?.address?.country || " - "}, ${data?.address?.city || " - "}`}
                            </div>
                            <div className='mt-[20px] flex gap-[6px] justify-center flex-wrap'>
                                {data?.expertises.map((item)=> <span key={item} className='badge text-accent bg-base-content border-0 line-clamp-1'>{item}</span>)}
                            </div>

                            <div className='mt-[20px] flex gap-[6px] justify-center flex-wrap'>
                                {data?.sectors.map((item)=> <span key={item} className='badge text-accent-focus bg-base-content border-0 line-clamp-1'>{item}</span>)}
                            </div>

                            <div className='text-center mt-[20px] leading-[28px]'>
                                {data?.description || "-- no description --"}
                            </div>

                            <div className='text-center font-bold mt-[20px] text-gray-400'>
                                Achievements
                            </div>

                            <div className='text-center mt-[20px] '>
                                {
                                    data?.achievements.map((item, index) => {
                                        // Split the item by new line characters to get an array of lines
                                        const lines = item.split('\n');

                                        return (
                                            <div className='col-span-2 mb-[20px] leading-[28px]' key={index}>
                                                {
                                                    // Map each line to a span with a <br /> after it (except for the last line)
                                                    lines.map((line, lineIndex) => (
                                                        <React.Fragment key={lineIndex}>
                                                            {line}
                                                            {lineIndex < lines.length - 1 && <br />}
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>

                            <div className='text-center font-bold mt-[20px] text-gray-400'>
                                SDGS
                            </div>

                            <div className='text-center mt-[20px] flex justify-center gap-[10px] flex-wrap'>
                                {
                                    data?.sdgs.map((item)=> (
                                        <img key={item} className='w-[60px]' src={SDG_OPTIONs.find((child)=> child.text == item)?.images}/>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

            }
        </Layout>
    );
};

export default UserProfile;
