import React from 'react';
import { createRouter } from '../config/routes/CreateRouter';
import { routes } from '../config/routes/Routes';
import { RouterProvider } from 'react-router-dom';

// interface NavigatorProps {}

const router = createRouter([...routes]);

export const Navigator = (): JSX.Element => {
    return (
        <RouterProvider router={router.router} />
    );
};

export default Navigator;
