import React from 'react';

import { ASSETS } from 'assets';
import { ModuleProgressDetail } from 'services/module/ModuleTypes';
import Banner from 'components/Banner';

interface TrackProgressProps {
    data: ModuleProgressDetail,
    isCompact?: boolean,
    isLate?: boolean
    compactProgress?: number
}

export const TrackProgress = (props: TrackProgressProps): JSX.Element => {
    const durationInMinutes = props.data.progress?.pagesDurationLeft || 0;
    const durationInHours = Math.ceil(durationInMinutes / 60);

    const compactProgress = props?.compactProgress ? props?.compactProgress : 0;
    const percentage =  props?.isCompact ? compactProgress: props.data.progress.percentage;


    const noLateMessage = ()=> {
        if(percentage === 0){
            return {
                title: "Welcome aboard! 🌟",
                description: "Keep going, and soon you’ll see your progress start to unfold!"
            };
        }

        if(percentage === 100){
            return {
                title: "Congratulations! 🎉",
                description: "You've successfully completed LauncHER!"
            };
        }

        return {
            title: "You are on track! 🎉",
            description: `${percentage}% completed. Keep up the good work!`
        };
    };

    return (
        <div className='TrackProgress max-lg:mx-[25px]'>
            {
                props.isCompact ?
                    <div className='rounded-[30px] bg-[#FC2C51] flex items-center gap-x-[20px] px-[12px] py-[5px]'>
                        <progress className='progress progress-warning grow h-[8px]'
                            value={percentage} max='100'
                            style={{backgroundColor: '#00000033'}}/>
                        <div className='flex-none flex items-center gap-x-[5px] px-[6px] py-[3px] bg-white justify-center rounded-[1000px]'>
                            <img src={ASSETS.dashboard.progressTrack.clockpink} alt='clock'/>
                            <div className='text-center leading-[12px] text-primary text-[12px] font-semibold'>
                                {compactProgress}%
                            </div>
                        </div>
                    </div>
                    :
                    <Banner isLate={props.isLate}>
                        <>
                            <div className='text-[26px] max-lg:text-xl font-semibold text-white leading-[40px] mb-[8px]'>
                                {
                                    props.isLate ?
                                        "The deadline is passed!"
                                        :
                                        noLateMessage().title

                                }
                            </div>
                            <div className='text-[18px] max-lg:text-base font-medium text-white leading-[28px] mb-[5px]'>

                                {
                                    props.isLate ?
                                        "No worry, we give you a few more days, but please finish the current module as soon as you can."
                                        :
                                        noLateMessage().description
                                }
                            </div>

                            <div className='flex items-center gap-x-[10px] relative'>
                                <progress className='progress grow h-[16px] progress-warning'
                                    value={percentage} max='100'
                                    style={{backgroundColor: '#00000033'}}/>
                                <div className='flex-none flex items-center gap-x-[5px] px-[12px] py-[8px] bg-white justify-center rounded-[1000px]'>
                                    <img src={ASSETS.dashboard.progressTrack.clockpink} alt='clock'/>
                                    <div className='text-center leading-[16px] text-primary text-[16px] font-semibold'>
                                        {durationInHours}h Left
                                    </div>
                                </div>
                            </div>
                        </>
                    </Banner>
            }
        </div>
    );
};

export default TrackProgress;
