import { useUploadFile } from 'hooks/file/file';
import {  Trash2 } from 'lucide-react';
import React, { ChangeEvent, useState } from 'react';

interface FileUploadProps {
    id: string;
    onChange?: (value: string) => void;
    currentFile?: string;
    downloadFile?: string;
}

export const FileUpload: React.FC<FileUploadProps> = (props) => {
    // Local state to store the selected file name and hover state
    const [fileName, setFileName] = useState('');
    const [url, setUrl] = useState("");
    const [isHovered, setIsHovered] = useState(false);
    const {onUpload, isLoading} = useUploadFile();

    // Event handler for file selection
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;

        if(!file) return;

        onUpload(file, {
            onSuccess:(res) => {
                setFileName(file.name);
                setUrl(res.url);
                props.onChange && props.onChange(res.url);
            }
        });
    };

    const onDelete = () => {
        props.onChange && props.onChange("");
        setFileName("");
    };

    // Determine the label text based on the state
    const labelText = isHovered ? 'Upload File' : fileName || 'Drag and drop or click to upload';

    return (
        <div className='mt-[15px]'>
            {props?.downloadFile && (
                <div className='my-[20px]'>
                    Download this file for more detail : <a href={props.downloadFile} className='link link-secondary'>{`Question Link`}</a>
                </div>
            )}

            <label
                htmlFor={props.id}
                className='w-full relative flex justify-center items-center border-2 border-dashed border-primary rounded-md cursor-pointer hover:border-solid hover:bg-gray-100 p-6'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                { isLoading ?<span className='loading loading-spinner loading-sm absolute z-50' />: <span className='absolute z-50'>{labelText}</span>}
            </label>

            <input
                id={props.id}
                type='file'
                className='hidden'
                onChange={handleFileChange}
                onClick={(event) => {
                    // This ensures that the input's value is reset
                    event.currentTarget.value = '';
                }}/>

            {
                fileName && <>
                    <div className='mt-[30px] text-[18px] mb-[10px] font-semibold'>Selected File</div>
                    <div className='flex linc cursor-pointer  items-center  py-[0px] px-[8px] rounded-[8px] mb-[40px]' >
                        <a className='underline text-primary line-clamp-1  py-[2px]' href={url}  target='_blank'
                            rel='noreferrer'>
                            {fileName}
                        </a>
                        <div className='ml-[10px]' onClick={onDelete}>
                            <Trash2  color='red' size={16}/>
                        </div>
                    </div>
                </>}
            {
                props?.currentFile ?
                    <div className='mt-[10px]'>
                        Current File : <a href={props.currentFile} target='_blank' className='text-primary'
                            rel='noreferrer'> Uploaded File</a>
                    </div>
                    :
                    <></>
            }
        </div>
    );
};

export default FileUpload;
