import React from 'react';
// import LandingPage from 'containers/LandingPage';
import { type RouteType } from './Type/Router';
import Login from 'containers/Authentication/Login';
import ForgotPassword from 'containers/Authentication/ForgotPassword';
import ResetPassword from 'containers/Authentication/ResetPassword';
import SetPassword from 'containers/Authentication/SetPassword';
import Register from 'containers/Authentication/Register';
import Verify from 'containers/Authentication/Verify';

export const publicRouteNames = {
    // HOME: '/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    SET_PASSWORD: '/set-password',
    GOOGLE_CALLBACK: '/oauth/google/callback',
    REGISTER: '/register'
};

export const publicRoute: RouteType[] = [
    // {
    //     path: publicRouteNames.HOME,
    //     element: <LandingPage />
    // },
    {
        path: publicRouteNames.LOGIN,
        element: <Login />
    },
    {
        path: publicRouteNames.FORGOT_PASSWORD,
        element: <ForgotPassword />
    },
    {
        path: publicRouteNames.RESET_PASSWORD,
        element: <ResetPassword />
    },
    {
        path: publicRouteNames.SET_PASSWORD,
        element: <SetPassword />
    },
    {
        path: publicRouteNames.SIGNUP,
        element: <Register />
    },
    {
        path: publicRouteNames.REGISTER,
        element: <Verify />
    }
];
