import React from 'react';
import { AxiosError } from "axios";
import { ProtectedRouteNames } from "config/routes/ProtectedRoute";
import { publicRouteNames } from "config/routes/PublicRoutes";
import { useLogin, useLoginAsGuest, useLoginSocials } from "hooks/auth/auth";
import { useGetProfileMutation } from "hooks/user/user";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { LoginAccount } from "services/auth/AuthTypes";

import ToastMessage from 'components/ToastMessage/ToastMessage';
import { setAuthToken } from "config/api";
import { useGoogleLogin } from '@react-oauth/google';

export const useLoginAuth = () => {
    const navigate = useNavigate();

    const {onLoginUser, isLoading} = useLogin();
    const {onLoginSocials} = useLoginSocials();
    const {onGetProfileMutation, isLoading: getProfileLoading} = useGetProfileMutation();
    const loginGuest = useLoginAsGuest();

    const navigateToDashboard = (res?:string) => {
        navigate(`${ProtectedRouteNames.DASHBOARD}${res ? `?guest_token=guest_mode`: ''}`, {replace: true});
    };

    const navigateToVerifyScreen = (token:string) => {

        navigate(`${publicRouteNames.REGISTER}?token=${token}`, {replace: true});
    };

    const pageLoading = isLoading || getProfileLoading || loginGuest.isLoading;


    const onSubmit = (data: LoginAccount) => {
        onLoginUser(data, {
            onSuccess:(tokenRes)=> {
                onGetProfileMutation(undefined, {
                    onSuccess: (res)=> {
                        if(res.email_verified_at){
                            toast.info(<ToastMessage title='Hello' message={'Welcome to LauncHER !'} />, {
                                theme: 'colored',
                                autoClose: 1000,
                                hideProgressBar: true,
                                icon: false
                            });
                            setAuthToken(tokenRes.access_token, true);
                            navigateToDashboard();

                            return;
                        }

                        toast.info(<ToastMessage title='Hello' message={'Please validate email'} />, {
                            theme: 'colored',
                            autoClose: 1000,
                            hideProgressBar: true,
                            icon: false
                        });

                        navigateToVerifyScreen(tokenRes.access_token);
                    }
                });
            },
            onError: (err) => {
                const errorAxios = err as AxiosError;

                if(errorAxios.response && errorAxios.response?.status === 401){
                    toast.error(<ToastMessage title='Error' message={'Credentials Invalid'} />, {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                        className: 'text-white',
                        icon: false
                    });
                }
            }
        });
    };

    const requestGoogleLogin = useGoogleLogin({
        onError: () => {
            toast.error(<ToastMessage title='Error' message={'Authentication Failed'} />, {
                theme: 'colored',
                autoClose: 1000,
                hideProgressBar: true,
                className: 'text-white',
                icon: false
            });
        },
        onSuccess: (data) => {
            const access_token = data?.access_token;

            onLoginSocials({
                token:access_token,
                provider: 'google'
            }, {
                onSuccess: () => {
                    toast.info(<ToastMessage title='Hello' message={'Welcome to LauncHER !'} />, {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                        icon: false
                    });
                    navigateToDashboard();
                }
            }
            );


        }
    });

    const handleGuestLogin = () => {
        loginGuest.onLoginGuest(undefined, {
            onSuccess: (res) => navigateToDashboard(res.access_token)

        });
    };

    return {
        navigateToDashboard,
        navigateToVerifyScreen,
        onSubmit,
        pageLoading,
        requestGoogleLogin,
        handleGuestLogin
    };
};
