import { axiosInstance, endpoints } from "config/api";
import { isResponseSuccess } from "services/serviceHelper";
import { ModuleList } from "./ModuleTypes";

export class ModuleServices {
    async getModules(): Promise<ModuleList> {
        const response = await axiosInstance.get(endpoints.program.modules);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

}
