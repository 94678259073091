import { axiosInstance, endpoints } from "config/api";
import {
    CreateAccount,
    CreateAccountResponse,
    ForgotAccount,
    GetGuestAccountResponse,
    LoginAccount,
    LoginAccountSocials,
    RegisterAccountResponse,
    ResetPassAccount
} from "./AuthTypes";
import { MessageOnlyResponse, isResponseSuccess } from "services/serviceHelper";

export class AuthService {

    async loginUser(data: LoginAccount): Promise<CreateAccountResponse> {
        const response = await axiosInstance.post(endpoints.auth.login, data);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

    async loginSocial(data: LoginAccountSocials): Promise<CreateAccountResponse> {
        const response = await axiosInstance.post(endpoints.auth.loginSocial,  data);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

    async registerUser(data: CreateAccount): Promise<RegisterAccountResponse> {
        const response = await axiosInstance.post(endpoints.auth.register, data);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

    async resetPassword(data: ResetPassAccount): Promise<MessageOnlyResponse> {
        const response = await axiosInstance.post(endpoints.auth.reset_password, data);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

    async forgotPassword(data: ForgotAccount): Promise<MessageOnlyResponse> {
        const response = await axiosInstance.post(endpoints.auth.forgot_password, data);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

    async logout(): Promise<unknown> {
        const response = await axiosInstance.post(endpoints.auth.logout);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

    async getGuest(): Promise<GetGuestAccountResponse> {
        const response = await axiosInstance.post(endpoints.auth.guest_token);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

    async resendVerification(): Promise<unknown> {
        const response = await axiosInstance.post(endpoints.auth.resend_verification);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

    async loginAsGuest(): Promise<CreateAccountResponse> {
        const response = await axiosInstance.post(endpoints.auth.guest_token);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }


    async registerFromGuest(data: CreateAccount): Promise<RegisterAccountResponse> {
        const response = await axiosInstance.post(endpoints.auth.guest_register, data);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }
}


