import { ASSETS } from 'assets';
import Button from 'components/Button/Button';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ResetAccountPassword } from 'services/auth/AuthTypes';
import TextInput from 'components/TextInput/TextInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { publicRouteNames } from 'config/routes/PublicRoutes';
import { useResetPassword } from 'hooks/auth/auth';
import ToastMessage from 'components/ToastMessage/ToastMessage';
import { toast } from 'react-toastify';

// Define your validation schema using Yup
const schema = yup.object().shape({
    password: yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must have at least one lowercase char")
        .matches(/[A-Z]/, "Password must have at least one uppercase char")
        .matches(/\W|_/, "Password must have at least one special character"),
    password_confirmation: yup.string()
        .required("Password confirmation is required")
        .oneOf([yup.ref('password')], 'Passwords must match')
});

export const SetPassword = (): JSX.Element => {
    const { control, handleSubmit, formState:{errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const location = useLocation();
    const navigate = useNavigate();
    const {onResetPassword} = useResetPassword();

    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);

        if (!queryParams.has('token') || !queryParams.has('email')) {
            navigate(publicRouteNames.LOGIN);
        }

    }, [location]);

    const onSubmit = (data: ResetAccountPassword) => {
        const queryParams = new URLSearchParams(location.search);

        let email = queryParams.get('email')!;

        email = email.replace(/%40/g, '@').replace(/ /g, '+');

        onResetPassword({
            ...data,
            token: queryParams.get('token')!,  // Use '!' because we know it exists after the above check
            email: email!
        },{
            onSuccess: () =>{
                toast.success(<ToastMessage title='Success' message={'Acount Createad Successfully'} />, {
                    theme: 'colored',
                    autoClose: 1000,
                    hideProgressBar: true,
                    className: 'text-white',
                    icon: false
                });
                navigate(publicRouteNames.LOGIN);
            },
            onError: () => {
                toast.error(<ToastMessage title='Error' message={'Token Not Valid'} />, {
                    theme: 'colored',
                    autoClose: 1000,
                    hideProgressBar: true,
                    className: 'text-white',
                    icon: false
                });

                navigate(publicRouteNames.LOGIN);
            }
        });
    };

    return (
        <div className='flex flex-wrap overflow-y-hidden relative gradient-overlay'>
            <img src={ASSETS.waves.WaveTop1} alt='Wave Top'
                className='absolute top-0  left-0 w-1/2 max-md:w-full max-md:top-[-85px] max-sm:top-[-25px]' />
            <img src={ASSETS.waves.WaveTop2} alt='Wave Top 2'
                className='absolute top-0  left-0 w-1/2 max-md:w-full max-md:top-[-30px] max-sm:top-[0px]' />

            {/* Form Section */}
            <div className='
                transition-transform duration-500 ease-in-out
                w-1/2 max-md:w-full flex items-center justify-center p-4 max-md:h-1/2 scale-125 max-2xl:scale-110 max-xl:scale-100 z-20' >
                <form className='w-full max-w-md px-[0px] max-lg:px-[20px] max-md:mt-[90px] max-md:mb-[40px]' onSubmit={handleSubmit(onSubmit)}>
                    <h1 className='text-[32px] font-semibold mb-[10px] leading-[40px] text-white'>
                        Finalize your account
                    </h1>
                    <div className='mb-[40px] text-white leading-[28px] max-md:mb-[32px]'>
                        Hi!
                        <div className='leading-[16px] max-md:mb-[32px] text-white'>
                            Please enter a password to finalize your account.
                        </div>
                    </div>
                    <div className='mb-[20px] flex flex-col'>
                        <Controller
                            name='password'
                            control={control}
                            defaultValue=''
                            render={({ field }) =>
                                <TextInput {...field}
                                    labelClass='text-white'
                                    error={errors.password?.message}
                                    label='Create Password'
                                    className='shadow appearance-none border rounded w-full py-2 px-3 input'
                                    type='password'
                                    autocomplete='false' placeholder='******************' />}/>
                    </div>
                    <div className='mb-[25px] flex flex-col gap-y-[10px]'>
                        <Controller
                            name='password_confirmation'
                            control={control}
                            defaultValue=''
                            render={({ field }) => <TextInput {...field}
                                className='input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                labelClass='text-white'
                                error={errors.password_confirmation?.message}
                                type='password'
                                label='Confirm Password'
                                autocomplete='false' placeholder='******************' />}/>
                    </div>
                    <div className='flex items-center justify-between'>
                        <Button
                            type='submit'
                            containerClass='w-full'
                            title='Login'/>
                    </div>
                </form>
            </div>

            {/* Image Section */}
            <div className='w-1/2 h-screen z-10
                max-md:w-full max-md:h-1/2
                flex items-center justify-center'>
                <img src={ASSETS.images.LoginCover} alt='Login Visual' className='w-full h-full object-cover' />
            </div>
            <img src={ASSETS.authentication.SuperGraphicsAuth} alt='Auth Super' className='absolute bottom-[0px] left-[0px] w-1/2' />

            <img src={ASSETS.waves.WaveBot1} alt='Wave Bot' className='absolute bottom-0 left-[0px] w-1/2' />
            <img src={ASSETS.waves.WaveBot2} alt='Wave Bot 2' className='absolute bottom-0 left-[0px] w-1/2' />

        </div>
    );
};

export default SetPassword;
