import React from 'react';

interface ProfilePhotoProps {
    photo: string;
    nationality: string;
}

export const ProfilePhoto = (props:ProfilePhotoProps): JSX.Element => {

    return (
    // <div className='relative w-[100px] h-[100px] rounded-full'>
    //     <img src={props.photo} className='w-[100px] h-[100px] rounded-[9999px]'/>
    //     <img src={props.nationality} className='
    //         w-[40px]  aspect-square rounded-full
    //     flex items-center justify-center bg-white absolute right-[-5px] bottom-[-5px]'/>

    // </div>

        <div className='w-[100px] h-[100px] relative rounded-full'>
            <img src={props.photo} className='shadow-md w-[100px] h-[100px]  rounded-full ' alt='Profile' />
            <div className='avatar  absolute top-[70px] right-[0px]'>
                <div className='w-[24px] rounded-full'>
                    <img src={props.nationality}
                        className='w-[24px] h-[24px] rounded-full '/>
                </div>
            </div>
            <div className=' w-[24px] rounded-full  shadow-md' />
        </div>
    );
};

export default ProfilePhoto;
