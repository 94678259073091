import Layout from 'components/Layout/Layout';
import LoadingPlaceholder from 'components/LoadingPlaceholder/LoadingPlaceholder';
import TrackProgress from 'containers/Dashboard/elements/TrackProgress/TrackProgress';
import { useGetLessonDetail, useSubmitLesson } from 'hooks/lesson/lesson';
import { useGetModules, useGetModulesMutation } from 'hooks/module/module';
import React, { useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import Question from './elements/Question/Question';
import Button from 'components/Button/Button';
import { CreateAnswerQuestions } from 'services/lesson/LessonTypes';
import { ProtectedRouteNames } from 'config/routes/ProtectedRoute';
import Lottie from 'react-lottie';
import { REINFORCEMENT_LOTTIE, REINFORCEMENT_TEXT } from 'config/constant';
import { useGetProfile } from 'hooks/user/user';
import Banner from 'components/Banner';
import { publicRouteNames } from 'config/routes/PublicRoutes';
import { clearAllLocalStorage } from 'utils/storage';
import { queryClient } from 'config/api';
import { ASSETS } from 'assets';



export const Assignment = (): React.JSX.Element => {
    const {data, isLoading: moduleLoading} = useGetModules();

    const onGetModules = useGetModulesMutation();
    const {id} = useParams();
    const {onSubmitAnswer, isLoading: submitLoading} = useSubmitLesson();
    const {data: lessonData, isLoading:lessonLoading} = useGetLessonDetail(id ? id : "");
    const navigate = useNavigate();
    const [answers, setAnswers] = useState<Record<number, string>>({});
    const {data:profileData} = useGetProfile();

    const [isShowPositiveFeedback, setIsShowPositiveFeedback] = useState(false);
    const [isLastFeedBack, setIsLastFeedback] = useState(false);
    const [randomIndex, setRandomIndex] = useState<number>(0);
    const [randomIndex2, setRandomIndex2] = useState<number>(0);
    const [loadHtmlContent, setLoadHtmlContent] = useState(false);

    const needsToComment = lessonData?.pageItems ?
        lessonData.pageItems.some((item)=> item.is_reviewable === true && item?.pivot?.is_completed)
        : false;

    const needToAns = lessonData?.pageItems ?
        lessonData.pageItems.some((item)=> item.type !== 'textBlock' && item.pivot?.is_completed)
        : false;

    const progressAllAnswered = needToAns ? lessonData?.pivot.answered_all_at : true;

    const progressAllCommented = profileData?.defaultProgram?.pivot?.type === 'sync' ?
        needsToComment ? lessonData?.pivot?.has_mad_some_comments : true
        : true;

    const needAnswerPage = lessonData?.pageItems?.filter((item)=> item.type != 'textBlock');
    const needAnsPageCompleted = needAnswerPage?.filter((item)=> item.pivot?.is_completed  === true).length ? needAnswerPage?.filter((item)=> item.pivot?.is_completed).length : 0;
    // console.log(lessonData?.pageItems);
    const progressBar = (progressAllAnswered &&  progressAllCommented) ? !needToAns ? lessonData?.pivot?.completed_at ? 100 : 0 : 100 :
        Math.ceil((needAnsPageCompleted / (needAnswerPage?.length ? needAnswerPage?.length : 0) ) * 100) ;


    useEffect(() => {
        // Randomize once on component mount
        const index = Math.floor(Math.random() * 4); // Assuming you have 4 animations and texts
        const index2 = Math.floor(Math.random() * 4); // Assuming you have 4 animations and texts

        setRandomIndex(index);
        setRandomIndex2(index2);
    }, [answers]);

    useEffect(() => {
        setTimeout(()=>{
            setLoadHtmlContent(true);

        },1500);
    }, []);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: REINFORCEMENT_LOTTIE[randomIndex2],
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const lastFeedbackOption = {
        ...defaultOptions,
        animationData: ASSETS.lottie.LottieGrad
    };

    const text  = REINFORCEMENT_TEXT[randomIndex];


    const totalQuestions = lessonData?.pageItems?.filter((item) => item.type != 'textBlock').length ?? 0;

    const isAllAnswered = (): boolean => {
        // Check if the number of answers is equal to the total number of questions

        if (Object.keys(answers).length < totalQuestions) {
            return false;
        }


        // Check if any answer string is empty
        for (const key in answers) {

            if (answers[key] === "" || answers[key] === '[]') {
                return false;
            }
        }

        return true;
    };


    const currentModule = data?.modules?.find((item)=> item.id === lessonData?.module_id);
    const currentModuleIndex = data?.modules?.findIndex((item)=> item.id === lessonData?.module_id);
    const nextModule = currentModuleIndex?.toString() ? data?.modules[currentModuleIndex+1]: null;

    const currentPage = currentModule?.pages.findIndex((item)=> item.id === lessonData?.id) || 0;

    // const hasNextModule = {};

    const canGoNext = currentModule?.pages[currentPage + 1];


    const progressUsers = data?.pivot;

    const isLoading = moduleLoading || lessonLoading || !loadHtmlContent;

    const getStyledHtmlContent = (html?: string): string => {
        if (!html) return "";

        const styledHtml = `
            <div class="space-y-6">
                ${html}
            </div>
        `;

        return styledHtml;
    };

    const LIST_QUESTION = lessonData?.pageItems || [];

    const handleUpdateAnswer = (questionId:number, answer:string) => {
        setAnswers((prevAnswers: Record<number, string>) => ({
            ...prevAnswers,
            [questionId]: answer
        }));
    };

    const handleSubmit = () => {
        const CreateAnswerObj:CreateAnswerQuestions = {answers: [], page_id: id as string};

        for (const [questionId, answer] of Object.entries(answers)) {
            CreateAnswerObj.answers.push({
                page_item_id: parseInt(questionId),
                answer: Array.isArray(answer) ? answer : String(answer)
            });
        }

        onSubmitAnswer( LIST_QUESTION.length === 0 ? {page_id: CreateAnswerObj.page_id}: CreateAnswerObj, {
            onSuccess: ()=> {
                window.scrollTo(0, 0);

                if(nextModule){
                    setIsShowPositiveFeedback(true);
                }


                if(!nextModule){
                    onGetModules.mutate(undefined, {
                        onSuccess: (res) => {
                            setIsShowPositiveFeedback(true);

                            if(res?.pivot?.progress.percentage === 100){
                                setIsLastFeedback(true);
                            }
                        }
                    });
                }
            }
        });
    };

    const handleNextLesson = () => {
        setIsShowPositiveFeedback(false);
        setAnswers({});

        if(canGoNext?.pivot?.is_accessible){
            // check wether this accessible or not
            navigate(ProtectedRouteNames.LESSON(canGoNext.slug));

            return;
        }

        if(nextModule?.pivot?.is_accessible){
            navigate(ProtectedRouteNames.LESSON(nextModule.pages[0].slug));

            return;
        }

        navigate(ProtectedRouteNames.DASHBOARD);

        return;
    };

    const navigateToDashboard = () => {
        setAnswers({});
        setIsShowPositiveFeedback(false);
        navigate(ProtectedRouteNames.DASHBOARD);
    };

    const navigateToRegisterScreen = () => {
        clearAllLocalStorage();
        sessionStorage.clear();
        queryClient.clear();
        navigate(`${publicRouteNames.SIGNUP}`);
    };

    const renderQuestions = () => {
        let questionIndex = 0;

        return LIST_QUESTION.map((item) => {
            const isQuestion = item.type != 'textBlock'; // Add more question types if needed

            if (isQuestion) {
                questionIndex++;

            }

            return (

                <Question key={item.id} index={questionIndex} data={item}
                    onUpdateAnswers={handleUpdateAnswer} />

            );
        });
    };

    if(isShowPositiveFeedback){

        return (
            <Layout>
                <>
                    <div className='flex flex-col items-center'>
                        <Lottie options={isLastFeedBack? lastFeedbackOption: defaultOptions}
                            height={400}
                            width={400}
                            isStopped={false}
                            isPaused={false}/>
                        <div className='mt-[40px]' />
                        <div className='text-[32px] font-semibold'>
                            {isLastFeedBack ? `Congratulations! 🎉 ` :text}
                        </div>
                        {
                            isLastFeedBack && (
                                <div className='text-[32px] font-semibold mt-[26px]'>
                                    {`You've successfully completed LauncHER!`}
                                </div>
                            )
                        }
                        {
                            !isLastFeedBack && (
                                <div className='mt-[30px]'>
                                    <Button
                                        onClick={handleNextLesson}
                                        title='Next Lesson'/>
                                </div>
                            )
                        }
                        <div className='mt-[16px] mb-[100px]'>
                            <Button
                                onClick={navigateToDashboard}
                                title='Back to dashboard' containerClass='btn-link font-normal text-[12px] bg-transparent !text-black'/>
                        </div>
                    </div>
                </>
            </Layout>
        );
    }

    const QuestionNeedComment = LIST_QUESTION.filter((item)=> {
        return item.is_reviewable && item.pivot?.answer && !item?.pivot.is_completed;
    });


    return (
        <Layout>
            <div className='container'>
                {isLoading && <LoadingPlaceholder  containerStyle='h-[800px]'/>}
                <div className={`container mb-[200px] bg-white p-[15px] rounded-[32px] ${isLoading ? 'invisible': ''}`} >
                    <div className='mb-[20px]'>
                        {profileData?.type == 'guest' &&  (
                            <Banner>
                                <>
                                    <div className='text-[26px] max-lg:text-xl font-semibold text-white leading-[40px] mb-[8px]'>
                                            Save your progress! 🎉
                                    </div>

                                    <div className='text-[18px] max-lg:text-base font-medium text-white leading-[28px] mb-[5px]'>
                                            Create an account in 1 minute to save all the progress of your startup
                                    </div>

                                    <div className='flex items-center gap-x-[10px] relative'>
                                        <div
                                            onClick={navigateToRegisterScreen}
                                            className='btn btn-sm text-primary bg-white no-animation hover:bg-white border-0 mt-[10px]'>
                                                Register Now
                                        </div>
                                    </div>
                                </>
                            </Banner>
                        )}
                        {(progressUsers && profileData?.type != 'guest') &&  <TrackProgress data={progressUsers} isCompact={true} compactProgress={progressBar}/>}
                    </div>
                    <h1 className='text-[36px] mb-[32px] font-semibold max-lg:mx-[25px]'>
                        {lessonData?.name}
                    </h1>
                    <div
                        className='max-lg:mx-[25px]'
                        dangerouslySetInnerHTML={{ __html: getStyledHtmlContent(lessonData?.data.content) }}/>

                    <div className='flex flex-col gap-x-[32px]'>

                        {
                            renderQuestions()
                        }
                    </div>
                    {
                        QuestionNeedComment.length > 0 ?

                            <div className='rounded-lg py-4 px-6 mt-10 bg-red-600 text-white shadow-lg flex items-center space-x-3'>
                                <span className='text-white'>To complete the module, please post 3 or more comments for your peers.</span>
                            </div>
                            :
                            <></>
                    }

                    <div className='flex justify-center mt-[32px]'>
                        <Button
                            type='submit'
                            containerClass='btn-sm min-w-[228px] px-[36px] py-[15px] h-[50px] text-[18px]'
                            isLoading={submitLoading}
                            onClick={handleSubmit}
                            disabled={!isAllAnswered()}
                            title={LIST_QUESTION.length > 0 ? !nextModule ? 'Submit': 'Submit and go to next lesson' : !nextModule ? "Submit" :'Continue to next lesson'}/>
                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default Assignment;
