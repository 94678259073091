import Layout from 'components/Layout/Layout';
import React, { useEffect, useState } from 'react';
import TrackProgress from './elements/TrackProgress/TrackProgress';
import ModuleCard from './elements/ModuleCard/ModuleCard';
import { useGetModules } from 'hooks/module/module';
import { ModuleDetail } from 'services/module/ModuleTypes';
import LoadingPlaceholder from 'components/LoadingPlaceholder/LoadingPlaceholder';
import { useGetProfile } from 'hooks/user/user';
import Banner from 'components/Banner';
import { clearAllLocalStorage } from 'utils/storage';
import { publicRouteNames } from 'config/routes/PublicRoutes';
import { useNavigate } from 'react-router-dom';


// interface DashboardProps {}

const renderPage = (item: ModuleDetail, index: number) => {

    return <ModuleCard key={item.id} item={item} index={index}/>;
};

export const Dashboard = (): JSX.Element => {
    const {data, isLoading: getModuleLoading} = useGetModules();
    const {data:profileData, isLoading: getProfileLoading} = useGetProfile();
    const [isLate, setIsLate] = useState(false);

    const navigate = useNavigate();

    const progressUsers = data?.pivot;
    const isGuestMode = profileData?.type === 'guest' ? true : false;

    const isSyncUser = () => {
        if(!profileData?.defaultProgram) return false;

        if(!profileData?.defaultProgram?.pivot) return false;

        if (profileData?.defaultProgram?.pivot?.type !== 'sync') return false;


        return true;
    };

    const navigateToRegisterScreen = () => {
        clearAllLocalStorage();
        sessionStorage.clear();
        navigate(`${publicRouteNames.SIGNUP}`);
    };

    const isLoading = getModuleLoading || getProfileLoading;

    useEffect(() => {
        if (data?.modules && isSyncUser()) {
            const isAnyModuleLate = data.modules.some(module => {
                const periodEnd = module.period.end_at;
                const progress = module.pivot?.progress.percentage || 0;
                const periodEndDate = new Date(periodEnd);
                const now = new Date();

                return now >= periodEndDate && progress < 100;
            });

            setIsLate(isAnyModuleLate);
        }
    }, [data?.modules]);

    return (
        <Layout>
            {
                isLoading ?

                    <LoadingPlaceholder  containerStyle='h-[800px]'/>

                    :

                    <div className='w-[1000px] mb-[200px]'>
                        <div className='mb-[32px]'>

                            {isGuestMode  &&  (
                                <Banner >
                                    <>
                                        <div className='text-[26px] max-lg:text-xl font-semibold text-white leading-[40px] mb-[8px]'>
                                            Save your progress! 🎉
                                        </div>

                                        <div className='text-[18px] max-lg:text-base font-medium text-white leading-[28px] mb-[5px]'>
                                            Create an account in 1 minute to save all the progress of your startup
                                        </div>

                                        <div className='flex items-center gap-x-[10px] relative'>
                                            <div
                                                onClick={navigateToRegisterScreen}
                                                className='btn btn-sm text-primary bg-white no-animation hover:bg-white border-0 mt-[10px]'>
                                                Register Now
                                            </div>
                                        </div>
                                    </>
                                </Banner>
                            )}
                            {(progressUsers && profileData?.type != 'guest') &&  <TrackProgress isLate={isLate} data={progressUsers}/>}
                        </div>


                        <div className='flex flex-col gap-y-[20px]'>
                            {data?.modules.map(renderPage)}
                        </div>
                    </div>

            }
        </Layout>
    );
};

export default Dashboard;
