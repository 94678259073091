import { useState } from "react";

export const useModuleAccordion = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen((prevState)=> !prevState);
    };

    const closeAccordion = () => {
        if(isOpen){
            setIsOpen((prevState)=> !prevState);
        }
    };

    return {
        toggleAccordion,
        closeAccordion,
        isOpen
    };
};
