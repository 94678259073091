import { ASSETS } from 'assets';
import Dropdown from 'components/Dropdown/Dropdown';
import { useGetExpertises, useGetSectors } from 'hooks/resources/resources';
import React, { useState } from 'react';

interface CohortFilterProps {
    onChangeSectors: (sectors: string[]) => void;
    onChangeExpertise: (expertises: string[]) => void;
    onChangeGroups: (isMyGroup: boolean) => void
}

export const CohortFilter = (props:CohortFilterProps): JSX.Element => {
    const [selectedSectors, setSelectedSectors] = useState<string[]>([]);
    const [selectedExpertises, setSelectedExpertises] = useState<string[]>([]);
    const {data:expertiseList} = useGetExpertises();
    const {data:sectorList} = useGetSectors();
    const [filterOptions, setFilterOptions]  = useState({
        my_group: '0',
    });


    const handleSectorChange = (item:string) => {
        const updatedSectors = selectedSectors.includes(item)
            ? selectedSectors.filter(sector => sector !== item)
            : [...selectedSectors, item];

        setSelectedSectors(updatedSectors);
        props.onChangeSectors(updatedSectors);
    };

    const handleExpertiseChange = (item:string) => {
        const updatedExpertises = selectedExpertises.includes(item)
            ? selectedExpertises.filter(expertise => expertise !== item)
            : [...selectedExpertises, item];

        setSelectedExpertises(updatedExpertises);
        props.onChangeExpertise(updatedExpertises);
    };

    const getListOfFilter = () => {
        switch (filterOptions.my_group) {
            case '1':
                return ['All Cohort'];
            case '0':
                return ['My Group'];
            default: // This will cover 'recent' and any other cases
                return [];
        }
    };

    const handleOnChangeFilter = (item:string) => {
        setFilterOptions((prevState)=>{

            switch (item) {
                case 'All Cohort':
                    props.onChangeGroups(false);

                    return {
                        ...prevState,
                        my_group: '0'
                    };
                case 'My Group':
                    props.onChangeGroups(true);

                    return {
                        ...prevState,
                        my_group: '1'
                    };
                default: // This will cover 'recent' and any other cases
                    return {
                        ...prevState
                    };
            }
        });
    };

    return (
        <div className='flex gap-x-[16px]'>

            <Dropdown
                icon={<div
                    className='
                select-none bg-white
                flex gap-x-[16px] text-[15px] items-center px-[20px] shadow py-[8px] rounded-[8px] cursor-pointer relative'>
                    {filterOptions.my_group === '1' ? "My Group" : "All Cohort" }
                    <img src={ASSETS.icon.chevron}/>
                </div>}>
                <li className=''>
                    {
                        getListOfFilter().map((item)=> <a key={item} onClick={()=> handleOnChangeFilter(item)}>{item}</a>)
                    }
                </li>
            </Dropdown>


            <Dropdown
                icon={<div
                    className='
                select-none bg-white
                flex gap-x-[16px] items-center px-[20px] shadow py-[8px] rounded-[8px] cursor-pointer relative'>
                    <div> Sector </div>
                    <img src={ASSETS.icon.chevron}/>
                </div>}>
                <div className='overflow-y-scroll max-h-[200px]'>
                    {
                        sectorList?.map((item) => {
                            return (
                                <li className={`text-[15px]`} key={item}>
                                    <label className='cursor-pointer'>
                                        <input
                                            type='checkbox'
                                            className='checkbox checkbox-primary'
                                            checked={selectedSectors.includes(item)}
                                            onChange={() => handleSectorChange(item)} />
                                        <span className='text-dark'>{item}</span>
                                    </label>
                                </li>
                            );
                        })
                    }
                </div>
            </Dropdown>


            <Dropdown
                icon={<div
                    className='
                select-none bg-white
                flex gap-x-[16px] items-center px-[20px] shadow py-[8px] rounded-[8px] cursor-pointer relative'>
                    <div> Expertise </div>
                    <img src={ASSETS.icon.chevron}/>
                </div>}>
                <div className='overflow-y-scroll max-h-[200px]'>
                    {
                        expertiseList?.map((item) => {
                            return (
                                <li className='text-[15px]' key={item}>
                                    <label className='cursor-pointer'>
                                        <input
                                            type='checkbox'
                                            className='checkbox checkbox-primary'
                                            checked={selectedExpertises.includes(item)}
                                            onChange={() => handleExpertiseChange(item)} />
                                        <span className='text-dark'>{item}</span>
                                    </label>
                                </li>
                            );
                        })
                    }
                </div>
            </Dropdown>
        </div>
    );
};

export default CohortFilter;
