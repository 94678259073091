import { useMutation } from "@tanstack/react-query";
import { FileServices } from "services/file/FileServices";

const FileServiceInstance = new FileServices();

export const useUploadFile = () => {
    const {mutate, isLoading} = useMutation(FileServiceInstance.uploadFile);

    return {onUpload:mutate, isLoading};
};
