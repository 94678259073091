import { axiosInstance, endpoints } from "config/api";
import { axiosInstanceCountry, resourceEndpoints } from "config/apiCountry";
import { isResponseSuccess } from "services/serviceHelper";
import { countryResponse, countryWithStateResponse } from "./resourcesTypes";

export class ResourceServices {
    async getSectorLists(): Promise<string[]> {
        const response = await axiosInstance.get(endpoints.resources.sectors);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

    async getExpertisesLists(): Promise<string[]> {
        const response = await axiosInstance.get(endpoints.resources.expertises);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

    async getSdgsLists(): Promise<string[]> {
        const response = await axiosInstance.get(endpoints.resources.sdgs);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

    async getCountryList(): Promise<countryResponse>{
        const response = await axiosInstanceCountry.get(resourceEndpoints.getCountry);

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }

    async getStateByCountry(country:string): Promise<countryWithStateResponse>{
        const response = await axiosInstanceCountry.post(resourceEndpoints.getStateByCountry, {country: country});

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }
}
