import { ASSETS } from 'assets';
import Button from 'components/Button/Button';
import ToastMessage from 'components/ToastMessage/ToastMessage';
import { setAuthToken } from 'config/api';
import { publicRouteNames } from 'config/routes/PublicRoutes';
import { useResendVerification } from 'hooks/auth/auth';
import { useGetProfile } from 'hooks/user/user';
import React, { useEffect, useState }  from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


export const Verify = (): JSX.Element => {
    const {data, refetch}= useGetProfile();
    const location = useLocation();
    const navigate = useNavigate();
    const {onResendVerificationEmail, isLoading} = useResendVerification();

    const [isVerified, setIsVerified] = useState(false);

    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        const verificationStatus = queryParams.get('verification-email-status');

        if(token){
            setAuthToken(token, false);
            refetch();
        }

        if(verificationStatus === 'verified'){
            setIsVerified(true);
        }

    },[]);

    const handleAction = () => {
        if(isVerified){
            navigateToLoginScreen();

            return;
        }

        onResendVerificationEmail(undefined, {
            onSuccess: () => {
                toast.info(<ToastMessage title='Hello' message={'Verification Email Sent !'} />, {
                    theme: 'colored',
                    autoClose: 1000,
                    hideProgressBar: true,
                    icon: false
                });
            }
        });

    };

    const navigateToLoginScreen = () => {

        navigate(`${publicRouteNames.LOGIN}`, {replace: true});
    };

    return (
        <div className='flex flex-wrap overflow-y-hidden relative gradient-overlay'>
            <img src={ASSETS.waves.WaveTop1} alt='Wave Top'
                className='absolute top-0  left-0 w-1/2 max-md:w-full max-md:top-[-85px] max-sm:top-[-25px]' />
            <img src={ASSETS.waves.WaveTop2} alt='Wave Top 2'
                className='absolute top-0  left-0 w-1/2 max-md:w-full max-md:top-[-30px] max-sm:top-[0px]' />

            {/* Form Section */}
            <div className='
                transition-transform duration-500 ease-in-out
                w-1/2 max-md:w-full flex items-center justify-center p-4 max-md:h-1/2 scale-125 max-2xl:scale-110 max-xl:scale-100 max-sm:scale-75 z-20' >
                <div className='relative bg-white  w-full max-w-md  max-md:mb-[40px] rounded-md'>
                    {/* <div className='bg-[#FC2C52] absolute w-full h-[80px] top-[0px] bg-opacity-60 rounded-b-[60px]' /> */}
                    <div className='flex justify-center z-10'>
                        <img src={ASSETS.icon.Email} width={120}/>
                    </div>
                    {
                        isVerified ?
                            <h1 className='text-[20px] text-center font-semibold mb-[30px] leading-[40px] text-primary'>
                                Email is verified
                            </h1>

                            :

                            <h1 className='text-[20px] text-center font-semibold mb-[30px] leading-[40px] text-primary'>
                                Verify your email address
                            </h1>
                    }

                    {
                        isVerified ?
                            <>
                                <div className='mb-[40px] text-white leading-[28px] px-[30px]'>
                                    <div className='text-base-200 leading-[18px] text-[14px] text-center'>
                                        {`You have successfully verified your account`}
                                    </div>
                                </div>
                            </>
                            :

                            <>
                                <div className='mb-[40px] text-white leading-[28px] px-[30px] flex flex-col justify-center items-center '>

                                    <div className='text-base-200 leading-[18px] text-[14px] text-center max-w-[370px]'>
                                        {`We have sent a verification link to ${data?.email}.`}
                                    </div>
                                    <p className='text-base-200 leading-[18px] text-[14px] mt-[20px] text-center max-w-[370px]'>
                                        {`Click on the link to complete your verification process.
                                            You might need to check your spam folder. `}
                                    </p>
                                </div>
                            </>


                    }



                    <div className='flex items-center justify-center px-[30px]
                    pb-[30px]'>
                        <Button
                            onClick={handleAction}
                            isLoading={isLoading}
                            type='submit'
                            containerClass=''
                            title={isVerified ? 'Go to login page' : 'Resend verification email'}/>
                    </div>
                </div>
            </div>

            {/* Image Section */}
            <div className='w-1/2 h-screen z-10
                max-md:w-full max-md:h-1/2
                flex items-center justify-center'>
                <img src={ASSETS.images.LoginCover} alt='Login Visual' className='w-full h-full object-cover' />
            </div>

            <img src={ASSETS.authentication.SuperGraphicsAuth} alt='Auth Super' className='absolute bottom-[0px] left-[0px] w-1/2' />

            <img src={ASSETS.waves.WaveBot1} alt='Wave Bot' className='absolute bottom-0 left-[0px] w-1/2' />
            <img src={ASSETS.waves.WaveBot2} alt='Wave Bot 2' className='absolute bottom-0 left-[0px] w-1/2' />

        </div>
    );
};

export default Verify;
