import Layout from 'components/Layout/Layout';
import LoadingPlaceholder from 'components/LoadingPlaceholder/LoadingPlaceholder';
import { SDG_OPTIONs } from 'config/constant';
import { showToast } from 'helper/toastHelper';
import { useUploadFile } from 'hooks/file/file';
import { useGetExpertises, useGetSectors } from 'hooks/resources/resources';
import { useGetProfile, useUpdateProfile } from 'hooks/user/user';
import React, { ChangeEvent, useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { ProtectedRouteNames } from 'config/routes/ProtectedRoute';
// import SettingsIcon from '@mui/icons-material/Settings';

export const Profile = (): JSX.Element => {
    const {data,isLoading: isGetProfileLoading} = useGetProfile();
    const {isLoading: isGetExpertiseLoading} = useGetExpertises();
    const {isLoading: isGetSectorsLoading} = useGetSectors();
    const {onUpload, isLoading: loadingUploadFIle} = useUploadFile();
    const {onUpdate, isLoading: loadingUpdateProfile} = useUpdateProfile();


    const updateProfileLoading = loadingUploadFIle || loadingUpdateProfile;
    const isLoading = isGetProfileLoading || isGetExpertiseLoading || isGetSectorsLoading;

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (file && file.type.match('image.*')) {
            onUpload(file, {
                onSuccess:(res) => {
                    onUpdate( {avatar:res.url },{

                    });
                    showToast("success", "Profile Photo Updated");
                }
            });


        }
    };

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Layout>
            {
                isLoading ?
                    <LoadingPlaceholder  containerStyle='h-[60vh]'/>
                    :
                    <div className='container mb-[200px]'>
                        <input
                            type='file'
                            accept='image/*'
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                            ref={fileInputRef}/>
                        <div className='card bg-white py-[40px] px-[80px] max-sm:px-[20px] mx-[20px]'>
                            <div className='flex items-center justify-between mb-[50px]'>
                                <h1 className=' text-[30px]'>My Profile</h1>
                                <Link to={ProtectedRouteNames.PROFILE_SETTINGS} className='cursor-pointer'>
                                    <EditIcon className='text-primary w-[30px]' fontSize='large'/>
                                </Link>
                            </div>
                            <div className='flex items-center justify-center'>
                                <div className=' relative group  cursor-pointer w-[192px] hover:opacity-100'>
                                    <div className='avatar w-[192px] rounded-full hover:opacity-100 bg-violet-200'>
                                        <div className='w-[192px] overflow-hidden relative'>
                                            <img src={data?.avatar || 'https://placehold.co/96x96'} className='shadow-md rounded-full ' alt='Profile' />
                                            <div className='avatar  absolute top-[140px] right-[-0px]'>
                                                <div className='w-[48px] rounded-full'>
                                                    <img src={data?.address.flag}
                                                        className='w-[48px] h-[48px] rounded-full '/>
                                                </div>
                                            </div>
                                            <div className=' w-[192px] rounded-full  shadow-md' />
                                        </div>

                                    </div>
                                    {
                                        updateProfileLoading ?
                                            <div
                                                className='w-[192px] h-[192px] absolute rounded-[9999px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-100 group-hover:opacity-100 transition-opacity duration-300'>
                                                <span className='loading loading-spinner text-secondary' />
                                            </div>
                                            :
                                            <div
                                                onClick={triggerFileInput}
                                                className='w-[192px] h-[192px] absolute rounded-[9999px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                                                <span className='text-white'>
                                                Change
                                                </span>
                                            </div>
                                    }

                                </div>
                            </div>
                            <div className='text-[16px] font-semibold text-center mt-[16px]'>
                                {data?.name}
                            </div>
                            <div className='text-[14px] font-normal text-center mt-[8px]'>
                                {data?.title || "-- no title --"}
                            </div>
                            <div className='text-[14px] font-normal text-center mt-[4px]'>
                                {`${data?.address?.country || " - "}, ${data?.address?.city || " - "}`}
                            </div>
                            <div className='mt-[20px] flex gap-[6px] justify-center flex-wrap'>
                                {data?.expertises.map((item)=> <span key={item} className='badge text-accent bg-base-content border-0 line-clamp-1'>{item}</span>)}
                            </div>

                            <div className='mt-[20px] flex gap-[6px] justify-center flex-wrap'>
                                {data?.sectors.map((item)=> <span key={item} className='badge text-accent-focus bg-base-content border-0 line-clamp-1'>{item}</span>)}
                            </div>

                            <div className='text-center mt-[20px] leading-[28px]'>
                                {data?.description || "-- no description --"}
                            </div>

                            <div className='text-center font-bold mt-[20px] text-gray-400'>
                                Achievements
                            </div>

                            <div className='text-center mt-[20px] '>
                                {
                                    data?.achievements.map((item, index) => {
                                        // Split the item by new line characters to get an array of lines
                                        const lines = item.split('\n');

                                        return (
                                            <div className='col-span-2 mb-[20px]' key={index}>
                                                {
                                                    // Map each line to a span with a <br /> after it (except for the last line)
                                                    lines.map((line, lineIndex) => (
                                                        <React.Fragment key={lineIndex}>
                                                            <div className='leading-[28px]'>
                                                                {line}
                                                            </div>
                                                            {lineIndex < lines.length - 1 && <br />}
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>

                            <div className='text-center font-bold mt-[20px] text-gray-400'>
                                SDGS
                            </div>

                            <div className='text-center mt-[20px] flex justify-center gap-[10px] flex-wrap'>
                                {
                                    data?.sdgs.map((item)=> (
                                        <img key={item} className='w-[60px]' src={SDG_OPTIONs.find((child)=> child.text == item)?.images}/>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

            }
        </Layout>
    );
};

export default Profile;
