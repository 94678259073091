import { ToastOptions, TypeOptions, toast } from "react-toastify";

export const TOAST_WARNING = toast.TYPE.WARNING;
export const TOAST_ERROR = toast.TYPE.ERROR;
export const TOAST_SUCCESS = toast.TYPE.SUCCESS;

export const showToast = (type: TypeOptions, message:string, autoClose = 2000) => {
    const options:ToastOptions = {
        autoClose,
        theme: 'light',
        icon: false,
    };

    switch (type) {
        case TOAST_WARNING:
            toast.warning(message, options);
            break;
        case TOAST_ERROR:
            toast.error(message, options);
            break;
        case TOAST_SUCCESS:
            toast.info(message, options);
            break;
        default:
            break;
    }
};
