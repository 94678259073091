import { ASSETS } from 'assets';
import Button from 'components/Button/Button';
import { showToast } from 'helper/toastHelper';
import { useGetComment, useSubmitComment } from 'hooks/lesson/lesson';
import { useGetProfile } from 'hooks/user/user';
import React, { useEffect, useRef, useState } from 'react';
import { ReviewData } from 'services/lesson/ReviewTypes';
import CommentCard from './CommentCard';
import { MAX_TEXT_LENGTH, REACTION_TYPE, REACTION_TYPE_RECORD, REACTION_TYPE_TEXT } from 'config/constant';
import Reaction from '../../Reaction/Reaction';
import { useNavigate, useParams } from 'react-router-dom';
import { ProtectedRouteNames } from 'config/routes/ProtectedRoute';
import { endpoints, queryClient } from 'config/api';
import Lottie from 'react-lottie';
import { LessonType } from 'services/lesson/LessonTypes';

interface ReviewCardProps {
    data: ReviewData
    handlePeerReviews: () => void
    user_page_item_id?: string
    isMultipleChoice?: boolean
    optionsForMultiple?:string[]
    type?: LessonType
    submitPeerReview?: (ans:string, onSubmited: () =>void) => void
}


export const ReviewCard = (props: ReviewCardProps): React.JSX.Element => {
    const [isCommentClicked, setIsCommentClicked] = useState(false);
    const [isContentEmpty, setIsContentEmpty] = useState(true);
    // TODO CHANGE THIS TO TEXT AREA INSTEAD OF USING EDITABLE DIV
    const [contentLength, setContentLength] = useState(0);
    const {data} = useGetProfile();
    const {id} = useParams();
    const {onSubmitComment, isLoading} = useSubmitComment();
    const {data:commentsData, hasNextPage, isFetchingNextPage, isLoading:getCommentLoading, refetch, fetchNextPage} = useGetComment({
        page:1,
        page_item_id: props.data.page_item_id.toString(),
        user_page_item_id: props.data.id.toString(),
        start_fetch: isCommentClicked
    });
    const [isCommentBoxOpen, setIsCommentBoxOpen] = useState(false);


    const commentFlatData = commentsData?.pages?.flatMap(page => page?.data) ?? [];
    const isLoadingComment = isFetchingNextPage || getCommentLoading;

    const contentEditableRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();

    const handleInput = () => {
        if (!contentEditableRef.current) return;
        let text = contentEditableRef.current.innerText.trim();

        if (text.length > MAX_TEXT_LENGTH) {
            contentEditableRef.current.innerText = text.substring(0, MAX_TEXT_LENGTH);
            text = contentEditableRef.current.innerText;
        }

        // Update content length and empty state
        setContentLength(text.length);
        setIsContentEmpty(text === '');
    };

    useEffect(() => {
        // Initial check to see if the div is empty when the component mounts
        handleInput();
    }, []);

    const navigateToProfile = (id:string) => {

        navigate(ProtectedRouteNames.PROFILE_PEOPLE(id), {replace: true});
    };

    // const myComment = props.data.comments.data.find(item=> item.user.email === data?.email);
    const isMyReview = props.data.user_id === data?.id;

    const handleOpenComment = () => {
        if(props.data.id != 0){
            setIsCommentClicked(prevState => {
                if(prevState){
                    setIsCommentBoxOpen(false);
                }

                return !prevState;
            });
        }
    };

    const handleOpenCommentBox = () => {
        if(props.data.id != 0){
            setIsCommentBoxOpen(prevState => !prevState);
        }
    };

    const handleSubmitCommnet = () => {
        if(! contentEditableRef.current) return;
        onSubmitComment(
            {user_page_item_id: props.data.id.toString(), content: contentEditableRef.current.innerText},
            {
                onSuccess: ()=> {
                    if(!contentEditableRef.current) return;
                    showToast("success", "Comment Submitted");
                    props.handlePeerReviews();
                    contentEditableRef.current.innerText = "";
                    refetch();
                    queryClient.invalidateQueries({queryKey: [endpoints.assignment.lessons, id]});
                }
            }
        );
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // This should stop the Enter key from creating a new line
            handleSubmitCommnet(); // And this should submit the comment
        }
    };

    const getLatestReaction = ():string[] => {
        const dataReaction = props.data.reactions.data || [];

        if(dataReaction.length === 0 ){
            return [];
        }

        const reaction = dataReaction.slice(0,3);

        return reaction.map((item)=> item.type);
    };

    const getLatestReactionUser = (): string => {
        const dataReaction = props.data.reactions.data || [];

        if(dataReaction.length === 0 ){
            return "";
        }

        const reaction = dataReaction[0];

        if(dataReaction.length > 1 ){
            return `${reaction.user?.name}, and ${dataReaction.length - 1} others`;
        }

        return `${reaction.user?.name}`;
    };

    const latestReactions = getLatestReaction();

    const isImageUrl = (url:string) => {
        return /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|webp))$/i.test(url);
    };

    const DisplayAnswer = (answer?: string) => {
        const MultipleAnswer: string[] = [];

        let parsedAnswer = answer;

        // Check and parse if answer is an array-like string
        if (answer && typeof answer === 'string' && answer.startsWith('[') && answer.endsWith(']')) {
            try {
                parsedAnswer = JSON.parse(answer);
            } catch (e) {
                console.error('Error parsing answer string as JSON', e);
            }
        }

        if (props?.isMultipleChoice) {

            if (parsedAnswer !== undefined && parsedAnswer !== null) {

                if (Array.isArray(parsedAnswer)) {
                // Handle the case where parsedAnswer is an array
                    props?.optionsForMultiple?.forEach((element, idx) => {
                        if (parsedAnswer && parsedAnswer.includes(idx.toString())) {
                            MultipleAnswer.push(element);
                        }
                    });
                } else {
                    // Handle the case where parsedAnswer is not an array
                    props?.optionsForMultiple?.forEach((element, idx) => {
                        if ((parsedAnswer || parsedAnswer?.toString() === '0') && parsedAnswer.toString() === idx.toString()) {
                            MultipleAnswer.push(element);
                        }
                    });
                }
            }
        }

        return (
            <div className='mb-[15px] text-[16px] font-normal leading-[24px] break-words'>
                {(answer || answer?.toString() === '0') ? (
                    isImageUrl(answer) ? (
                        <img src={answer} alt='Answer' className='max-h-[200px]'/>
                    ) : (
                        props?.isMultipleChoice ?
                            MultipleAnswer.map((e, i) => {
                                return <div key={i} className='break-words'>{e}</div>;
                            })
                            : <div dangerouslySetInnerHTML={{ __html: answer }} className='break-words' />
                    )
                ) : (
                    '-- No Answer --'
                )}
            </div>
        );
    };


    return (
        <div
            className='flex gap-x-[12px] gap-y-[16px] mt-[16px] border-[1px] p-[20px] rounded-[12px]
                max-sm:border-none max-sm:p-0 max-sm:border-t-[1px]
            '>
            <div className='max-sm:hidden'>
                <img
                    onClick={()=> navigateToProfile(props.data.user_id.toString())}
                    className='w-[40px] h-[40px] rounded-full cursor-pointer'
                    src={props.data.user.avatar || 'https://placehold.co/600x600/F20D69/F20D69'}/>
            </div>
            <div className='flex flex-col w-full'>
                <div className='flex items-center gap-x-[5px] max-sm:mb-[10px] cursor-pointer'>
                    <img
                        onClick={()=> navigateToProfile(props.data.user_id.toString())}
                        className='w-[40px] h-[40px] rounded-full sm:hidden'  src={props.data.user.avatar || 'https://placehold.co/600x600/F20D69/F20D69'}/>
                    <div className='flex items-center justify-between w-full'>
                        <div
                            onClick={()=> navigateToProfile(props.data.user_id.toString())}
                            className='mb-[1px] text-[14px] leading-[20px] font-medium pt-[7px] cursor-pointer'>
                            {`${props.data.user?.name}  ${isMyReview ? "(Me)" : ''}`}
                        </div>
                    </div>
                </div>
                {DisplayAnswer(props.data?.answer)}
                <div className='text-[12px] font-normal leading-[16px] text-base-300 sm:hidden mb-[8px]'>{formatDate(props.data.updated_at)}</div>
                <div className='flex items-center max-sm:mb-[5px]'>
                    <div className='flex flex-row max-md:flex-col max-md:items-start gap-y-[12px] flex-grow gap-x-[8px] items-center'>
                        <div className='flex gap-x-[8px]'>
                            {latestReactions.length > 0 ?
                                <div className='flex  items-center text-[12px] font-normal leading-[16px] text-base-300 bg-base-content rounded-full py-[3px] px-[6px]'>
                                    {
                                        latestReactions.map((item, index) =>
                                            <Lottie
                                                options={{
                                                    loop: false,
                                                    autoplay: false,
                                                    animationData: REACTION_TYPE_RECORD[item as keyof typeof REACTION_TYPE_RECORD],
                                                    rendererSettings: {
                                                        preserveAspectRatio: 'xMidYMid slice'
                                                    }

                                                }}
                                                width={18}
                                                height={18}
                                                isPaused={true}
                                                isStopped={true}
                                                key={index}/>
                                        )

                                    }

                                    {getLatestReactionUser()}

                                </div>
                                :
                                <></>
                            }

                            <div className='  bg-base-content rounded-full py-[3px] px-[6px] h-[25px] cursor-pointer' onClick={handleOpenComment} >
                                <div className='text-[12px] font-normal leading-[18px] text-base-300 flex gap-x-[4px]'>
                                    <img src={ASSETS.icon.CommentGr}/>
                                    {props.data.comments?.meta?.total} Comments
                                </div>
                            </div>

                        </div>
                        <div className='flex gap-x-[8px]'>
                            <Button
                                onClick={()=> {
                                    if(!isCommentClicked){
                                        handleOpenComment();
                                    }

                                    handleOpenCommentBox();
                                }}
                                leftIcon={<img src={ASSETS.icon.COMMENT}/>}
                                isNotPrimary={true}
                                containerClass='btn-xs btn-outline btn-primary '
                                title='Comment'/>
                            <div className='relative group z-50'>
                                <div className='flex gap-x-[20px] absolute left-0 bottom-[-43px]
                                border-0 border-black p-[10px] rounded-lg border-solid bg-white
                                invisible group-hover:visible transition-all drop-shadow-2xl	'>
                                    {/* Reaction components */}
                                    <Reaction
                                        handlePeerReview={props.handlePeerReviews}
                                        name={REACTION_TYPE_TEXT.LIKE}
                                        icon={REACTION_TYPE.LIKE} tooltip='Like'
                                        id={props.data.id} />
                                    <Reaction
                                        handlePeerReview={props.handlePeerReviews}
                                        name={REACTION_TYPE_TEXT.LOVE} icon={REACTION_TYPE.LOVE} tooltip='Love'
                                        id={props.data.id} />
                                    <Reaction
                                        handlePeerReview={props.handlePeerReviews}
                                        name={REACTION_TYPE_TEXT.INSIGHTFUL} icon={REACTION_TYPE.INSIGHTFUL} tooltip='Insightful'
                                        id={props.data.id} />
                                    <Reaction
                                        handlePeerReview={props.handlePeerReviews}
                                        name={REACTION_TYPE_TEXT.SHOCKED} icon={REACTION_TYPE.SHOCKED} tooltip='Shocked'
                                        id={props.data.id} />
                                </div>
                                <Button
                                    leftIcon={<img src={ASSETS.icon.LikeIcon}/>}
                                    containerClass={`btn-xs btn-outline btn-primary ${isMyReview ? 'hidden' : ''}`}
                                    title='Like'/>
                            </div>

                        </div>
                    </div>

                    <div className='text-[12px] font-normal leading-[16px] text-base-300 max-sm:hidden'>{formatDate(props.data.updated_at)}</div>
                </div>
                {
                    isCommentClicked && (
                        <>
                            { isCommentBoxOpen && <div className={`mt-[8px] mb-[4px]  flex items-center gap-x-[10px] relative ${isMyReview ? 'hidden'  : ''}`}>
                                <img
                                    className='w-[40px] h-[40px] rounded-full'
                                    src={data?.avatar || 'https://placehold.co/600x600/F20D69/F20D69'}/>
                                {isContentEmpty && (
                                    <div
                                        className='absolute text-gray-500 bottom-[13px] left-[70px]'
                                        style={{ pointerEvents: 'none', userSelect: 'none' }}>
                                            Enter your text here...
                                    </div>
                                )}

                                <div
                                    ref={contentEditableRef}
                                    className={`outline-[#b2b2b2] border-[1px] relative grow py-[8px] px-[16px] rounded-[12px] leading-[24px] text-[14px] ${isContentEmpty ? 'placeholder-style' : ''}`}
                                    contentEditable={true}
                                    onInput={handleInput} />

                                <div
                                    className='absolute text-gray-500 bottom-[-20px] right-2 pointer-events-none select-none text-[12px]'>
                                    {`${contentLength ? contentLength  : '0'}/1500`}
                                </div>
                                {
                                    (
                                        <div className='absolute right-[8px]'
                                            onClick={handleSubmitCommnet}
                                            onKeyDown={handleKeyDown}>
                                            <Button
                                                title=''
                                                isLoading={isLoading}
                                                leftIcon={isLoading ? <></>:<img src={ASSETS.icon.SEND_PINK} className='cursor-pointer'/>}
                                                containerClass='btn-ghost'/>

                                        </div>
                                    )
                                }



                            </div>
                            }
                            <div className='flex flex-col gap-y-[20px] mt-[15px]'>
                                {commentFlatData.map((item)=>
                                    <CommentCard key={item.id}
                                        id={item.id}
                                        deleted_at={item?.deleted_at}
                                        is_edited={item.created_at !== item.updated_at}
                                        email={item.user.email}
                                        handlePeerReviews={refetch}
                                        avatar={item.user?.avatar}
                                        name={item.user?.name}
                                        reactions={item.reactions.data}
                                        updated_at={item.updated_at}
                                        user_id={item.user.id}
                                        has_next_page={hasNextPage}
                                        is_fetching_next_page={isFetchingNextPage}
                                        fetch_next_page={fetchNextPage}
                                        comment={item.content}/>)
                                }

                                {
                                    hasNextPage && !isLoadingComment && (
                                        <div className='mt-[20px] text-primary cursor-pointer hover:opacity-[0.6]' onClick={()=> fetchNextPage()}>
                                            Load More Comment
                                        </div>
                                    )
                                }
                                {
                                    isLoadingComment &&
                                    <div className='flex justify-center'>
                                        <span className='loading loading-spinner text-primary loading-sm mt-[40px]' />
                                    </div>
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );

    function formatDate(dateStr: string): string {
        // Parse the date string
        const date = new Date(dateStr);

        // Array of day names
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        // Array of month names
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Get the day name
        const dayName = days[date.getUTCDay()];

        // Get the day of the month
        const day = date.getUTCDate().toString().padStart(2, '0');

        // Get the month name
        const month = months[date.getUTCMonth()];

        // Get the year
        const year = date.getUTCFullYear();

        // Get hours and minutes
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');

        // Format the date string
        return `${dayName} ${day} ${month} ${year} • ${hours}:${minutes}`;
    }
};

export default ReviewCard;
