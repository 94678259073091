
import React from 'react';
import Navbar from './elements/Navbar/Navbar';
import Footer from './elements/Footer/Footer';



interface LayoutProps {
    children: JSX.Element
}

export const Layout = (props:LayoutProps): JSX.Element => {
    return (
        <div className='flex flex-col overflow-x-hidden h-screen '>
            <Navbar/>

            <div className='flex  flex-1 justify-center '>
                {props.children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
