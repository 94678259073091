import React from 'react';
import styles from './Footer.module.scss';
import { ASSETS } from 'assets';
// import { ASSETS } from 'assets';

export const Footer = (): JSX.Element => {
    return (

        <footer className={`footer text-neutral-content ${styles.footerBackground} `} >
            <div className=' max-lg:scale-90' >
                <div className='flex flex-col items-center w-screen mt-[0px] max-sm:mt-[0px]'>
                    <div
                        className='flex max-w-[940px] w-full pt-[72px] items-center
                        max-md:flex-col max-md:items-start max-md:w-screen max-md:px-[25px] max-md:py-[25px] gap-y-[25px]'>
                        <div className='grow'>
                            <img src={ASSETS.logo.withTextWhite} alt='logo' className='w-[192px] opacity-1 sm:opacity-0'/>
                        </div>
                        {/* <div className='text-white pr-[40px] font-medium cursor-pointer'>Terms of use</div> */}
                        <a className='text-white pr-[40px] font-medium cursor-pointer' href='https://launcherimpact.org/rules-of-participation/'
                            target='_blank' rel='noreferrer'>Rules of Participation</a>
                        {/* <div className='text-white font-medium cursor-pointer'>Privacy Policy</div> */}
                    </div>
                    <div className='

                            max-w-[940px] flex flex-col text-center mt-[30px]
                            max-md:text-left max-md:mx-[25px]
                            '>
                        <div className='border-b-[1px] pb-[15px]'>
                            <div className='text-white font-normal text-[14px] leading-[22px]'>
                                LauncHER is an online training course and knowledge platform dedicated to empowering women leaders of early-stage, social
                            </div>
                            <div className='text-white font-normal text-[14px] leading-[22px]'>
                                impact-oriented, tech-enabled startups.
                            </div>
                        </div>

                        <div />
                        <div className='text-white pt-[15px] text-[12px] leading-[22px]'>
                            © LauncHER 2024. All rights reserved.
                        </div>
                        <div className='text-white text-[12px] pt-[10px] max-sm:pt-0 leading-[22px]'>
                            LauncHER has been developed by Creatella Impact and Erasmus Centre for Entrepreneurship
                        </div>

                        <div className='h-[40px]' />

                    </div>
                </div>
            </div>

            {/* </div> */}

            {/* <img src={ASSETS.layout.SuperGraphicsFooter}
                className='absolute left-0 bottom-0 max-lg:opacity-0'/> */}

            {/* <div className='opacity-1 sm:opacity-0'>
                <img src={ASSETS.layout.OrnamentsRight}
                    className='absolute right-0 top-[80px] ' />

            </div> */}

        </footer>
    );
};

export default Footer;
