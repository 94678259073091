import React from 'react';
import {
    type RouterProviderProps,
    createBrowserRouter,
    Navigate,
} from 'react-router-dom';
import type { RouteType } from './Type/Router';
import RouteGuard from './RouteGuard';
import { publicRouteNames } from './PublicRoutes';
// import ErrorPage from 'global/components/404';

const fallbackRoute = {
    path: '*',
    element: <Navigate to={publicRouteNames.LOGIN}/>
};

export const createRouter = (routes: RouteType[]): RouterProviderProps => {
    const browserRoutes = routes.map(({ path, element, isProtected }) => ({
        path,
        element: <RouteGuard isProtected={isProtected || false}>{element}</RouteGuard>,
    }));

    // browserRoutes.push(...GlobalRoutes);
    browserRoutes.push(fallbackRoute);

    return {
        router: createBrowserRouter(browserRoutes),
    };
};
