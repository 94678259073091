import Layout from 'components/Layout/Layout';
import React, { useState } from 'react';
// import CohortFilter from './elements/CohortFilter/CohortFilter';
import CohortCard from './elements/CohortCard/CohortCard';
import { useGetDefaultCohort } from 'hooks/cohort/cohort';
import CohortFilter from './elements/CohortFilter/CohortFilter';
import LoadingPlaceholder from 'components/LoadingPlaceholder/LoadingPlaceholder';
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import Button from 'components/Button/Button';
// import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';

let sectorTimeout:NodeJS.Timeout | undefined = undefined;
let filterTimeout:NodeJS.Timeout | undefined = undefined;

export const Cohort = (): JSX.Element => {
    const [filterSectorList, setFilterSectorList] = useState<string[]>([]);
    const [filterExpertiseList, setFilterExpertiseList] = useState<string[]>([]);
    const [isMyGroup, setIsMyGroup] = useState(false);

    const {data, isLoading, dataFlat, hasNextPage, fetchNextPage , isFetchingNextPage} = useGetDefaultCohort({expertises: filterExpertiseList, sectors: filterSectorList, isMyGroup:isMyGroup});

    const LIST_OF_COHORTS = dataFlat;

    const handleOnChangeSector = (sectors: string[])=>{
        clearTimeout(sectorTimeout);
        sectorTimeout = setTimeout(()=>{
            setFilterSectorList(sectors);
        }, 1000);
    };

    const handleOnChangeExpertise = (expertise: string[])=>{
        clearTimeout(filterTimeout);
        filterTimeout = setTimeout(()=> {
            setFilterExpertiseList(expertise);

        },1000);
    };

    const handleOnChangeGroup = (isMygroup: boolean) => {
        setIsMyGroup(isMygroup);
    };



    return (
        <Layout>

            <div className='container mb-[100px]'>
                <div className='font-semibold text-[40px] mx-[20px] mb-[30px]'>
                    {data?.pages && data?.pages[0].name}
                </div>
                <CohortFilter
                    onChangeGroups={handleOnChangeGroup}
                    onChangeExpertise={handleOnChangeExpertise}
                    onChangeSectors={handleOnChangeSector}/>
                {/* <div className='mt-[35px] mb-[30px] py-[5px] border-b-[2px] border-primary' >
                            Group 1: Gojek
                        </div> */}

                {
                    isLoading ?
                        <div className='flex h-[400px] justify-center'>
                            <LoadingPlaceholder />
                        </div>
                        :

                        LIST_OF_COHORTS?.length > 0 ?
                            <div className='grid grid-cols-3 max-md:grid-cols-2 max-md:mx-[40px] max-sm:grid-cols-1 gap-[30px] mt-[35px]'>
                                {


                                    LIST_OF_COHORTS?.map((item)=> <CohortCard data={item}  key={item.id}/> )


                                }
                            </div>

                            :

                            <EmptyPlaceholder />


                }

                {
                    isFetchingNextPage && (
                        <div className='my-[180px]'>
                            <LoadingPlaceholder />
                        </div>
                    )
                }



                {
                    hasNextPage && !isFetchingNextPage && (
                        <div className='flex justify-center mt-[40px]'>
                            <Button title='Load More' containerClass='btn-link' isNotPrimary={true}
                                disabled={isFetchingNextPage}
                                onClick={()=>fetchNextPage()}/>

                        </div>
                    )
                }


            </div>


        </Layout>
    );
};

export default Cohort;
