import { protectedRoutes } from './ProtectedRoute';
import { publicRoute } from './PublicRoutes';

const protectedRoutesArray = [...protectedRoutes.map((route) => {
    return { ...route, isProtected: true };
})];

export const routes = [
    ...publicRoute,
    ...protectedRoutesArray
];
