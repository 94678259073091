import React from 'react';
import { ReviewData } from 'services/lesson/ReviewTypes';
import ReviewCard from './ReviewCard';
import { LessonType } from 'services/lesson/LessonTypes';

interface ReviewsProps {
    data: ReviewData[]
    handlePeerReviews : () => void
    hasNextPage?: boolean
    handleLoadMoreReviews?: ()=> void
    isLoading?:boolean
    questionType: LessonType
    isMultipleChoice?: boolean
    optionsForMultiple?:string[]
}

export const Reviews = (props: ReviewsProps): React.JSX.Element => {
    const ReviewList = props.data;

    return (
        <div className='flex flex-col 	'>
            {
                ReviewList.map((item, index)=> (
                    <div key={item.id}>
                        {index > 0 && <div className='divider before:bg-neutral-content after:bg-neutral-content' />}
                        <ReviewCard
                            isMultipleChoice={props?.isMultipleChoice}
                            optionsForMultiple={props?.optionsForMultiple}
                            data={item}
                            handlePeerReviews={props.handlePeerReviews}/>
                    </div>
                ))
            }
            {
                props?.hasNextPage && !props.isLoading && (
                    <div className='mt-[20px] text-primary cursor-pointer hover:opacity-[0.6]' onClick={props.handleLoadMoreReviews}>
                        Load More Answer
                    </div>
                )
            }
            {
                props?.isLoading &&
                <div className='flex justify-center'>
                    <span className='loading loading-spinner text-primary loading-sm mt-[40px]' />
                </div>
            }

        </div>
    );
};

export default Reviews;
