import React, { useState } from 'react';
import './SingleChoice';

interface SingleChoiceProps {
    id: number;
    options: string[];
    value?: string;
    onChange?: (value: string) => void;
    answer?:string
    myAnswer?:string
}

export const SingleChoice = (props: SingleChoiceProps): JSX.Element => {
    // Initialize state with the first option or an empty string
    const [checked, setIsChecked] = useState<string>(props.value || "");

    // Correctly type the event and use e.target.value
    const onChange = (e: React.ChangeEvent<HTMLInputElement>, index:number) => {
        setIsChecked(index.toString());
        props?.onChange && props.onChange(index.toString());
    };

    // const trueClass = ' bg-[#E3F1DF] rounded-[8px]';
    // const falseClass = 'bg-[#FFE3E6]/[0.6] rounded-[8px]';

    return (
        <div className='grid grid-cols-1 gap-[15px]'>
            {
                props.options.map((item, index) =>
                {
                    let isTrue = false;

                    if(props.answer?.toString() && props.myAnswer?.toString()){

                        if(props.answer.toString() == props.myAnswer.toString()){
                            isTrue = true;
                        }
                    }

                    return (<div className={`flex items-center `} key={`${item}-${props.id}`}>
                        <div className='form-control'>
                            <label className='label cursor-pointer'>

                                <input
                                    onChange={(e)=> onChange(e, index)} // Use onChange event handler
                                    checked={checked.toString() === index.toString()}
                                    value={item} // Assign value corresponding to the item
                                    type='radio'
                                    name={`option-${props.id}`} // Give a unique name based on the id
                                    className='radio radio-primary !checked:text-white	  mr-[10px] radio-sm border-1 border-primary bg-white' />
                                <span className=''>{item}</span>
                            </label>
                        </div>

                        {isTrue &&  props.myAnswer == index.toString() && <div className='ml-[10px] text-center  text-[14px] font-semibold text-white bg-success px-[12px] py-[2px] rounded-[1000px]'>Correct answer</div>}
                        {!isTrue &&  props.myAnswer == index.toString() && <div className='ml-[10px] text-center  text-[14px] font-semibold text-white bg-[#FC2C51] px-[12px] py-[2px] rounded-[1000px]'>Incorrect answer</div>}
                    </div>);
                }
                )
            }
        </div>
    );
};

export default SingleChoice;
