import { useMutation } from "@tanstack/react-query";
import { ReactionServices } from "services/reaction/ReactionService";

const ServiceInstance = new ReactionServices();

export const useCreateReaction = ()=> {
    const {mutate, isLoading} = useMutation(ServiceInstance.createReaction);

    return {onCreateReaction: mutate, isLoading};
};

export const deleteReaction = () => {
    const {mutate, isLoading} = useMutation(ServiceInstance.deleteReaction);

    return {
        onDeleteReaction: mutate,
        isLoading
    };
};
