
import { ASSETS } from 'assets';
import React from 'react';

interface Props {
    children: React.JSX.Element
    isLate?: boolean
}

export const Banner = (props: Props): React.JSX.Element => {
    return (
        <div className=' max-lg:mx-[25px]'>
            <div className={`h-[159px] ${props?.isLate ? 'bg-[#EE6400] max-sm:h-[190px]': 'bg-gradient-to-r from-[#FC2C51] to-[#BE0526]'}
             rounded-[30px] px-[40px] py-[21px] relative overflow-hidden box`}>
                <img src={ASSETS.dashboard.progressTrack.waveProgressTrack} className='absolute left-0 top-0'/>
                {props.children}
            </div>
        </div>
    );
};

export default Banner;
