import React from 'react';
import ProfilePhoto from './element/ProfilePhoto';
import { UserObj } from 'services/user/UserTypes';
import { SDG_OPTIONs } from 'config/constant';
import { useNavigate } from 'react-router-dom';
// import { ProtectedRouteNames } from 'config/routes/ProtectedRoute';

// import { ASSETS } from 'assets';

interface CohortCardProps {
    data: Partial<UserObj>
}

export const CohortCard = (props: CohortCardProps): JSX.Element => {
    const navigate = useNavigate();

    const navigateToProfile = () => {
        if(!props?.data?.id) return;
        const id = props.data.id;

        navigate(id.toString());
    };

    return (
        <div
            onClick={navigateToProfile}
            className='flex flex-col items-center p-[24px] bg-white w-auto relative rounded-[30px]
            cursor-pointer hover:opacity-[0.7]'>
            <ProfilePhoto
                photo={`${props.data?.avatar || 'https://placehold.co/400x400/png'}`}
                nationality={`${props.data?.address?.flag || 'https://placehold.co/600x400/ffffff/ffffff'}`}/>
            <div className='mt-[16px] font-semibold'>{props.data.name}</div>
            <div className='mt-[8px] text-center font-regular'>{props.data.title || "-"}</div>
            <div className='mt-[8px] text-center font-regular text-base-200'>
                {`${props.data.address?.country || "-"}, ${props.data.address?.city || "-"}`}
            </div>
            <div className='flex flex-col mt-[24px] gap-y-[10px]'>
                <div className='flex gap-[6px] flex-wrap'>
                    {props.data?.expertises?.slice(0, 3).map((item)=>{
                        return <React.Fragment key={item}>
                            <span key={item} className='badge text-accent bg-base-content border-0 line-clamp-1'>{item}</span>
                            {/* {items.length - 1  !== index && <img src={ASSETS.icon.Spacer}/>} */}
                        </React.Fragment>;
                    })}
                </div>

                <div className='flex gap-[6px] flex-wrap'>
                    {props.data?.sectors?.slice(0, 3).map((item)=> (
                        <div key={item} className=''>
                            <span key={item} className='badge text-accent-focus bg-base-content border-0 line-clamp-1'>{item}</span>
                            {/* {items.length - 1  !== index && <img src={ASSETS.icon.Spacer}/>} */}
                        </div>
                    ))}
                </div>
                <div className='text-center gap-[10px] grid grid-cols-5'>
                    {
                        props.data?.sdgs?.slice(0, 3).map((item)=> (
                            <img key={item} className='w-[30px]' src={SDG_OPTIONs.find((child)=> child.text == item)?.images}/>
                        ))
                    }
                </div>
            </div>

            {/* <div className='btn btn-primary btn-sm w-full mt-[24px]'>
                Follow
            </div> */}
            {/* <div className='btn btn-outline btn-primary btn-sm w-full mt-[24px] '>
                <img src={ASSETS.cohort.Following}/>
                Following
            </div> */}
        </div>
    );
};

export default CohortCard;
