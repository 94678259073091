import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { endpoints, queryClient } from "config/api";
import { TOAST_SUCCESS, showToast } from "helper/toastHelper";
import { LessonServices } from "services/lesson/LessonServices";
import { LessonDetail } from "services/lesson/LessonTypes";
import { getCommentRequest, getReviewRequest } from "services/lesson/ReviewTypes";

const LessonServicesInstance = new LessonServices();

export const useGetLessonDetail = (programId: string) => {
    const { data, isLoading } = useQuery<LessonDetail, Error>(
        [endpoints.assignment.lessons, programId],
        () => LessonServicesInstance.getLessonDetail(programId)
    );

    return { data, isLoading };
};

export const useSubmitLesson = () => {
    const {mutate,isLoading } = useMutation(LessonServicesInstance.submitAnswer, {
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [endpoints.program.modules]});
            queryClient.invalidateQueries({queryKey: [endpoints.assignment.lessons],exact: false});
            showToast(TOAST_SUCCESS, "Successfully submitted");
        }
    });

    return {onSubmitAnswer: mutate, isLoading};
};

export const useGetPeerReviews = () => {
    const {mutate,isLoading } = useMutation(LessonServicesInstance.getPeerReviews);

    return {onGetPeerReviews: mutate, isLoading};
};


export const useGetPeerReviewsv2 = (query: getReviewRequest) => {
    const fetchPeerReviews = ({ pageParam = 1 }) => {
        const queryWithPage: getReviewRequest = {
            ...query,
            page: pageParam,
            per_page: 5
        };

        return LessonServicesInstance.getPeerReviews(queryWithPage);
    };

    const params = {
        filter: query?.my_group ?? 1,
        sort: query?.sort ?? 'recent'
    };

    const queryKey = `${endpoints.assignment.getPeerReviews(query.page_item_id)}?filter[my_group]=${params.filter}&sort=${params.sort}`;

    const {
        data,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        refetch,
        fetchStatus
    } = useInfiniteQuery(
        [queryKey, query.page_item_id],
        fetchPeerReviews,
        {
            getNextPageParam: (lastPage) => {
                const currentPage = lastPage?.meta?.current_page;
                const lastPageNumber = lastPage?.meta?.last_page;

                if (currentPage && lastPageNumber && currentPage < lastPageNumber) {
                    return currentPage + 1;
                } else {
                    return undefined;
                }
            },
            enabled: query?.start_fetch ?? false,
        }
    );

    return { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, fetchStatus };
};



export const useGetComment = (query: getCommentRequest) => {
    const fetchPeerReviews = ({ pageParam = 1 }) => {
        const queryWithPage:getCommentRequest = {
            ...query,
            page: pageParam,
            per_page: 5
        };

        return LessonServicesInstance.getComment(queryWithPage);
    };

    const queryKey = `${endpoints.assignment.getComments(query.page_item_id, query.user_page_item_id)}`;

    const {
        data,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        refetch,
        fetchStatus
    } = useInfiniteQuery(
        [queryKey, query.page_item_id],
        fetchPeerReviews,
        {
            getNextPageParam: (lastPage) => {
                if(!lastPage?.meta?.current_page) return undefined;
                const last_page = lastPage?.meta?.last_page  || 0;

                if (lastPage.meta.current_page < last_page ) {
                    return lastPage.meta.current_page + 1;
                } else {
                    return undefined;
                }
            },
            enabled: query?.start_fetch,
        }
    );

    return { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, fetchStatus };
};

export const useSubmitComment = () => {
    const {mutate,isLoading } = useMutation(LessonServicesInstance.submitComments,{
        onSuccess: () => queryClient.invalidateQueries({queryKey: [endpoints.assignment.getPeerReviews], exact: false})
    });

    return {onSubmitComment: mutate, isLoading};
};


export const useDeleteComment = () => {
    const {mutate,isLoading } = useMutation(LessonServicesInstance.deleteComments,{
        onSuccess: () => queryClient.invalidateQueries({queryKey: [endpoints.assignment.getPeerReviews], exact: false})
    });

    return {onDeleteComment: mutate, isLoading};
};

export const useUpdateComment = () => {
    const {mutate,isLoading } = useMutation(LessonServicesInstance.updateComments,{
        onSuccess: () => queryClient.invalidateQueries({queryKey: [endpoints.assignment.getPeerReviews], exact: false})
    });

    return {onUpdateComment: mutate, isLoading};
};
