export const storageKeys = {
    authToken: 'authToken',
    guestMode: 'guest'
};

const storage = localStorage;

// const validateKey = (key: string): void => {
//     if (!(key in storageKeys)) {
//         throw new Error(`Invalid storage key: ${key}`);
//     }
// };

export const setLocalStorage = (key: string, value: string): void => {
    // validateKey(key);
    storage.setItem(key, value);
};

export const getLocalStorage = (key: string): string | null => {
    // validateKey(key);

    return storage.getItem(key);
};

export const removeLocalStorage = (key: string): void => {
    // validateKey(key);
    storage.removeItem(key);
};

export const clearAllLocalStorage = (): void => {
    storage.clear();
};
