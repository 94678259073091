import { REACTION_TYPE_RECORD } from 'config/constant';
import { showToast } from 'helper/toastHelper';
import { useCreateReaction } from 'hooks/reaction/reaction';
import { Loader2 } from 'lucide-react';
import React, { useState } from 'react';
import Lottie from 'react-lottie';

interface ReactionProps {
    icon: unknown; // should be lottie json
    tooltip: string;
    isComment?: boolean;
    id: number;
    name : string;
    handlePeerReview: ()=>void;
}

const Reaction = (props: ReactionProps): React.JSX.Element => {
    const {onCreateReaction, isLoading} = useCreateReaction();
    const [isPaused, setIsPaused] = useState(true);

    const handleCommentAction = () => {
        if(isLoading) return;
        onCreateReaction({
            user_page_item_id: props?.isComment ? undefined :  props.id,
            comment_id: props?.isComment ? props.id : undefined,
            type: props.name  as keyof typeof REACTION_TYPE_RECORD
        },{
            onSuccess: () => {
                props.handlePeerReview();
                showToast('success', "Reaction Updated");
            }, onError: () => {
                showToast('error', "Server Error");
            }
        });
    };

    const defaultOptions = {
        loop: true,
        autoplay: false,
        animationData: props.icon,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }

    };


    return (
        <div className='tooltip tooltip-info text-white tooltip-top' data-tip={props.tooltip} onClick={handleCommentAction}>
            <div className='cursor-pointer transition-transform duration-300 ease-in-out hover:scale-150 text-[20px]'
                onMouseEnter={() => setIsPaused(false)}
                onMouseLeave={() => setIsPaused(true)}>
                {isLoading ? (
                    <div className='pt-[6px]'>
                        <Loader2 className='animate-spin' color='#FC2C51' size={isPaused ? 36: 24} />
                    </div>
                ) : (
                    <Lottie options={defaultOptions}
                        height={40}
                        width={40}
                        isStopped={isPaused}
                        isPaused={isPaused}/>
                )}
            </div>
        </div>
    );
};

export default Reaction;
