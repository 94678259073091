import React from 'react';
import { type RouteType } from './Type/Router';
import Dashboard from 'containers/Dashboard/Dashboard';
import Assignment from 'containers/Assignment/Assignment';
import Cohort from 'containers/Cohort/Cohort';
import Profile from 'containers/Profile/Profile';
import SettingProfile from 'containers/SettingProfile/SettingProfile';
import UserProfile from 'containers/UserProfile/UserProfile';

export const ProtectedRouteNames = {
    DASHBOARD: '/dashboard',
    LESSON: (id: string) => `/page/${id}`,
    COHORT: '/cohort',
    PROFILE: '/profile',
    PROFILE_SETTINGS: '/profile/settings',
    PROFILE_PEOPLE: (id:string)=>`/cohort/${id}`,
};


export const protectedRoutes: RouteType[] = [
    {
        path: ProtectedRouteNames.DASHBOARD,
        element: <Dashboard />
    },
    {
        path: ProtectedRouteNames.LESSON(":id"),  // Not invoking the function here
        element: <Assignment />
    },
    {
        path: ProtectedRouteNames.COHORT,
        element: <Cohort />
    },
    {
        path: ProtectedRouteNames.PROFILE,
        element: <Profile />
    },
    {
        path: ProtectedRouteNames.PROFILE_PEOPLE(":id"), // Not invoking the function here
        element: <UserProfile />
    },
    {
        path: ProtectedRouteNames.PROFILE_SETTINGS,
        element: <SettingProfile />
    },

];

