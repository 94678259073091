import { useInfiniteQuery } from "@tanstack/react-query";
import { endpoints } from "config/api";
import { CohortServices } from "services/cohort/CohortService";

const CohortServiceInstance = new CohortServices();

export const useGetDefaultCohort = (body: {expertises:string[], sectors:string[], isMyGroup:boolean}) =>{
    const fetchCohort = ({ pageParam = 1 }) => {
        const param = {
            ...body,
            page: pageParam
        };

        return CohortServiceInstance.getDefaultCohort(param);
    };


    const {
        data,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        refetch,
        fetchStatus
    } = useInfiniteQuery({
        queryKey: [endpoints.cohort.cohorts, body],
        queryFn: fetchCohort,
        staleTime: 1000 * 5 * 60,
        getNextPageParam: (lastPage) => {
            if (lastPage.users.meta.current_page < lastPage.users.meta.last_page) {
                return lastPage.users.meta.current_page + 1;
            } else {
                return undefined;
            }
        }
    });

    const dataFlat =  data?.pages?.flatMap(page => page?.users.data) ?? [];

    return { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, fetchStatus, dataFlat };
};
