import HeroLogo from 'assets/images/hero-cover.png';
import HeroLogoHD from 'assets/images/hero-cover-hd.png';
import InformationProgram from 'assets/images/information-program.png';
import LoginCover from 'assets/images/login-cover.png';
import WaveTop1 from 'assets/images/wave-top-1.svg';
import WaveTop2 from 'assets/images/wave-top-2.svg';
import WaveBot1 from 'assets/images/wave-bot-1.svg';
import WaveBot2 from 'assets/images/wave-bot-2.svg';
import LayoutTopWave from 'assets/images/header-layout.png';
import LogoWithText from 'assets/icons/final_logo.svg';
import LogoWithTextWhite from 'assets/icons/final_logo_white.svg';
import OvalWhite from 'assets/icons/oval-white.svg';
import Hamburger from 'assets/icons/hamburger.svg';
import ClockPink from 'assets/icons/clock-pink.svg';
import GrassOrnamentsBlue from 'assets/icons/grass-ornaments.svg';
import GrassOrnamentsGreen from 'assets/icons/grass-ornaments-green.svg';
import WaveProgressTrack from 'assets/images/dashboards/wave-track.svg';
import HeaderElipse from 'assets/images/dashboards/header-elipse.svg';
import HeaderWave from 'assets/images/dashboards/wave-header.svg';
import HeaderOrnamentLeft from 'assets/images/dashboards/header-ornament-left.svg';
import ClockGray from 'assets/images/dashboards/clock-sm-gray.svg';
import CalendarGray from 'assets/images/dashboards/calendar-sm-gray.svg';
import Chevron from 'assets/icons/chevron.svg';
import ChecklistWhite from 'assets/icons/checklist-white.svg';
import LockWhite from 'assets/icons/lock-white.svg';
import CalendarWrite from 'assets/icons/calendar-write.svg';
import TickGreenCircle from 'assets/icons/tick-cirlce-green.svg';
import GreenCircle from 'assets/icons/circle-green.svg';
import GrayLock from 'assets/icons/lock-gray.svg';
import AssigmentSmallGray from 'assets/icons/assignment-small-gray.svg';
import BookSmallGray from 'assets/icons/book-small-gray.svg';
import ZoomBlue from 'assets/images/dashboards/zoom-blue.png';
import GoogleLogo from 'assets/icons/google-logo.svg';
import FooterElips from 'assets/images/footer-layoput.svg';
import OrnamentsRight from 'assets/images/ornaments-right.svg';
import EyeOpen from 'assets/images/authentication/eye-outline.svg';
import EyeClose from 'assets/images/authentication/eye-off-outline.svg';
import TickGreen from 'assets/images/assignment/tick-green.svg';
import TickCircle from 'assets/images/assignment/tick-circle.svg';
import Filter from 'assets/images/assignment/filter.svg';
import Send from 'assets/images/assignment/send.svg';
import Cross from 'assets/icons/cross.svg';
import Follow from 'assets/images/cohort/follow.svg';
import Following from 'assets/images/cohort/following.svg';
import SDG01 from './icons/E-WEB-Goal-01.png';
import SDG02 from './icons/E-WEB-Goal-02.png';
import SDG03 from './icons/E-WEB-Goal-03.png';
import SDG04 from './icons/E-WEB-Goal-04.png';
import SDG05 from './icons/E-WEB-Goal-05.png';
import SDG06 from './icons/E-WEB-Goal-06.png';
import SDG07 from './icons/E-WEB-Goal-07.png';
import SDG08 from './icons/E-WEB-Goal-08.png';
import SDG09 from './icons/E-WEB-Goal-09.png';
import SDG10 from './icons/E-WEB-Goal-10.png';
import SDG11 from './icons/E-WEB-Goal-11.png';
import SDG12 from './icons/E-WEB-Goal-12.png';
import SDG13 from './icons/E-WEB-Goal-13.png';
import SDG14 from './icons/E-WEB-Goal-14.png';
import SDG15 from './icons/E-WEB-Goal-15.png';
import SDG16 from './icons/E-WEB-Goal-16.png';
import SDG17 from './icons/E-WEB-Goal-17.png';
import COMMENT from './icons/comment.svg';
import SEND_PINK from './icons/send-pink.svg';
import LikeIcon from './icons/like-icon.svg';
import Sort from './icons/sort.svg';
import Spacer from './icons/spacer.svg';
import CommentGr from './icons/comment-copy.svg';
import Email from './icons/email.svg';
import SuperGraphicsFooter from './icons/supergraphics-footer.svg';
import SuperGraphicsAuth from './images/authentication/super-graphics.svg';

import LottieHearth from './lottie/heart_animation_2.json';
import LottieClap from './lottie/clap_animation.json';
import LottieShock from './lottie/shocked_animation.json';
import LottieInsight from './lottie/insightfull_animation_2.json';
import LottieGrad from './lottie/lottie_graduation.json';


export const ASSETS = {
    images: {
        HeroLogo,
        HeroLogoHD,
        InformationProgram,
        LoginCover
    },
    waves: {
        WaveTop1,
        WaveTop2,
        WaveBot1,
        WaveBot2
    },
    layout: {
        top: LayoutTopWave,
        ovalWhite: OvalWhite,
        hamburger: Hamburger,
        headerElips: HeaderElipse,
        headerWave: HeaderWave,
        headerOrnamentLeft: HeaderOrnamentLeft,
        FooterElips,
        OrnamentsRight,
        Cross,
        SuperGraphicsFooter
    },
    logo: {
        withText: LogoWithText,
        withTextWhite: LogoWithTextWhite
    },
    dashboard: {
        progressTrack: {
            clockpink: ClockPink,
            grassOrnamentsBlue: GrassOrnamentsBlue,
            grassOrnamentGreen: GrassOrnamentsGreen,
            waveProgressTrack: WaveProgressTrack
        },
        module: {
            clockGray: ClockGray,
            calendarGray: CalendarGray,
            checklistWhite: ChecklistWhite,
            lockWhite: LockWhite,
            calendarWrite: CalendarWrite,
            grayLock: GrayLock,
            tickGreenCircle: TickGreenCircle,
            greenCircle: GreenCircle,
            AssigmentSmallGray,
            BookSmallGray,
            ZoomBlue
        }
    },
    authentication: {
        EyeClose,
        EyeOpen,
        SuperGraphicsAuth
    },
    assignment: {
        TickGreen,
        TickCircle,
        Filter
    },
    cohort: {
        Follow,
        Following
    },
    icon: {
        chevron: Chevron,
        GoogleLogo,
        Send,
        COMMENT,
        SEND_PINK,
        LikeIcon,
        Sort,
        Spacer,
        CommentGr,
        Email
    },
    sdg: {
        SDG01,
        SDG02,
        SDG03,
        SDG04,
        SDG05,
        SDG06,
        SDG07,
        SDG08,
        SDG09,
        SDG10,
        SDG11,
        SDG12,
        SDG13,
        SDG14,
        SDG15,
        SDG16,
        SDG17,
    },
    lottie: {
        LottieClap,
        LottieHearth,
        LottieInsight,
        LottieShock,
        LottieGrad
    }
};
