import { ProtectedRouteNames } from "config/routes/ProtectedRoute";
import { showToast } from "helper/toastHelper";
import { useUploadFile } from "hooks/file/file";
import { useGetCountry, useGetExpertises, useGetSdgs, useGetSectors } from "hooks/resources/resources";
import { useGetProfile, useUpdateProfile } from "hooks/user/user";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserObj } from "services/user/UserTypes";
import * as yup from 'yup';

// Schema for the form with both password and password confirmation
const passwordSchema = yup.object().shape({
    password: yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must have at least one lowercase char")
        .matches(/[A-Z]/, "Password must have at least one uppercase char")
        .matches(/\d/, "Password must have at least one number")
        .matches(/\W|_/, "Password must have at least one special character"),
    password_confirmation: yup.string()
        .required("Password confirmation is required")
        .oneOf([yup.ref('password')], "Passwords must match")
});

export const useProfileForm = () => {

    const navigate = useNavigate();
    const {data,isLoading: isGetProfileLoading} = useGetProfile();
    const [form, setForm] = useState<Partial<UserObj>>({});
    const [expertiseForm, setExpertiseForm] = useState<string[]>(form.expertises || []);
    const [sdgForm, setSdgForm] = useState<string[]>(form.expertises || []);
    const [sectorForm, setSectorForm] = useState<string[]>(form.sectors || []);
    const [achievementsTmp, setAchievmentTmp] = useState("");
    const [achievementsForm, setAchievmentForm] = useState<string[]>([]);
    const expertiseSelectRef = useRef<HTMLSelectElement>(null);
    const sectorSelectRef = useRef<HTMLSelectElement>(null);
    const sdgSelectRef = useRef<HTMLSelectElement>(null);

    const {data: EXPERTISE_OPTIONS,isLoading: isGetExpertiseLoading} = useGetExpertises();
    const {data: SECTOR_OPTIONS ,isLoading: isGetSectorsLoading} = useGetSectors();
    const {data: SDG_OPTION ,isLoading: isGetSdgLoading} = useGetSdgs();
    const {onUpload, isLoading: loadingUploadFIle} = useUploadFile();
    const {onUpdate, isLoading: loadingUpdateProfile} = useUpdateProfile();
    const {data: COUNTRY_OPTIONS, isLoading: COUNTRY_LOADING} = useGetCountry();

    useEffect(()=> {
        if(!data)return;
        setForm(data as Partial<UserObj>);
        setExpertiseForm(data.expertises);
        setSectorForm(data.sectors);
        setAchievmentForm(data.achievements);
        setSdgForm(data.sdgs);
    },[data]);

    const updateProfileLoading = loadingUploadFIle || loadingUpdateProfile;
    const isLoading = isGetProfileLoading || isGetExpertiseLoading || isGetSectorsLoading || COUNTRY_LOADING || isGetSdgLoading;

    const fileInputRef = useRef<HTMLInputElement | null>(null);



    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (file && file.type.match('image.*')) {
            onUpload(file, {
                onSuccess:(res) => {
                    setForm((prevState)=> {
                        return {...prevState, avatar: res.url};
                    });
                    showToast("success", "Profile Photo Updated");
                }
            });


        }
    };

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onFormUpdate = (data: Partial<UserObj>) => {
        if(data?.address?.city){
            setForm((prevState)=> {
                const addressPrevState = {...prevState.address};

                return {...prevState, address : {
                    ...addressPrevState,
                    city: data.address?.city ? data?.address.city: addressPrevState.city,
                    flag: data.address?.flag ? data?.address.flag: addressPrevState.flag,
                    country: data.address?.country ? data?.address.country: addressPrevState.country,
                }};
            });
        }

        else{
            setForm((prevState)=> {
                return {...prevState, ...data};
            });

        }

    };

    const handleExpertiseChange = () => {
        if(!expertiseSelectRef.current?.value) return;
        const selectedData = expertiseSelectRef.current?.value;

        setExpertiseForm((prevState)=> [...prevState, selectedData]);
        expertiseSelectRef.current.value = '';
    };

    const handleSectorChange = () => {
        if(!sectorSelectRef.current?.value) return;
        const selectedData = sectorSelectRef.current?.value;

        setSectorForm((prevState)=> [...prevState, selectedData]);
        sectorSelectRef.current.value = '';
    };

    const handleSdgChange = () => {
        if(!sdgSelectRef.current?.value) return;
        const selectedData = sdgSelectRef.current?.value;

        setSdgForm((prevState)=> [...prevState, selectedData]);
        sdgSelectRef.current.value = '';
    };

    const handleRemoveSdg = (index: number) => {
        // Create a new array by excluding the element at the index
        const updatedSdg = [
            ...sdgForm.slice(0, index),
            ...sdgForm.slice(index + 1)
        ];

        setSdgForm(updatedSdg);
    };

    const handleAddAchievement = () => {
        if(achievementsTmp === "") return;
        setAchievmentForm((prevState)=> [...prevState, achievementsTmp]);
        setAchievmentTmp("");
    };

    const handleRemoveExpertises = (index: number) => {
        // Create a new array by excluding the element at the index
        const updatedExpertises = [
            ...expertiseForm.slice(0, index),
            ...expertiseForm.slice(index + 1)
        ];

        setExpertiseForm(updatedExpertises);
    };

    const handleRemoveSectors = (index: number) => {
        // Create a new array by excluding the element at the index
        const updatedSectors = [
            ...sectorForm.slice(0, index),
            ...sectorForm.slice(index + 1)
        ];

        setSectorForm(updatedSectors);
    };

    const handleRemoveAchievments = (index: number) => {
        // Create a new array by excluding the element at the index
        const updatedAchievments = [
            ...achievementsForm.slice(0, index),
            ...achievementsForm.slice(index + 1)
        ];

        setAchievmentForm(updatedAchievments);
    };

    const handleSubmitPass = async () => {
        if(form.password && form.password != ""){
            try {
                await passwordSchema.validate({
                    password: form.password,
                    password_confirmation: form.password_confirmation
                });
                // If passwords match and meet all criteria, continue with your logic
            } catch (error) {
                if (error instanceof yup.ValidationError) {
                    // Here we know that error is specifically a ValidationError from Yup
                    showToast("error", error.message);
                } else {
                    // For any other type of error, you might want to handle it differently
                    showToast("error", "An unexpected error occurred");
                }

                return;
            }
        }

        const submitForm:Partial<UserObj>  = {
            ...form,
            expertises: expertiseForm,
            sectors: sectorForm,
            description: form.description || "",
            title: form.title || "-",
            achievements: form.achievements,
            address: {
                country: form.address?.country || "",
                city: form.address?.city || "",
                flag: form.address?.flag || ""
            },
            sdgs: sdgForm,
            password: form.password == "" ? undefined : form.password,
            password_confirmation: form.password_confirmation == "" ? undefined : form.password_confirmation
        };

        onUpdate(submitForm,{
            onSuccess: ()=> showToast("success", "Profile Updated"),
            onError: () => showToast("error", "Server Error")
        });
    };

    const handleSubmitForm = async () => {
        const isRequiredFieldDone = form.address?.country || form.address?.city;

        if(!isRequiredFieldDone){
            showToast("error", "Country and State is Required");

            return;
        }


        const submitForm:Partial<UserObj>  = {
            ...form,
            expertises: expertiseForm,
            sectors: sectorForm,
            description: form.description || "-",
            title: form.title || "-",
            achievements: form.achievements,
            address: {
                country: form.address?.country || "-",
                city: form.address?.city || "-",
                flag: form.address?.flag || "-"
            },
            sdgs: sdgForm,
            // password: form.password == "" ? undefined : form.password,
            // password_confirmation: form.password_confirmation == "" ? undefined : form.password_confirmation
        };

        if(!form.avatar){
            submitForm.avatar = 'https://placehold.co/96x96';
        }



        onUpdate(submitForm,{
            onSuccess: ()=> {
                setTimeout(()=>{
                    navigate(ProtectedRouteNames.PROFILE);

                },500);
                showToast("success", "Profile Updated");
            },
            onError: () => showToast("error", "Server Error")
        });
    };

    return {
        form,
        setForm,
        data,
        onUpdate,
        onUpload,
        COUNTRY_OPTIONS,
        updateProfileLoading,
        isLoading,
        handleImageChange,
        triggerFileInput,
        fileInputRef,
        onFormUpdate,
        SECTOR_OPTIONS,
        EXPERTISE_OPTIONS,
        handleAddAchievement,
        handleRemoveAchievments,
        handleExpertiseChange,
        handleSectorChange,
        handleRemoveExpertises,
        handleRemoveSectors,
        expertiseSelectRef,
        sectorSelectRef,
        sectorForm,
        achievementsForm,
        achievementsTmp,
        setAchievmentTmp,
        setAchievmentForm,
        expertiseForm,
        setExpertiseForm,
        handleSubmitForm,
        SDG_OPTION,
        sdgForm,
        sdgSelectRef,
        handleSdgChange,
        handleRemoveSdg,
        handleSubmitPass
    };
};
