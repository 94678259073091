import { useGetProfile } from 'hooks/user/user';
import React, { useRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDeleteComment, useUpdateComment } from 'hooks/lesson/lesson';
import Dropdown from 'components/Dropdown/Dropdown';
import Button from 'components/Button/Button';
import { ASSETS } from 'assets';
import { showToast } from 'helper/toastHelper';
import Reaction from '../../Reaction/Reaction';
import { MAX_TEXT_LENGTH, REACTION_TYPE, REACTION_TYPE_RECORD, REACTION_TYPE_TEXT } from 'config/constant';
import { ReactionObj } from 'services/reaction/ReactionType';
import { useNavigate } from 'react-router-dom';
import { ProtectedRouteNames } from 'config/routes/ProtectedRoute';
import Lottie from 'react-lottie';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import Button from 'components/Button/Button';

interface CommnetCardProps {
    avatar: string;
    name: string;
    updated_at: string;
    comment: string;
    email: string
    id: number
    deleted_at?: string;
    handlePeerReviews: () => void
    is_edited: boolean
    user_id: number
    reactions: ReactionObj[]
    has_next_page?: boolean
    is_fetching_next_page: boolean
    fetch_next_page: () => void
}

export const CommentCard = (props: CommnetCardProps): React.JSX.Element => {
    const {data} = useGetProfile();
    const isMyComment = data?.email === props.email;
    const [textLength, setTextLength] = useState(0);

    const {onDeleteComment} = useDeleteComment();
    const {onUpdateComment, isLoading} = useUpdateComment();
    const [isEditFlag, setIsEditFlag]  = useState(false);
    const contentEditableRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();

    const handleDelete = () => {
        onDeleteComment(props.id.toString(), {
            onSuccess: ()=> {
                showToast('success', "Comment Deleted successfully");
                props.handlePeerReviews();
            }
        });

    };

    const toogleEditFlag = () => setIsEditFlag((prevState) => !prevState);

    const handleUpdateComment = () => {
        if(!contentEditableRef.current?.innerHTML) return;
        onUpdateComment({
            id: props.id.toString(),
            content: contentEditableRef.current?.innerHTML
        },{
            onSuccess: ()=> {
                props.handlePeerReviews();
                showToast('success', "Comment Updated successfully");
                toogleEditFlag();
            }
        }
        );
    };

    const getLatestReaction = ():string[] => {
        const dataReaction = props.reactions || [];

        if(dataReaction.length === 0 ){
            return [];
        }

        const reaction = dataReaction.slice(0,3);

        return reaction.map((item)=> item.type);
    };

    const getLatestReactionUser = (): string => {
        const dataReaction = props.reactions || [];

        if(dataReaction.length === 0 ){
            return "";
        }

        const reaction = dataReaction[0];

        if(dataReaction.length > 1 ){
            return `${reaction.user?.name}, and ${dataReaction.length - 1} others`;
        }

        return `${reaction.user?.name}`;
    };

    const latestReactions = getLatestReaction();



    const navigateToProfile = (id:string) => {

        navigate(ProtectedRouteNames.PROFILE_PEOPLE(id), {replace: true});
    };

    return (
        <div className='flex  gap-x-[12px] gap-y-[16px] mt-[12px]  rounded-[12px]'>
            <div className='avatar'>
                <div
                    onClick={()=>navigateToProfile(props.user_id.toString())}
                    className='w-8 h-8 rounded-full cursor-pointer' >
                    <img src={props.avatar || 'https://placehold.co/600x600/F20D69/F20D69'} />
                </div>
            </div>
            {/* <div className='w-[32px] h-[32px]'>
                <img className='w-8 h-8 object-cover rounded-full'  />
            </div> */}
            <div className='flex flex-col w-full'>
                <div className='flex gap-x-[10px] items-center justify-between'>
                    <div className='flex items-center gap-x-[10px] max-sm'>
                        <div
                            onClick={()=>navigateToProfile(props.user_id.toString())}
                            className='mb-[8px] leading-[20px] font-medium cursor-pointer' >{props?.name}</div>
                        {props.is_edited && !props?.deleted_at ?<div className='text-base-300 text-[14px] leading-[20px] pb-[7px]'>edited</div> :<></>}
                    </div>
                    {
                        isEditFlag ?
                            <div className='cursor-pointer' onClick={toogleEditFlag}>Cancel</div>
                            :
                            isMyComment ?
                                <Dropdown
                                    icon={<Button
                                        leftIcon={<MoreVertIcon style={{ color: '#FC2B51' }}/>}
                                        title=''
                                        containerClass={`btn-sm btn-ghost ${props.deleted_at ? 'hidden': ""}`}/>}>

                                    <li>
                                        <a onClick={toogleEditFlag}>Update</a>
                                    </li>
                                    <li onClick={handleDelete}>
                                        <a>Delete</a>
                                    </li>
                                </Dropdown>

                                :
                                <></>
                    }
                </div>
                {
                    props?.deleted_at ?
                        <div className='text-base-100'>
                            -- Comment Deleted --
                        </div>

                        :

                        isEditFlag ?
                            <div className='flex relative'>
                                <div
                                    onInput={()=> {
                                        if (!contentEditableRef.current) return;

                                        let text = contentEditableRef.current.innerText.trim();

                                        // Check if the length exceeds 500 characters
                                        if (text.length > MAX_TEXT_LENGTH) {
                                            // Remove excess text


                                            contentEditableRef.current.innerText = text.substring(0, MAX_TEXT_LENGTH);
                                            text = contentEditableRef.current.innerText;
                                        }

                                        setTextLength(text?.length);
                                    }}
                                    ref={contentEditableRef}
                                    className='outline-[#b2b2b2] my-[10px] border-[1px] grow py-[8px] px-[16px] rounded-[12px] leading-[24px] text-[14px]'
                                    contentEditable={true}>
                                    {props.comment}
                                </div>
                                <div
                                    className='absolute text-gray-500 bottom-[-10px] right-2 pointer-events-none select-none text-[12px]'>
                                    {`${textLength? textLength  : '0'}/1500`}
                                </div>
                                <div className='absolute right-[8px] top-[7px]'>
                                    <Button
                                        title=''
                                        onClick={handleUpdateComment}
                                        isLoading={isLoading}
                                        leftIcon={isLoading ? <></>:<img src={ASSETS.icon.SEND_PINK} className='cursor-pointer'/>}
                                        containerClass='btn-ghost'/>

                                </div>
                            </div>
                            :

                            <div className='mb-[8px] font-normal leading-[24px] whitespace-pre-wrap'>
                                {props.comment ?? '-- No Comment -- '}
                            </div>
                }
                <div className='text-[12px] font-normal leading-[16px] text-base-300 sm:hidden'>{formatDate(props.updated_at)}</div>

                <div className='flex items-center mt-[15px]'>
                    <div className='flex grow items-center gap-x-[10px]'>
                        {latestReactions.length > 0 ?
                            <div className='flex items-center text-[12px] font-normal leading-[16px] text-base-300 bg-base-content rounded-full py-[3px] px-[6px]'>
                                {
                                    latestReactions.map((item, index) =>
                                        <Lottie
                                            options={{
                                                loop: false,
                                                autoplay: false,
                                                animationData: REACTION_TYPE_RECORD[item as keyof typeof REACTION_TYPE_RECORD],
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }

                                            }}
                                            width={18}
                                            height={18}
                                            isPaused={true}
                                            isStopped={true}
                                            key={index}/>
                                    )
                                }
                                {getLatestReactionUser()}
                            </div>
                            :
                            <></>
                        }
                        <div className='relative group z-50'>
                            <div className='flex gap-x-[20px] absolute left-0 bottom-[-43px]
                                border-0 border-black p-[10px] rounded-lg border-solid bg-white
                                invisible group-hover:visible transition-all drop-shadow-2xl	 '>
                                {/* Reaction components */}
                                <Reaction
                                    handlePeerReview={props.handlePeerReviews}
                                    name={REACTION_TYPE_TEXT.LIKE}
                                    isComment={true}
                                    icon={REACTION_TYPE.LIKE} tooltip='Like'
                                    id={props.id} />
                                <Reaction
                                    isComment={true}
                                    handlePeerReview={props.handlePeerReviews}
                                    name={REACTION_TYPE_TEXT.LOVE} icon={REACTION_TYPE.LOVE} tooltip='Love'
                                    id={props.id} />
                                <Reaction
                                    isComment={true}
                                    handlePeerReview={props.handlePeerReviews}
                                    name={REACTION_TYPE_TEXT.SHOCKED} icon={REACTION_TYPE.SHOCKED} tooltip='Insightful'
                                    id={props.id} />
                                <Reaction
                                    isComment={true}
                                    handlePeerReview={props.handlePeerReviews}
                                    name={REACTION_TYPE_TEXT.INSIGHTFUL} icon={REACTION_TYPE.INSIGHTFUL} tooltip='Shocked'
                                    id={props.id} />
                            </div>
                            <Button
                                leftIcon={<img src={ASSETS.icon.LikeIcon}/>}
                                containerClass={`btn-xs btn-outline ${props.deleted_at ? 'hidden': ""}`}
                                title='Like'/>
                        </div>
                        {/* <Button
                            onClick={handleOpenComment}
                            leftIcon={<img src={ASSETS.icon.COMMENT}/>}
                            containerClass='btn-sm btn-outline'
                            title='Comment'/> */}
                    </div>
                    <div className='text-[12px] font-normal leading-[16px] text-base-300 max-sm:hidden'>{formatDate(props.updated_at)}</div>
                </div>
            </div>
        </div>
    );

    function formatDate(dateStr: string): string {
        // Parse the date string
        const date = new Date(dateStr);

        // Array of day names
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        // Array of month names
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Get the day name
        const dayName = days[date.getUTCDay()];

        // Get the day of the month
        const day = date.getUTCDate().toString().padStart(2, '0');

        // Get the month name
        const month = months[date.getUTCMonth()];

        // Get the year
        const year = date.getUTCFullYear();

        // Get hours and minutes
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');

        // Format the date string
        return `${dayName} ${day} ${month} ${year} • ${hours}:${minutes}`;
    }
};

export default CommentCard;
