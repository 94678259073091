import { ASSETS } from 'assets';
import React from 'react';
import Chip from './element/Chip';
import LessonCard from './element/LessonCard';
import { ModuleDetail } from 'services/module/ModuleTypes';
import { useModuleAccordion } from './hooks/useModuleAccordion';
import { formatDateToGMT7AndFormat, getHoursText } from 'helper/dateHelper';
import { LessonDetail } from 'services/lesson/LessonTypes';
import { useGetProfile } from 'hooks/user/user';

interface ModuleCardProps {
    item : ModuleDetail
    index: number
}

const renderLessons = (item:LessonDetail) => {
    return <LessonCard key={`${item.id}_${item.module_id}`} data={item}/>;
};

const renderProgress = (item:LessonDetail, index:number, items: LessonDetail[] ) => {
    const isLocked = !item.pivot?.is_accessible;
    const isSuccess = item.pivot?.completed_at ? true : false;
    const isNextExist = index < items.length - 1 ? items[index + 1] : null;
    let isNextSuccess = false;



    if(isNextExist){
        isNextSuccess = items[index + 1].pivot?.completed_at ? true: false;
    }

    const getLineComponent = () => {
        if(isNextSuccess) {
            return <div className='w-[1px] h-[60px] bg-success'/>;
        }

        return <div className=' h-[60px] w-[1px]' style={{ backgroundImage: "repeating-linear-gradient(to bottom, #98A2B3, #98A2B3 5px, transparent 2px, transparent 10px)" }} />;
    };

    const getIconComponent = () => {
        if(isLocked) {
            return <img src={ASSETS.dashboard.module.grayLock}/>;
        }

        if(isSuccess){
            return <img src={ASSETS.dashboard.module.tickGreenCircle}/>;
        }

        return <img src={ASSETS.dashboard.module.greenCircle}/>;
    };

    return (
        <React.Fragment key={`${item.id}_${item.module_id}`}>
            {getIconComponent()}

            {
                isNextExist ?
                    // <div className='w-[1px] h-[54px] bg-success'/>
                    getLineComponent()
                    :

                    <></>
            }

        </React.Fragment>
    );

};

export const ModuleCard = (props:ModuleCardProps): JSX.Element => {
    const {isOpen, toggleAccordion, closeAccordion} = useModuleAccordion();
    const {data:profileData} = useGetProfile();

    const isSyncUser = () => {
        if(!profileData?.defaultProgram) return false;

        if(!profileData?.defaultProgram?.pivot) return false;

        if (profileData?.defaultProgram?.pivot?.type !== 'sync') return false;


        return true;
    };
    // const isLocked = !props.item.pivot?.is_accessible;

    const Lessons = props.item.pages;

    const ModulePeriods = props.item.period;
    const ModuleDuration = Math.ceil(props.item.duration/60);

    const startPeriod = formatDateToGMT7AndFormat(ModulePeriods.start_at);
    const endPeriod = formatDateToGMT7AndFormat(ModulePeriods.end_at);

    const progressDetail = props.item.pivot;

    const getStatusComponent = () => {
        if(!progressDetail?.is_accessible){
            return(
                <div className='flex min-w-[100px] gap-x-[8px] bg-base-300 item-center px-[12px] py-[7px] rounded-[8px] scale-90 max-lg:scale-100'>
                    <img src={ASSETS.dashboard.module.lockWhite} alt='checklist'/>
                    <div className='text-white font-semibold text-[14px] leading-[20px]'>
                        Locked
                    </div>
                </div>
            );
        }

        if(progressDetail.progress.percentage  === 100){



            return (
                <div className='flex min-w-[133px] gap-x-[8px] bg-success item-center px-[12px] py-[7px] rounded-[8px]'>
                    <img src={ASSETS.dashboard.module.checklistWhite} alt='checklist'/>
                    <div className='text-white font-semibold text-[14px] leading-[20px]'>
                        Completed
                    </div>
                </div>
            );
        }

        return (
            <div className='flex min-w-[160px] gap-x-[8px] bg-[#5BC6CC] items-center px-[12px] py-[5px] rounded-[8px] relative'>
                <div className='px-[8px] relative bg-white rounded-[6px] text-[#0F767C] '>
                    <div className='absolute top-0 left-0 bottom-0 bg-[#5BC6CC] rounded-[6px]' style={{width: `${progressDetail.progress.percentage}%`, backgroundColor:'#9BEAEE'}}/>
                    <div className='relative text-[14px] text-[#0F767C] font-semibold leading-[22px] min-w-[28px]'>
                        {progressDetail.progress.percentage}%
                    </div>
                </div>
                <div className='text-white font-semibold text-[14px] leading-[20px]'>
                    In progress
                </div>
            </div>
        );
    };


    return (
        <div className='max-lg:mx-[25px]'>
            <div className='collapse bg-white drop-shadow-md' >
                <input className='' type='radio' name={props.item.id.toString()}
                    onChange={()=> {
                        toggleAccordion();
                    }}
                    onClick={closeAccordion}
                    checked={isOpen} />
                <div className='collapse-title flex flex-col ' onClick={()=>toggleAccordion()}>
                    <div className='flex gap-x-[20px] items-center' >
                        <img src={ASSETS.icon.chevron}
                            alt='chevron'
                            className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180': ''} `}/>
                        <div className='flex items-center w-full max-lg:flex-col max-lg:items-start max-lg:gap-y-[8px]'>
                            <div className='grow pr-[30px]'>
                                <p className='text-xl  font-semibold'>{`Module ${props.index} : ${props.item.name}`}</p>
                                <div className='flex w-auto gap-x-[8px] max-lg:mt-[8px] mt-[5px]'>
                                    {
                                        !isSyncUser() ?
                                            <></>
                                            :
                                            <Chip >
                                                <>
                                                    <img src={ASSETS.dashboard.module.calendarGray}/>
                                                    <div className='text-[12px] text-base-300'>{`${startPeriod} - ${endPeriod}`}</div>
                                                </>
                                            </Chip>
                                    }


                                    <Chip >
                                        <>
                                            <img src={ASSETS.dashboard.module.clockGray}/>
                                            <div className='text-[12px] text-base-300'>{`${ModuleDuration} ${getHoursText(props.item.duration)}`}</div>
                                        </>
                                    </Chip>
                                </div>
                            </div>

                            {profileData?.type !== 'guest' && getStatusComponent()}

                        </div>
                    </div>
                </div>
                <div className='collapse-content'>
                    <div className='flex gap-x-[20px]'>
                        <div className='flex flex-col w-[24px] items-center gap-y-[3px]'>
                            <div className='pt-[15px]' />
                            {profileData?.type !== 'guest' && Lessons.map(renderProgress)}
                        </div>
                        <div className='flex flex-col gap-y-[16px]'  style={{width:'100%'}}>
                            {
                                Lessons.map(renderLessons)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ModuleCard;
