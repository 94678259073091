import React from 'react';
import Chip from './Chip';
import { ASSETS } from 'assets';
import { LessonDetail } from 'services/lesson/LessonTypes';
import { getHoursText } from 'helper/dateHelper';
import { useNavigate } from 'react-router-dom';
import { ProtectedRouteNames } from 'config/routes/ProtectedRoute';

interface LessonCardProps {
    data: LessonDetail
}

export const LessonCard = (props: LessonCardProps): JSX.Element => {
    const LessonDuration = Math.ceil(props.data.duration/60);
    const LessonType = props.data.type;
    const navigate = useNavigate();
    const isLocked = !props.data.pivot?.is_accessible;

    const getLessonChip = () => {
        if(LessonType === "webinar"){
            const LessonDateFormat = formatDateTimeRangeToGMT7(props.data.start_at, props.data.end_at);

            return (
                <div className='flex  w-auto gap-x-[8px]'>
                    <Chip >
                        <>
                            <img src={ASSETS.dashboard.module.ZoomBlue}/>
                            <div className='text-[12px] text-base-300'>{capitalizeFirstLetter(LessonType)}</div>
                        </>
                    </Chip>

                    <Chip >
                        <>
                            <img src={ASSETS.dashboard.module.calendarGray}/>
                            <div className='text-[12px] text-base-300'>{LessonDateFormat}</div>
                        </>
                    </Chip>
                </div>
            );
        }

        if(LessonType === "lesson"){
            return (
                <div className='flex  w-auto gap-x-[8px]'>
                    <Chip >
                        <>
                            <img src={ASSETS.dashboard.module.BookSmallGray}/>
                            <div className='text-[12px] text-base-300'>{capitalizeFirstLetter(LessonType)}</div>
                        </>
                    </Chip>

                    <Chip >
                        <>
                            <img src={ASSETS.dashboard.module.clockGray}/>
                            <div className='text-[12px] text-base-300'>{`${LessonDuration} ${getHoursText(props.data.duration)}`}</div>
                        </>
                    </Chip>
                </div>
            );
        }

        if(LessonType === "assignment"){
            return (
                <div className='flex  w-auto gap-x-[8px]'>
                    <Chip >
                        <>
                            <img src={ASSETS.dashboard.module.AssigmentSmallGray}/>
                            <div className='text-[12px] text-base-300'>{capitalizeFirstLetter(LessonType)}</div>
                        </>
                    </Chip>

                    <Chip >
                        <>
                            <img src={ASSETS.dashboard.module.clockGray}/>
                            <div className='text-[12px] text-base-300'>{`${LessonDuration} ${getHoursText(props.data.duration)}`}</div>
                        </>
                    </Chip>
                </div>
            );
        }
    };


    function openCalendar() {
        if(isLocked){
            return;
        }

        const userAgent = window.navigator.userAgent;

        // Check for iOS devices: iPhone, iPod, or iPad
        const isIOS = /iPad|iPhone|iPod/.test(userAgent);

        if (isIOS) {
            // For iOS devices, use the Apple Calendar URL
            window.open(props.data.data.apple_calendar_url, '_blank');
        } else {
            // For non-iOS devices, use the Google Calendar URL
            window.open(props.data.data.google_calendar_url, '_blank');
        }

        // If you need to handle the image URL as well, you can do so here
    }

    function capitalizeFirstLetter(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    const navigateToLesson = () => {
        if(isLocked){
            return;
        }

        navigate(ProtectedRouteNames.LESSON(props.data.slug));
    };

    return (
        <div className={`flex flex-col`} >
            <div className='flex items-center pb-[16px] pr-[14px] max-lg:flex-col max-lg:items-start'>
                <div className='flex flex-col gap-y-[8px] grow'>
                    <div className='text-[16px] font-medium leading-[24px] cursor-pointer' onClick={navigateToLesson}>
                        {props.data.name}
                    </div>
                    {getLessonChip()}
                </div>
                {
                    LessonType === "webinar" ?
                        <div className='flex gap-x-[8px] bg-[#FC2C52] item-center px-[12px] py-[7px] rounded-[8px] cursor-pointer
                            max-lg:scale-100 max-lg:mt-[8px] '
                        onClick={openCalendar}>
                            <img src={ASSETS.dashboard.module.calendarWrite} alt='checklist'/>
                            <div className='text-white font-semibold text-[14px] leading-[20px] '>
                                Add to Calendar
                            </div>
                        </div>
                        : <></>
                }

            </div>
            <div className='h-[1px] bg-neutral-content mr-[35px]'/>
        </div>

    );

    function formatDateTimeRangeToGMT7(startDateString:string, endDateString:string) {
    // Parse the dates from the input strings
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        // Convert both dates to GMT+7 timezone
        startDate.setUTCHours(startDate.getUTCHours() + 7);
        endDate.setUTCHours(endDate.getUTCHours() + 7);

        // Format the date to get the month and day
        const formattedStartDate = startDate.toLocaleDateString('en-US', {
            month: 'short', // Short month representation
            day: 'numeric'  // Numeric day of the month
        });

        // Extract the hours from both dates
        const startHour = startDate.getUTCHours();
        const endHour = endDate.getUTCHours();

        // Construct the final formatted string
        return `${formattedStartDate}, ${startHour} - ${endHour}pm WIB`;
    }
};

export default LessonCard;
