import { axiosInstance, endpoints } from "config/api";
import { isResponseSuccess } from "services/serviceHelper";
import { CreateAnswerQuestions, LessonDetail } from "./LessonTypes";
import { ReviewResponse, commenstObj, createComment, getCommentRequest, getReviewRequest } from "./ReviewTypes";

export class LessonServices {
    async getLessonDetail(id: string): Promise<LessonDetail> {
        const response = await axiosInstance.get(endpoints.assignment.lessons(id));

        if (!isResponseSuccess(response)) {
            throw Error("Server error");
        }

        return response.data;
    }


    async submitAnswer(data: Partial<CreateAnswerQuestions>): Promise<unknown> {
        const response = await axiosInstance.post(endpoints.assignment.submitAnswer(data.page_id as string), data);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return true;
    }

    async getPeerReviews(data: getReviewRequest): Promise<ReviewResponse> {
        const params = {
            filter: data?.my_group? data.my_group : 1,
            sort: data?.sort ? data.sort : 'recent'
        };
        const response = await axiosInstance.get(`${endpoints.assignment.getPeerReviews(data.page_item_id)}?filter[my_group]=${params.filter}&sort=${params.sort}&page=${data.page}&per_page=${data.per_page}`);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

    async getComment(data: getCommentRequest): Promise<commenstObj> {
        const response = await axiosInstance.get(`${endpoints.assignment.getComments(data.page_item_id, data.user_page_item_id)}?page=${data.page}&per_page=${data.per_page}`);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

    async submitComments(data:createComment): Promise<unknown> {
        const response = await axiosInstance.post(endpoints.assignment.createComments, data);

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

    async deleteComments(data:string): Promise<unknown> {
        const response = await axiosInstance.delete(endpoints.assignment.updateComments(data));

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

    async updateComments({id, content}: {id: string, content: string}): Promise<unknown> {
        const response = await axiosInstance.put(endpoints.assignment.updateComments(id), {content: content});

        if (!isResponseSuccess(response)) {
            throw Error("Server error: " + response.status);
        }

        return response.data;
    }

}
